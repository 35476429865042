@import "assets/scss/master";

.image__crop__buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    button:last-child {
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
            color: $dark__mode__theme__white__color !important;
        }
    }
}
