@import "assets/scss/master";

.classification-container-box {
    height: 10rem;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    padding: 1rem;
    gap: 0.625rem 0.313rem;
    overflow-y: auto;
    width: 100%;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--classification-capsules {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 0.75rem
    }
}