@import "assets/scss/master";

.pending__task__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;

    .pending__task__classification {
        padding: 16px 0;
        border-bottom: 1px solid #ecebf5;

        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }

        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
}
