@import "assets/scss/master";

.tm__tag {
    height: 36px;
    border: 1px solid #e1e1eb;
    border-radius: 8px;
    margin-right: 0.625rem;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    span {
        color: #696d8c;
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
    }
    &.active {
        background: #ff991f;
        border-color: #ff991f;
        span {
            color: #fff;
        }
    }
}
