@import "assets/scss/master";

.guide-automation-fields {
    margin-top: 16px;
    h5 {
        color: #696d8c;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            border-color: $dark__mode__theme__border__color;
        }
    }
    &__section {
        display: flex;
        width: 100%;
        gap: 12px;
        section {
            width: 100%;
        }
    }
    .delete_automation {
        float: right;
        margin-top: -12px;
        svg {
            width: 16px;
            cursor: pointer;
        }
    }
}
