@import "assets/scss/master";

.added-scenarios {
    margin-bottom: 16px;
    h3 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-weight: bold;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    section {
        border-radius: 10px;
        border: 1px solid $border_highlight_color;
        background: #fff;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 12px;
        min-height: 4.5rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            background-color: $dark__mode__theme__background__color;
        }

        div {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            display: flex;
            height: 36px;
            padding: 20px 16px 20px 20px;
            align-items: center;
            border-radius: 10px;
            background: $default__capsule__theme_color;
            width: fit-content;
            gap: 12px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
                background-color: $dark__mode__theme__color !important;
            }
            img {
                width: 12px;
                height: 12px;
                cursor: pointer;
            }
        }
    }
}
