@import "assets/scss/master";

.analytics-qa-dropHeader {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
        color: #060213;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    svg {
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__white__color;
        }
        path {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
            }
        }
    }
}
