.edit__profile {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 0.927rem;

    &--avatar {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin-bottom: 1rem;
    }
 }