@import "assets/scss/master";

.branch-canned-element {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 10px;
    border: 1px solid white;
    background: #fff;
    width: 100%;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }

    &__heading {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        h6 {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        button {
            width: fit-content !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
    p {
        color: #696d8c;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    &__list {
        p {
            color: #696d8c;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            padding: 6px 16px;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #e1e1eb;
            background: white;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color !important;
                background: transparent;
                color: $dark__mode__theme__text__color;
            }
        }
    }

    &__update-btn {
        color: #6837ef !important;
        font-family: Gelion;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        border: none !important;
        padding: 0 !important;
        height: fit-content !important;
    }

    &__thrash {
        width: 24px;
        height: 24px;
        background-color: #f25a68;
        border-radius: 50%;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        svg {
            width: 12.6px;
            margin-bottom: 3px;
            cursor: pointer;
            stroke: white !important;
            path {
                stroke: white !important;
            }
        }
    }
}
