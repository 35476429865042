@import "assets/scss/master";

.search__class__capsule__input__group {
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 1.8rem;
    background: #ffffff;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
}
