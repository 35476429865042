@import "assets/scss/master";

.email__holder {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #a3a3c2;
    background: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 7px;
    gap: 3px;

    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        background-color: $dark__mode__theme__color;
    }
    &.sender--email {
        background-color: $primary__theme_color !important;
        color: $light__theme_color !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background-color: $dark__mode__primary__theme_muted_color !important;
        }
        svg {
            cursor: pointer;
            path {
                stroke: $light__theme_color !important;
            }
        }
    }
    button {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
    }

    &.email {
        border: 0.063rem solid #e1e1eb;
        padding: 0px 8px;
        height: 28px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }

    & .remove__email {
        svg {
            cursor: pointer;

            & path {
                stroke: #a3a3c2;

                #{$dark__mode__theme__selector} {
                    stroke: $dark__mode__theme__text__color;
                }
            }
        }
    }
}
