.ticketaa-create-mentions {
    display: flex;
    align-items: flex-end;
    gap: 12px;

    .mentions {
        max-height: 120px !important;

        .mentions__highlighter {
            height: auto;
            max-height: 120px;
        }
    }

    & textarea {
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0 !important;
        outline: none !important;
    }
}