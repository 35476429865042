@import "assets/scss/master";

.statusMap {
    background: #ffffff;
    border: 1px solid #ecebf5;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0.625rem;
    height: fit-content;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }
}
