@import "assets/scss/_master.scss";

.notifications-container {
    .main {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 8.33333333% 1.5rem 8.33333333%;

        &--cancel {
            cursor: pointer;
            margin-top: -20px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #fafafc;
            display: flex;
            align-items: center;
            justify-content: center;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
            }
            svg {
                #{$dark__mode__theme__selector} {
                    stroke: $light__theme_color !important;
                }
                path {
                    #{$dark__mode__theme__selector} {
                        stroke: $light__theme_color !important;
                    }
                }
            }
        }
    }

    .empty-notifictions {
        margin-top: 6.25rem !important;
    }

    .loader__container {
        height: 3.125rem !important;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.563rem;
    }
}
