#canned__content__dropdown {
    .bottom__menu {
        left: -141px !important;
        top: 30px !important;
        
        &::after {
            content: "";
            display: none;
        }

        &::before {
            content: "";
            display: none;
        }
    }

    .top__menu {
        left: -141px !important;
        top: -80px !important;
     
        &::after {
            content: "";
            display: none;
        }

         &::before {
            content: "";
            display: none;
        }
    }
}