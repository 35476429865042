@import "assets/scss/master";

.tickets__detail {
    &--header {
        position: relative;
        border-bottom: 1px solid #ecebf5;
        display: flex;
        flex-direction: space-evenly;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: auto;
        padding: 0;
        // justify-content: space-evenly;

        #{$dark__mode__theme__selector} {
            border-bottom: 1px solid $dark__mode__theme__border__color;
        }

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }
}
