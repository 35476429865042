@import "assets/scss/master";

.branch_option {
    border: 1px solid #ecebf5;
    border-radius: 0.625rem;
    margin-bottom: 3rem;
}

.no-next-step-text {
    color: $muted__theme_color_2;
    font-family: Gelion;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}
