@import "assets/scss/master";
.suggestion__container {
    margin-bottom: 2rem;
    margin: 16px;
    h6{
        color: #060213;
        font-size: 16px;
        margin-bottom: 1rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    .tmt__list--container {
        margin-top: 1rem;
        margin-bottom: 3rem;
        border: 1px solid #E1E1EB;
        border-radius: 0.625rem;
        padding: 13px 12px;
        height: 120px;
        display: flex;
        align-items: center;
        overflow: scroll;
        gap: 10px;
        flex-wrap: wrap;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        .tt_tag {
            height: 36px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1.25rem;
            border-radius: 0.625rem;
            width: fit-content;
            white-space: nowrap;
            font-size: 0.875rem;
            color: #696D8C;
            background: #F5F5F7;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
                background: $dark__mode__theme__color;
            }
        }

        .empty__tmtag__conversation {
            width: 100%;
        }
    }
}