@import "assets/scss/master";

.analytics-tsam-capsules-tdropdown {
    // height: 124px;
    background: $light__theme_color;
    border: 1px solid $border__theme_color;
    border-radius: 10px;
    text-align: unset !important;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__background__color;
    }
    &__heading {
        font-weight: 600;
        font-size: 16px;
        // line-height: 24px;
        color: #060213;
        padding: 17px 16px;
        text-align: left;
        border-bottom: 1px solid $border__theme_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        p {
            margin-bottom: 0rem !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }
    &__body {
        padding: 17px 16px;
        display: flex;
        gap: 10px;
        .analytics-tsam-capsules-element {
            padding: 2px 16px;
            gap: 16px;
            width: fit-content;
            // height: 36px;
            background: $default__capsule__theme_color;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
                border: 1px solid $dark__mode__theme__border__color
            }
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 32px;
                color: #696d8c;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
            strong {
                width: 42px;
                height: 30px;
                background: #e6e6ed;
                border-radius: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: $black__theme_color;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                    background-color: $dark__mode__theme__capsule__count__color;
                }
            }
        }
    }
}
