@import "assets/scss/master";

.branch__option {
    border: 1px solid #ecebf5;
    border-radius: 0.625rem;
    margin-bottom: 0.5rem;
    padding: 1.5rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &:active {
        background: url(https://res.cloudinary.com/metacare/image/upload/v1653586729/Vector_kknzkn.svg)
            no-repeat;
        background-position-x: 10px;
        background-position-y: 10px;
        opacity: 0.6;
    }

    &.invalid {
        border-color: rgba($danger__theme_color, 0.7);
    }

    h6 {
        font-family: $primary_font_family--medium;
        color: $primary__theme_color;
        font-size: 18px;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }

    &__button {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
    }

    .connections {
        color: #696d8c;
        font-size: 0.875rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.15px;
        #{$dark__mode__theme__selector} {
            color: #e6e6ed;
        }
    }

    .scheduled {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        span {
            color: #696d8c;
            font-size: 0.875rem;
            letter-spacing: 0.15px;
            margin-top: 4px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
    .capsule__content {
        margin-bottom: 1rem;
        &--tag {
            height: 36px;
            border-radius: 0.625rem;
            font-size: 0.875rem;
            padding: 0 1rem;
            display: flex;
            align-items: center;
            margin: 0;
        }
    }
    .blue__tag {
        background: #377dff;
        border-radius: 15px;
        color: #fff;
        font-size: 0.875rem;
        // font-family: $primary_font_family--medium;
        letter-spacing: 0.15px;
        font-weight: 100;
        // padding: 0.5rem 8px;
        padding-left: 0;
        padding-right: 8px;
        width: fit-content;
        height: 32px;
        display: flex;
        align-items: center;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__blue__color;
            color: $dark__mode__theme__white__color;
        }
        .circle {
            background: #fff;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-left: 8px;
            margin-right: 0.625rem;
        }
        span {
            line-height: 8px;
        }
    }
}
