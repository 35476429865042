@import "assets/scss/master";

.edit__block__btn {
    padding: 0.375rem 1rem;
    border-radius: 0.313rem;
    height: auto;
    border: 0.063rem solid $border_highlight_color;
    font-size: 0.875rem;
    color: $text__default_color;
    background: $light__theme_color;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
        border-color: $dark__mode__theme__border__color !important;
        color: $light__theme_color !important
    }
}