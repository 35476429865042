@import "assets/scss/master";

.team-form {
    &__instruction {
        padding: 0.75rem 0.5rem;
        width: 100%;
        height: 4rem;
        background: rgba(104, 55, 239, 0.1);
        border: 1px solid rgba(104, 55, 239, 0.1);
        border-radius: 6px;
        display: flex;
        margin-bottom: 1.563rem;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
           background-color: $dark__mode__theme__color;
           color: $dark__mode__primary__theme_muted_color
        }
        span {
            font-family: "Moderat";
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-left: 0.5rem;
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color
             }
        }
    }
    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #060213;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
    }
}
