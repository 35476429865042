@import "assets/scss/master";

.sidebar {
    padding: 0.75rem 0 1.813rem 1.813rem;
    padding-top: 21px;
    background-color: #fff;
    width: 16.375rem;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }

    // transition: all 1s;
    &:hover {
        .collapse-toggler {
            display: flex;
        }
    }
    &.collapsed {
        width: 5.625rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        .menu-list {
            .menu--item .menu--icon {
                margin-right: 0;
            }
        }
    }
    &__heading {
        min-height: 4.438rem;
        display: flex;
        align-items: center;
    }
    .workspace--collapsed-logo,
    .workspace--logo {
        width: 2.313rem;
        height: 2.313rem;
        border-radius: 100%;
        flex-shrink: 0;
    }
    .workspace--collapsed-logo {
        margin-right: 1.813rem;
        margin-top: 0.313rem;
    }
    .logo-plus-toggler {
        width: 14.563rem;
        position: relative;
        .collapse-toggler {
            position: absolute;
            right: 0;
            height: 100%;
        }
    }
    .menu-logo--container {
        padding: 1rem;
        border: 0.063rem solid #dee1e5;
        border-radius: 0.5rem;
        color: #060213;
        width: 12.75rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        .menu-logo--text {
            margin-left: 0.563rem;
            h5 {
                font-size: 0.875rem;
            }
            p {
                font-size: 0.75rem;
            }
            h5,
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 7.875rem;
                white-space: nowrap;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
        }
    }
    .collapse-toggler {
        display: none;
        background: #f3f3f4;
        border-radius: 0.5rem 0px 0px 0.5rem;
        width: 1.125rem;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }
    }
    .menu-list {
        margin-top: 30px;
        padding-right: 1.813rem;
        .menu--item {
            color: #696d8c;
            font-size: 1rem;
            line-height: 1.375rem;
            width: 100%;
            text-decoration: none;
            margin-bottom: 26px;
            display: flex;
            align-items: center;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
            span {
                margin-bottom: 0.063rem;
            }
            .menu--anchor {
                display: none;
            }
            &.active {
                color: $primary__theme_color;
                font-family: $primary_font_family--medium;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__primary__theme_muted_color;
                }

                .menu--icon {
                    color: $dark__mode__primary__theme_muted_color !important;
                }
            }
            &:hover {
                text-decoration: none;
                color: #565558;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
                &.active {
                    color: $primary__theme_color;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__primary__theme_muted_color;
                    }
                }
                .menu--anchor {
                    display: flex;
                }
            }
            .menu--icon {
                content: "";
                margin-right: 1.188rem;
                width: 1rem;
                height: 1rem;
                background-color: #696d8c;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__theme__text__color;
                }
                &.people {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690090/icons/ic_Messages_ciwd3p_vhb6pt_nbtnue.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690090/icons/ic_Messages_ciwd3p_vhb6pt_nbtnue.svg");
                }
                &.round-check {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690265/icons/ic_Projects_hdhhli_fhb4ed_1_zjtvk6.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690265/icons/ic_Projects_hdhhli_fhb4ed_1_zjtvk6.svg");
                }
                &.train-sam {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690265/icons/ic_Projects_hdhhli_fhb4ed_1_zjtvk6.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690265/icons/ic_Projects_hdhhli_fhb4ed_1_zjtvk6.svg");
                }
                &.agent-inbox {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690381/icons/ic_Schedule_asmu8o_selyxu_o7tcle.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690381/icons/ic_Schedule_asmu8o_selyxu_o7tcle.svg");
                }
                &.help-center {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690452/icons/ic_Activity_halvgs_gc7zmf_rkyik3.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690452/icons/ic_Activity_halvgs_gc7zmf_rkyik3.svg");
                }
                &.service-module {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690560/icons/ic_Settings_hvgjkp_fkbeze_nhifrp.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690560/icons/ic_Settings_hvgjkp_fkbeze_nhifrp.svg");
                }
                &.dependency {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690623/icons/ic_Settings1_kej6qt_oskzwx_x5ps0o.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1642690623/icons/ic_Settings1_kej6qt_oskzwx_x5ps0o.svg");
                }
                &.analytics {
                    mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1666010452/icons/Shape_rjiyu7.svg");
                    -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1666010452/icons/Shape_rjiyu7.svg");
                }
            }
        }
        .menu-item--children {
            border-left: 0.063rem solid #dee1e5;
            margin-left: 0.625rem;
            padding-left: 1.875rem;
            display: none !important;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
            &.show {
                display: block !important;
            }
            &.hide {
                display: none !important;
            }
            .menu-item--child {
                color: #636365;
                font-size: 1rem;
                line-height: 1.375rem;
                text-decoration: none;
                margin: 1.5rem 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &.active {
                    color: #636365;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color;
                    }
                    font-family: $primary_font_family--medium;
                }
                &:hover {
                    text-decoration: none;
                    color: #636365;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__white__color;
                    }
                    &.active {
                        color: #636365;
                        #{$dark__mode__theme__selector} {
                            color: $dark__mode__theme__text__color;
                        }
                    }
                }
            }
        }
    }

    &-theme-toggle {
        position: fixed;
        bottom: 1rem;
        cursor: pointer;
        padding: 12px 16px 12px 12px;
        gap: 12px;
        display: flex;
        max-width: 212px;
        height: 48px;
        &.collapsed {
            left: 22px;
            padding: 12px 16px 12px 12px;
        }
        background-color: white;
        z-index: 1;
        border: 1px solid #e1e1eb;
        justify-content: space-between;
        align-items: center;
        #{$dark__mode__theme__selector} {
            border: 1px solid #8f8f8f;
            background-color: $dark__mode__theme__background__color;
        }
        border-radius: 4px;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }

        &__moon {
            margin-top: -2px;
        }
    }
}
