@import "assets/scss/master";

.phase__class__container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    // width: 100%;
    gap: 10px;
    #{$white__mode__theme__selector} {
        background-color: $light__mode__theme__background__color !important;
    }

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }
}

.tickets__filter__container {
    padding: 0 12px;
    #{$white__mode__theme__selector} {
        background-color: $light__mode__theme__background__color !important;
    }

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }
}

.tickets__filter {
    #{$white__mode__theme__selector} {
        background-color: $light__mode__theme__background__color !important;
    }

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color !important;
    }
}
