@import "assets/scss/master";

.recipient__container {
    & .recipient {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 20px 24px 20px 24px;
        border-bottom: 0.063rem solid #e1e1eb;
        height: 40px !important;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        &--direction {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }

        .copy__recipient--controls {
            display: flex;
            gap: 0.5rem;

            & span {
                font-weight: 500;
                font-size: 1rem;
                line-height: 24px;
                color: #a3a3c2;
                cursor: pointer;

                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.email__content__textarea {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    resize: none;
    font-size: 18px !important;
    line-height: 26px !important;
    color: #000000 !important;
    height: 30rem;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color
    }
    &:disabled {
        border: 0 !important;
    }
}
