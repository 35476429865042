@import "assets/scss/master";

.analytics-tsam-convo-view-table {
    .table__container {
        min-height: fit-content !important;
        thead {
            th:first-child {
                float: unset !important;
                text-align: left !important;
            }
            th {
                float: unset !important;
                text-align: center !important;
            }
        }
        tbody {
            td:first-child {
                text-align: left !important;
            }
            td {
                font-size: 14px !important;
                text-align: center !important;
            }
           
        }
    }

    .analytics-tsam-RESOLVED {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: #25bb87;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__green__color
        }
    }
    .analytics-tsam-PENDING {
        font-weight: 600;
        font-size: 14px;
        color: #696d8c;   
    }
    .analytics-tsam-UN_RESOLVED {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: #f25a68;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__red__color
        }
    }
}
