@import "assets/scss/master";


.empty__classification__list {
    margin-bottom: 1rem;

    &--box {
    height: 10rem;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    padding: 1rem;
    width: 100%;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
     }
    }
}