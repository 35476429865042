@import "assets/scss/master";

.follow__up__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 24px 24px;
    border-bottom: 0.063rem solid #ecebf5;
    border-top: 0.063rem solid #ecebf5;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    gap: 1.5rem;
    .follow__up__header--sub__container {
        & .title {
            font-size: 12px;
            line-height: 1rem;
            color: #a3a3c2;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }

        & .value {
            font-size: 14px;
            line-height: 20px;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
}

.pending__tasks--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 24px 0;

    & h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #696d8c;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
}
