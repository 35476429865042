@import "assets/scss/master";

.email {
    width: fit-content;
    color: $primary__theme_color;
    background: $gray__bg__color;
    padding: 0.25rem 0.5rem;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.25rem;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
        color: $dark__mode__primary__theme_muted_color;
    }
    &--text {
        text-transform: capitalize;
        font-size: 0.875rem;
    }
}

  