@import "assets/scss/master";

.view-gd-heading {
    margin-top: 24px;
    border-top: 1px solid rgba(30, 31, 32, 0.1);
    border-bottom: 1px solid rgba(30, 31, 32, 0.1);
    padding: 24px 0px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__background__color;
    }
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
        button {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }
    p {
        color: var(--text-body-text, #696d8c);
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }
}
