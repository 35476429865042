@import "assets/scss/master";

.branch-embeds {
    &__heading {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        button {
            width: fit-content !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
}
