@import "assets/scss/master";

.ticket-canned-response {
    width: fit-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    padding: 20px 16px 20px 20px;
    border-radius: 10px;
    background: #f5f5f7;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__color;
    }

    span {
        color: $muted__theme_color_2;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }
}
