@import "assets/scss/master";

.discovery {
    padding: 0 3rem;
    padding-top: 6rem;
}


.table {
    margin-bottom: unset !important;
}
