@import "assets/scss/master";

.guide-downtime-capsules {
    margin-bottom: 34px;
    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        p {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
            span {
                // width: 28px;
                // height: 28px;
                background: $badge__theme__color !important;
                color: $black__theme_color;
                text-align: center;
                font-family: Gelion;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                padding: 6px;
                border-radius: 100%;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                    background-color: $dark__mode__theme__color !important;
                }
            }
        }
    }
    .capsule__content__list {
        border-radius: 10px;
        border: 1px solid rgba(30, 31, 32, 0.1);
        background: white;
        padding: 24px;
        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__theme__border__color !important;
            background-color: $dark__mode__theme__background__color;
        }
        &.gap-12px {
            gap: 12px;
        }
        .shiny__line {
            width: 5rem !important;
        }
    }
}
