@import "assets/scss/_master.scss";

.notification__tabs {
    display: flex;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid rgba(30, 31, 32, 0.1);
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .notification__tab {
        font-size: 14px;
        font-family: $primary_font_family--medium;
        /* line-height: 1.063rem; */
        letter-spacing: 0.05px;
        color: #e0e0e0;
        /* margin-right: 2rem; */
        cursor: pointer;
        // padding: 8px 0px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
        text-transform: capitalize !important;
        span {
            margin-bottom: 9px;
            display: block;
        }
        &::after {
            display: block;
            content: "";
            border-bottom: solid 1px $primary__theme_color;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
            margin-bottom: -1px;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
            }
        }
        &.active {
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
            &::after {
                transform: scaleX(1);
            }
        }
    }
}
