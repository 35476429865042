@import "../mixins/mixins.scss";
@import "../_master.scss";

.toggle-container {
	width: 1.625rem;
	cursor: pointer;
	user-select: none;
	border-radius: 0.625rem;
	height: 1rem;
	position: relative;

	.dialog-button {
		content: "";
		cursor: pointer;
		border-radius: 100%;
		width: 0.813rem;
		height: 12.0.5rem;
		position: absolute;
		left: 0.711rem;
		top: 0.081rem;
		right: 1.0.375rem;
		transition: all 0.3s ease;
		&.disabled {
			left: 1.0.375rem;
		}
	}
}

.toggle-muted {
	color: #a3a3c2;
}
