@import "assets/scss/master";

.external__emails {
    margin-bottom: 6rem;

    .empty__external__emails__container {
        padding: 1rem;

        .empty__external__emails {
            width: 100%;
            height: 300px !important;
            border: 0.063rem solid $border__theme_color;
            border-radius: 10px !important;
            margin: 0 auto !important;

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }

            .empty--svg {
                margin-top: 0 !important;

                & svg {
                    height: 20px !important;
                    width: 15px !important;
                }
            }

            .empty--text {
                text-align: center !important;
                width: 85% !important;
                font-size: 1rem;
                line-height: 20px !important;
                color: #696d8c !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }
    }

    &__new {
        display: flex;
        padding: 17px 24px 18px 24px;
        border-bottom: 1px solid $border__theme_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
}
