@import "assets/scss/master";

.existing-scenarios {
    margin-bottom: 16px;
    h3 {
        color: var(--text-black, #060213);
        font-family: Gelion;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-weight: bold;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
    &__box {
        border-radius: 10px;
        border: 1px solid var(--border-stroke-field, #e1e1eb);
        background: #fff;
        padding: 16px;
        height: 200px;
        overflow: scroll;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            background-color: $dark__mode__theme__background__color;
        }
        section {
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            gap: 12px;
            div {
                color: $muted__theme_color_2;
                font-family: Gelion;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                display: flex;
                height: 36px;
                padding: 20px 16px 20px 20px;
                align-items: center;
                gap: 112px;
                border-radius: 10px;
                background: $default__capsule__theme_color;
                width: fit-content;
                cursor: pointer;
                text-wrap: nowrap;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color !important;
                    background-color: $dark__mode__theme__color !important;
                }
            }
        }
    }
    &__loader {
        width: 8rem !important;
        margin-right: 12px;
        margin-bottom: 12px;
    }
}
