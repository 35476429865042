@import "assets/scss/master";

.tagging {
    border-radius: 0.625rem;
    border: 0.063rem solid $border__theme_color;
    padding: 1.5rem 1.25rem 2.5rem;
    margin-bottom: 3rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}