@import "assets/scss/master";
.form-canned-response {
    // width: fit-content;
    // overflow: hidden;
    &__content {
        border-radius: 8px;
        background: $default__capsule__theme_color;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: $muted__theme_color_2;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__border__color;
            color: $default__capsule__theme_color;
        }
    }
    &__dropdown {
        position: absolute;
        div {
            margin-top: 12px;
            width: 133px;
            z-index: 100;
            height: fit-content;
            padding: 12px 15px 12px 18px;
            padding-left: 12px;
            background: $off__white__theme__color;
            border-radius: 12px;
            gap: 11px;
            display: grid;
        }
        box-shadow: 8px 12px 32px 0px rgba(0, 0, 0, 0.06);
        #{$dark__mode__theme__selector} {
            border-radius: 12px;
            background: $dark__mode__theme__color;
        }
        p {
            color: $muted__theme_color_2;
            display: block;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            margin-top: 0;
            margin-bottom: 0;
            #{$dark__mode__theme__selector} {
                color: $default__capsule__theme_color;
            }
        }
    }

    p {
        color: $muted__theme_color_2;
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $default__capsule__theme_color;
        }
    }
}
