@import "../../../../../../../../../assets/scss/master";

.added__collection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    .collection--content {
        background: rgba(0, 135, 90, 0.2);
        border-radius: 4px;
        padding: 4px 0.625rem;
        margin-right: 0.625rem;
        p{
            font-size: 0.875rem;
            color: #00875A;
            font-family: $primary_font_family--medium;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__green__muted__color
            }
        }
    }
}