@import "assets/scss/master";

.key__Phrase__header {
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $border__theme_color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        margin-bottom: 0;
    }
}