.analytics-tsam-capsules-table {
    .table__container {
        min-height: fit-content !important;
        thead {
            th:first-child {
                text-align: left !important;
            }
            th {
                float: unset !important;
                text-align: center !important;
            }
        }
        tbody {
            td {
                text-align: center !important;
            }
            td:last-child {
                float: unset !important;
                text-align: center !important;
            }
        }
    }
}
