@import "assets/scss/master";

.scenarios {
    &__guides {
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
        .tag-pop {
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__background__color !important;
                color: $dark__mode__theme__white__color !important;
                border: 1px solid $dark__mode__theme__color !important;
                cursor: pointer;
            }
        }
    }
}
