@import "assets/scss/master";

.users__search__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 1rem;

    svg{
        path{
            fill: $dark__mode__primary__theme_muted_color
        }
    }
    &--text {
        color: $text__default_color;
    }
}