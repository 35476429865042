@import "assets/scss/master";

.image__crop__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &--title {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: #060213;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    &--sub__title {
        font-size: 1rem;
        line-height: 24px;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }

    .crop__actions {
        display: flex;
        align-items: center;
        gap: 1rem;

        &--delete {
            cursor: pointer;
        }
    }

    .edit__image-btn {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
    }
    .crop__mode__container {
        .crop__modes {
            width: fit-content;
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid #e1e1eb;
            border-radius: 10px;
            padding: 4px;
            margin: 0;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
                border-color: $dark__mode__theme__border__color;
            }
            .crop__mode {
                font-size: 14px;
                line-height: 22px;
                color: #696d8c;
                list-style: none;
                padding: 6px 1rem;
                cursor: pointer;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                &.active__crop__mode {
                    font-weight: 600;
                    color: #ffffff;
                    background: #6837ef;
                    border-radius: 8px;
                    pointer-events: none;
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__primary__theme_color;
                    }
                }
            }
        }
    }
}
