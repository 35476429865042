@import "assets/scss/master";

.pre__nav {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $border_highlight_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    font-size: 16px;
    a {
        color: $muted__theme_color_2 !important;
        text-decoration: none !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
    .anchor-right {
        margin: 0 1.25rem;
    }
    svg {
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__white__color !important;
        }
        path {
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
        }
    }
    .pre__error {
        color: $danger__theme_color;
        width: fit-content;
        padding: 8px;
        font-size: 12px;
        border-radius: 10px 10px 10px 10px;
        margin-top: -6px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__green__color !important;
            color: $dark__mode__theme__white__color !important;
        }

        svg {
            width: 1rem !important;
            height: 1rem !important;
        }

    }
    p {
        text-transform: capitalize;
        margin-bottom: 0;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }
}
