@import "assets/scss/master";

.invite-team-search {
    background-color: $off__white__theme__color;
    min-height: 13.563rem;
    top: 170%;
    z-index: 1;
    width: 100%;
    height: auto;
    border-radius: 0.75rem;
    padding: 1rem;
    border: 1px solid #dee1e5;
    box-shadow: 0 4rem 4rem -3rem rgba(31, 47, 70, 0.12);
    #{$dark__mode__theme__selector} {
       background-color: transparent;
        border-color: $dark__mode__theme__border__color;
    }
    &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        &--btn {
            height: auto !important;
        }
    }

    &--checkbox__list {
        height: 13.75rem;
        overflow-y: auto;
    }

    &--checkbox__container {
        background: $muted__gray__theme__color;
    }

    .label {
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
    }

    input.form-check-input {
        &.input-check {
            background-color: #e6e6ed;
            border: 0;
            &:checked {
                background-color: $primary__theme_color;
            }
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
