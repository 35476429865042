@import "assets/scss/master";

.external__emails__header {
    // padding: 25px 24px 24px;
    padding: 16px 24px;
    border-bottom: 0.063rem solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--title {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $black__theme_color;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            cursor: pointer;
        }
        .is-closed {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &--sub__title {
        font-size: 14px;
        line-height: 18px;
        color: #696d8c;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
