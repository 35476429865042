@import "assets/scss/master";

.analytics-qa-vet-ticket {
    a {
        color: $primary__theme_color !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
        }
    }

    .table__container {
        min-height: unset !important;
    }

    h3 {
        color: #060213;
        font-family: Gelion;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
        &.qa-vet-others {
            margin-top: 40px;
        }
    }

    .qa-vet-table-dropdown {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}
