@import "assets/scss/master";
@import "assets/scss/mixins/mixins";

.signin {
    margin-top: 3.125rem;
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        color: $muted__theme_color_2;
        text-align: center;
    }
    .workspace--option {
        height: 4.188rem;
        padding: 1.125rem 1.438rem;
        border: 0.063rem solid #ecebf5;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background-color: #fff;
        display: flex;
        cursor: pointer;
        margin-bottom: 1rem;
        &.selected {
            border: 0.063rem solid $primary__theme_color;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
            }
        }
        p {
            font-size: 1.25rem;
            font-weight: 600;
        }
        .workspace--option-logo {
            height: 1.875rem;
            width: 1.875rem;
            margin-right: 0.813rem;
            border-radius: 0.313rem;
        }
    }
    &--forgot-password-link {
        display: flex;
        justify-content: right;
    }
    &--link {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }
    div.hide {
        opacity: 0;
        position: absolute;
        z-index: -1;
        transform: translateY(3.125rem);
    }
    div.show {
        opacity: 1;
        @include transition(0.5s);
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(-0.313rem);
        z-index: 1;
    }
}
