.analytics-qava-convo-dropdown{
    width: 100%;
    .table__container{
        width: 100% !important;
        .table__body td {
            border-bottom: none !important;
        }
        .table__body td:last-child {
            text-align: unset !important;
        }
    }
}