@import "assets/scss/master";

.date__picker__error__border {
    border: 0.063rem solid red !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
}

.react-datepicker__time-name {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker__day {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
    &:hover {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }
}

.react-datepicker__day-name {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}

.react-datepicker-popper .react-datepicker__navigation {
    padding-top: 12px;
    color: #000;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        border-color: $dark__mode__theme__border__color !important;
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker__header {
    border-bottom: solid 5px var(--light) !important;
    background: white !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        border-color: $dark__mode__theme__border__color !important;
    }
}

.react-datepicker__current-month {
    color: var(--dark) !important;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker__day.react-datepicker__day--today {
    border-radius: 7px;
    border: solid 2px var(--brand) !important;
    background-color: white !important;
    color: var(--dark) !important;
    width: 30px;
    height: 30px;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        color: $dark__mode__theme__white__color !important;
    }
}

.react-datepicker__day.react-datepicker__day--selected {
    border: none;
    border-radius: 7px;
    background-color: black;
    color: white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: var(--brand) !important;
    color: var(--dark) !important;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}
