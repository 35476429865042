@import "assets/scss/master";

.add-new-form-task {
    margin-bottom: 2rem;
    &__date {
        // display: flex;
        gap: 24px;
        margin-bottom: 40px;
        margin-top: 24px;
        width: 100%;
    }
    &__checkbox-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    &__select-collection {
        border: 1px solid #e1e1eb;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 1rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        margin-top: 28px;
    }
}
