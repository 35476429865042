@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/_master.scss";

.btn {
    font-family: $primary_font_family--medium;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.0.938rem;
    border-radius: 0.625rem !important;
    // border-radius: 10px;
    padding: 0.438rem 1.438rem;
    height: 3.438rem;
    white-space: nowrap;
    text-transform: capitalize !important;
    &--add {
        border: 0 !important;
    }
    &-close-ticket {
        height: 32px;
        width: fit-content;
        background-color: rgba(244, 107, 120, 0.1) !important;
        border-radius: 6px !important;
        font-family: $primary_font_family !important;
        font-size: 14px !important;
        color: #F25A68 !important;
        line-height: 12px !important;
    }
    &-plain {
        font-size: 1rem !important;
        color: $black__theme_color !important;
        border: 0.063rem solid $border_highlight_color !important;
        border-radius: 0.313rem !important;
        padding: 0.5rem 1.125rem !important;
        height: 2.688rem !important;
    }
    &-outline-secondary {
        color: #060213 !important;
    }
    &--connect {
        border: 0.063rem solid $border_highlight_color !important;
        height: auto;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
            border-color: $dark__mode__theme__border__color !important;
        }
        &:hover{
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }
    &__major{
        &--submit {
            border: 0.063rem solid $success__green_color !important;
            color: $success__green_color !important;
        }
    }
    &-primary {
        background: $primary__theme_color !important;
        #{$dark__mode__theme__selector} {
           background: $dark__mode__primary__theme_color !important;
        }
    }
    &-sm {
        font-size: 0.875rem;
        height: 3rem;
    }
    &-deletable {
        font-size: 0.875rem !important;
        height: 2.25rem !important;
        border-radius: 0.375rem !important;
    }
    &-xsm {
        height: 2.5rem !important;
    }
    &-xs {
        font-size: 0.875rem !important;
        height: 2.063rem !important;
        display: flex !important;
        align-items: center !important;
    }
    &-muted {
        color: #060213 !important;
    }
    &-faded {
        color: #b0b7c3 !important;
    }
    &-success,
    &-danger {
        color: #fff !important;
    }
    &--file-label {
        border: 0 !important;
        color: #060213 !important;
        background-color: #f8f8f8 !important;
        border-radius: 0.313rem !important;
        font-size: 1rem;
        font-family: $primary_font_family;
        font-weight: 400 !important;
        height: 3.313rem !important;
    }
    &--file-label-full {
        @extend .btn--file-label;
        height: 3.438rem !important;
    }
    &[class*="btn-outline"]:hover {
        color: #fff;
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
    @include responsiveMobile($sm) {
        height: 3rem;
    }
    &.transparent {
        border: none;
        outline: none;
        background: transparent;
        padding: 0 !important;

        &:hover {
            color: default;
        }
    }
    &.text__meta-purple {
        &:hover {
            color: #6837ef;
        }
    }
    &-apply {
        width: 100% !important;
        border-radius: 8px;
        border: 0 !important;

        background: #6837ef !important;
        color: #fff !important;

        &.disabled {
            background: #E6E6ED !important;
            color: #A3A3C2 !important;
        }
    }
}