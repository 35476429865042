.tside__heading__container {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    h6 {
        margin: 0 auto !important;
    }
    .sort__trigger {
        cursor: pointer;
        &--desc {
            transform: rotate(180deg);
        }
        &--asc {
            transform: rotate(0deg);
        }
    }
    
}

