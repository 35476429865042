@import "assets/scss/master";

.create-reminder {
    &__text {
        padding: 16px 20px;
        // height: 58px;
        background: #f8f8fa;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: $muted__theme_color_2;
        margin-bottom: 24px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background-color: $dark__mode__theme__color;
        }
    }
    &__checkbox {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $text__default_color;
        margin-bottom: 40px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
