@import "assets/scss/master"; 

.view__team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.563rem !important;
    
    &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

       .header {
        width: 50%;
        
        &--text {
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
       }

       &--btn {
        color: $text__default_color;
        font-weight: 400;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
      }
    }
}    