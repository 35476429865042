@import "assets/scss/master";

.formbuilder__branch {
    .empty__branch {
        background: #f8f8fa;
        // border: 1px dashed #ecebf5;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23ECEBF5FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 6px;
        padding: 17px;
        text-align: center;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color;
        }
        span {
            font-size: 0.875rem;
            letter-spacing: 0.15px;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
    .added__branch {
        background: $success__theme_color;
        padding: 17px;
        text-align: center;
        border-radius: 6px;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.15px;
            color: #fff;
            text-transform: capitalize;
        }
    }
}
