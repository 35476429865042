.filter {
    display: flex;
    justify-content: space-between;
    .filter__select-group {
        display: flex;
        gap: 24px;
    }
    &__select {
        width: 180px;
        margin-top: 1.5rem;
    }
    &__search {
        margin-top: 1.5rem;
        width: 280px;
        margin-left: auto;
    }
}
