@import "../../../../../../../assets/scss/master";

.personas__name {
    display:  flex;
    gap: 0.75rem;
    align-items: center;

    img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 100%;
    }
}