@import "assets/scss/master.scss";

.analytics__bar__header {
    margin: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__card-container {
        display: flex;
        gap: 20px;
    }
    .analytics__bar__header__card {
        display: flex;
        width: 10rem;
        padding: 0.25rem 0.75rem 0.5rem 0.75rem;
        flex-direction: column;
        border-radius: 0.75rem;
        border: 1px solid $border__theme_color;
        background: $off__white__theme__color;

        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__theme__border__color;
            background: $dark__mode__theme__background__color;
        }

        .bar__header__card__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__label {
                color: $text__muted__theme__color;
                font-size: 0.75rem;
                line-height: 1.25rem;

                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }

        .bar__header__card__bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__label {
                color: $text__default_color;
                font-size: 1rem;
                line-height: 1.5rem;

                #{$dark__mode__theme__selector} {
                    color: $--background-background-dark;
                }
            }

            &__value {
                font-size: 0.75rem;
                line-height: 1.25rem;

                &.red {
                    color: $dark__mode__theme__red__color;
                }

                &.green {
                    color: $dark__mode__theme__green__muted__color;
                }
            }
        }
    }

    .analytics__toggle {
        display: flex;
        align-items: center;

        .average {
            padding: 0.5rem 0.5rem 0.75rem 0.75rem;
            border-radius: 0.5rem 0rem 0rem 0.5rem;
            border-top: 1px solid $muted__theme_color_2;
            border-left: 1px solid $muted__theme_color_2;
            border-bottom: 1px solid $muted__theme_color_2;
            color: $muted__theme_color_2;
            font-size: 0.875rem;
            line-height: 1.25rem;
            cursor: pointer;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
                border-top: 1px solid $dark__mode__theme__text__color;
                border-left: 1px solid $dark__mode__theme__text__color;
                border-bottom: 1px solid $dark__mode__theme__text__color;
            }
        }

        .percentage {
            display: flex;
            width: 2.6rem;
            padding: 0.5rem 0.5rem 0.75rem 0.75rem;
            justify-content: center;
            align-items: center;
            border-radius: 0rem 0.5rem 0.5rem 0rem;
            border-top: 1px solid $muted__theme_color_2;
            border-right: 1px solid $muted__theme_color_2;
            border-bottom: 1px solid $muted__theme_color_2;
            color: $muted__theme_color_2;
            font-size: 0.875rem;
            line-height: 1.25rem;
            cursor: pointer;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
                border-top: 1px solid $dark__mode__theme__text__color;
                border-right: 1px solid $dark__mode__theme__text__color;
                border-bottom: 1px solid $dark__mode__theme__text__color;
            }
        }

        & .active {
            border: 1px solid $dark__mode__primary__theme_color;
            background: $default__capsule__theme_color;
            color: $dark__mode__primary__theme_color;

            #{$dark__mode__theme__selector} {
                color: $--background-background-dark;
                background-color: $dark__mode__theme__background__color;
                border: 1px solid $dark__mode__primary__theme_muted_color;
            }
        }
    }
}
