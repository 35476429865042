ol.message-thread {
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    padding-right: 1rem;
    &::-webkit-scrollbar-thumb {
        height: 5rem !important;
    }
    &::-webkit-scrollbar-thumb:hover {
        height: 5rem !important;
    }
    li {
        list-style: none;
    }
}

.customer__chat__img {
    img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
    }
}
