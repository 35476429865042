.forms__pf-action {
    display: flex;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    button {
        height: fit-content !important;
        background: transparent !important;
    }
    .forms__pf__action__button {
        border-radius: 4px;
        padding: 4px 0.625rem;
        margin-right: 0.625rem;
    }
}
