@import "assets/scss/master";

input[type="range"] {
    height: 25px;
    -webkit-appearance: none;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color;
    }
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: $muted__theme_color;
    border-radius: 1px;
    border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid $muted__theme_color;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $primary__theme_color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $muted__theme_color;
}
input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: $muted__theme_color;
    border-radius: 1px;
    border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid $muted__theme_color;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}
input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type="range"]::-ms-fill-lower {
    background: $muted__theme_color;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
    background: $muted__theme_color;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid $muted__theme_color;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
    background: $muted__theme_color;
}
input[type="range"]:focus::-ms-fill-upper {
    background: $muted__theme_color;
}
