@import "assets/scss/master";

.mt__filter--container {
    border-top: 1px solid #ecebf5;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    height: 100px;
    padding: 1rem;
    overflow: auto;
    margin-top: 1rem;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    .empty__text {
        // min-;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        h6 {
            color: #696d8c;
            font-size: 0.875rem;
            letter-spacing: 0.15px;
            margin: 0;
        }
    }
}
