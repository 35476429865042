@import "assets/scss/master";

.user {
    &__wrapper {
        max-width: 550px;
    }

    &__description {
        color: $muted__theme_color_2;
    }
}
