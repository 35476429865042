.custom__emoji__picker {
    position: relative;
    
    &.hide__emoji__picker {
        .emoji-picker-react { 
            display: none !important;
        }
    }

    &.right__display {
        .emoji-picker-react {
            left: 0 !important;
        }
    }

    .emoji-picker-react {
        z-index: 500;
        right: 0 !important;
        position: absolute !important;
        bottom: 30px !important; 
    }

    .emoji__toggler {
        cursor: pointer;
    }
}