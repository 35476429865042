@import "assets/scss/master.scss";

.analytics__bar__chart {
    .analytics__title {
        color: $text__default_color;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.625rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }
}