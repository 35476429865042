@import "assets/scss/master";

.new-followup-form {
    .automation_fields {
        display: flex;
        gap: 12px;
        section {
            width: 100%;
        }
    }
    .create__comment__input__group {
        width: 100% !important;
        margin-bottom: 10px;
        & .mentions {
            max-height: unset !important;
            min-height: 100px !important;

            .mentions__highlighter {
                height: unset !important;
            }
        }
        textarea {
            border-radius: 10px !important;
            padding: 16px !important;
            &:focus {
                border: 1px solid $primary__theme_color !important;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__primary__theme_muted_color !important;
                }
            }
        }
    }
}
