@import "assets/scss/master";

.heading {
    h1 {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.438rem;
        color: $text__default_color;
        margin-bottom: 0.5rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }

    p {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $muted__theme_color_2;
        margin-bottom: 1.5rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
