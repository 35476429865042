@import "assets/scss/master";

.bordered_table {
    &--row,
    &--head {
        padding: 1rem 0;
        border-bottom: 0.063rem solid rgba(30, 31, 32, 0.1);
        min-height: 3.438rem;
        margin-right: 0;
    }
    &--row {
        margin: 0 0.063rem;
    }
}
