@import "assets/scss/master";
.task__reminder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 16px;
    border: 1px solid #ecebf5;
    border-radius: 10px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &__text {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #060213;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    &__duration__container {
        display: flex;
        align-items: center;
    }
}
