@import "assets/scss/master";

.branch__scheduled__time {
    background: #f5f5f7;
    width: 200px;
    border: 1px solid #e1e1eb;
    border-radius: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #060213;
    padding: 12px 16px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        color: $dark__mode__theme__white__color;
        background-color: $dark__mode__theme__color;
    }
}
