.custom-url-field {
    &__button {
        // margin-top: -0.8rem;
        margin-bottom: 1rem;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
    }

    &__remove {
        margin-top: -1rem;
        // margin-bottom: 1rem;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        svg {
            width: 1rem;
            height: 1rem;
        }
    }
}
