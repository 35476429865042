.error {
    padding: 8px 16px;
    padding-right: 1.25rem;
    background-color: #fff5f5;
    border: 1px solid #ffccc7;
    border-radius: 2px;
}

.error__message {
    font-size: 15px;
    font-weight: 300;
    color: #dc3545;
}

.error__close-btn {
    padding: 0;
    font-size: 26px;
    line-height: 1;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    color: #dd6773;
    transition: all 300ms linear;
}

.error__close-btn:hover {
    color: #dc3545;
}
