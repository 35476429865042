@import "assets/scss/master";

.ticketaaresponse {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}

.ticketaaresponse__form-flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    .form-group {
        width: 100%;
    }
    button {
        width: fit-content;
    }
}
