@import "assets/scss/master";

.suggestion__header{
    padding: 1.25rem 0;
    border-bottom: 0.063rem solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}