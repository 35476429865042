.lds-spinner-small {
  color: official;
  display: block;
  margin: auto;
  position: relative;
  width: 22.33px;
  height: 22.33px;

  div {
    transform-origin: 10.67px 10.67px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0.063rem;
      left: 9.67px;
      width: 0.67px;
      height: 4.67px;
      border-radius: 6.67%;
      background: #ffffff;
    }

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        transform: rotate(#{($i - 1) * 30}deg);
        animation-delay: #{-1.2 + ($i * 0.1)}s;
      }
    }
  }

  &.black-spinner {
    div:after {
      background: #000;  // Black background when .black-spinner class is added
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}