@import "assets/scss/master";

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &--svg {
        margin-top: 6.25rem;
    }

    &--text {
        color: $muted__theme_color_2 !important;
        font-size: 1.25rem !important;
        margin-top: 0.719rem;
        margin-bottom: 0;
    }
    &--sub-text {
        margin-top: 1rem;
        color: $muted__theme_color_2 !important;

    }


}