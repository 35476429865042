@import "assets/scss/master";

.image__crop__upload {
    &--container {
        width: 100%;
        height: 340px;
        background: #f5f5f7;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color;
        }
    }

    &--action {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    &--call__to__action {
        font-size: 20px;
        line-height: 24px;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
