@import "assets/scss/master";

.ticket__email__attachment {
    &--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        &__text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #060213;
            text-align: center;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color
            }
        }

        &__sub__text {
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
}

.close__ticket__email__attachment__container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 29.33px;

    .close__ticket__email__attachment {
        width: fit-content;
        cursor: pointer;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }
}

.email__attachment__ticket__info {
    &--list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
        border: 1px solid #ecebf5;
        border-radius: 10px;
        padding: 28px 16px;
        max-height: 244px;
        overflow-y: auto;

        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }

        .ticket__info__checkbox__label {
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }

        .ticket__info__checkbox__inbox {
            border: 1px solid #e1e1eb;
            cursor: pointer;
            background-color: #ffffff;

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
                background-color: $dark__mode__theme__background__color;
            }

            &:checked {
                background-color: $primary__theme_color;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.upload__ticket__email__attachment {
    margin-bottom: 2rem;
    display: flex !important;
    gap: 4px;
    align-items: center !important;
    padding: 6px 8px;
    height: fit-content !important;
    border-radius: 4px;
}
