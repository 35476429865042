@import "assets/scss/master";

.ticket__phase__selector__dropdown {
    width: fit-content !important;

    .phase__selector__anchor {
        svg {
            path {
                fill: $light__theme_color;
            }
        }
    }
    
    .phase__selector__label {
        margin-right: 0 !important;
    }

    & .options--ticket__phase__selector {
        width: fit-content !important;
        right: -45px !important;
        top: 1.25rem !important;
    }

    & .ticket__source__radio__input__group {
        width: fit-content;
        display: flex;
        align-items: baseline;
        gap: 5px;
        cursor: pointer;

        & .ticket__source__radio__input__label {
            font-size: 14px;
            line-height: 16px;
            color: #696D8C;
            cursor: pointer;
        }
    }
}