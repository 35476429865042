@import "assets/scss/master";

.personal-profile {
    &__header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border__theme_color;
        padding-bottom: 1.25rem;
        margin-bottom: 15px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
    p {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
}
.user__avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
