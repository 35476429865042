@import "assets/scss/master";

.analytcis-qava-convo-suggs {
    .success {
        color: $success__green_color !important;
    }
    .failure {
        color: $danger__theme_color !important;
    }
    .table__body td:last-child {
        text-align: right;
    }
    .table__body .dropdown__element td:last-child {
        text-align: left !important;
    }
}
