@import "assets/scss/master";

.classes__container {
    position: relative;
    width: 100%;
    height: 9.375rem;
    overflow-y: auto;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 2.188rem 1.563rem;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &.extend__container__height {
        height: 13.375rem;
        padding: 0 1.563rem;
    }

    .shiny__line {
        min-width: 9.375rem;
        min-height: 1.875rem;
        border-radius: 0.625rem;
        margin-bottom: 0.625rem;
        margin-right: 0.625rem;
    }
}
