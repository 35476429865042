@import "assets/scss/master";

.tabbed__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border__theme_color;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #fff;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }
    a {
        padding: 18px 40px;
        font-size: 16px;
        white-space: nowrap;
        color: $muted__theme_color_2 !important;
        letter-spacing: 0.15px;
        text-decoration: none;
        margin: 0;
        transition: all 2s;
        position: relative;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            bottom: -0.063rem;
            width: 0%;
            height: 0.063rem;
            background: $primary__theme_color;
            transition: all 1s;
            #{$dark__mode__theme__selector} {
                border: 0.1px solid $dark__mode__primary__theme_muted_color;
            }
        }
        &.active {
            color: $primary__theme_color !important;
            font-family: $primary_font_family--medium;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color !important;
            }
            &::before {
                width: 100%;
            }
        }
    }
}
