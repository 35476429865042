@import "assets/scss/master";

.custom__card {
    padding: 1.25rem 1.5rem;
    padding-bottom: 0;
    border-radius: 0.625rem;
    border: 1px solid $border_highlight_color;
    #{$dark__mode__theme__selector} {
       border-color: $dark__mode__theme__border__color;
    }
}

.accordion__card--item {
    &:last-child {
        .accordion__header {
            border: 0;
        }
    }
    &.active {
        .accordion__header {
            border: 0;
            &::before {
                transform: rotate(0deg) !important;
                background-color: $black__theme_color;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__theme__white__color;
                }
            }
        }
    }
    .accordion__body {
        // display: none !important;
        margin-bottom: 1rem;
    }
    .accordion__header {
        position: relative;
        padding-top: 6px;
        padding-bottom: 15px;
        padding-left: 15px;
        margin-bottom: 0.625rem;
        border-bottom: 1px solid $border_highlight_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            transform: rotate(-90deg);
            display: block;
            top: 12px;
            left: 5px;
            display: block;
            width: 12px;
            height: 7px;
            background-color: #a3a3c2;
            transition: all 1s;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__text__color;
            }
            mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1648048111/Shape_iejzg0.svg");
            -webkit-mask-image: url("https://res.cloudinary.com/metacare/image/upload/v1648048111/Shape_iejzg0.svg");
        }

        .logo_container {
            width: 35px;
            height: 35px;
            margin-left: auto;
            img {
                width: 100%;
                height: auto;
            }
        }

        .sub_text {
            text-transform: capitalize;
            letter-spacing: 0.15px;
            h6 {
                color: $black__theme_color;
                font-family: $primary_font_family--medium;
                font-size: 18px;
                margin-bottom: 5px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
            p {
                font-size: 0.875rem;
                color: $muted__theme_color_2;
                margin-bottom: 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }
    }
}
