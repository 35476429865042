@import "assets/scss/master";

.file__email__attachment {
    &--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        &__text {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #060213;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color
            }
        }

        &__sub__text {
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
}

.close__file__email__attachment__container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 29.33px;

    .close__file__email__attachment {
        width: fit-content;
        cursor: pointer;

        svg {
            width: 1rem;
            height: 1rem;
        }

        &.disable__close__file__email__attachment {
            pointer-events: none;
        }
    }
}

.save__email__attachments {
    margin-top: auto;
}
