@import "assets/scss/master";

.connected__form {
    color: $muted__theme_color_2;
    background: #f5f5f7;
    transition: all 0.2s;
    width: fit-content;
    letter-spacing: 0.15px;
    font-size: 0.875rem;
    padding: 6px 16px;
    text-transform: capitalize;
    font-family: $primary_font_family;
    border: 1px solid #f5f5f7;
    border-radius: 0.625rem;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        border: none;
        background: $dark__mode__theme__color;
        color: $dark__mode__theme__text__color;
    }
}