@import "assets/scss/master";

.ticketaa-scenarios {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &__list{
        overflow-y: scroll;
        padding-bottom: 2rem;
        height: 12rem;
    }
    &__actions {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 16px;

        .pagination-search {
            width: 100px !important;
            padding: 6px 16px !important;
        }

        .add-button {
            width: fit-content !important;

            span {
                font-family: Gelion;
                font-size: 14px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }

    &__content {
        padding: 10px 16px;
        gap: 8px;
        border-radius: 10px;
        background: #f8f8fa;
        margin-top: 16px;

        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }

        &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;

            h6 {
                color: $black__theme_color;
                font-family: Gelion;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                margin-top: 0;
                margin-bottom: 0;

                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
        }
    }

    &__sent {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 10px;
        background: #f9f9fa;
        margin-top: 12px;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color !important;
        }

        .form-check-input:checked {
            background-color: #1d9169 !important;
        }

        &.active {
            border: 1px solid #1d9169;
        }

        span {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
    }
}

.send_to_chat {
    width: fit-content !important;
    margin-left: auto !important;
    margin-right: auto !important;
}