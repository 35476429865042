@import "assets/scss/master";

.tickets__search__container {
    position: relative;
    width: 539px;

    .tickets__search {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 54px;
        width: 100%;
        background: transparent;
        border-radius: 5px;
        padding: 15px 20px;

       
        .tickets__text {
            font-size: 16px;
            line-height: 24px;
            color: #a3a3c2;

            #{$dark__mode__theme__selector} {
                color: $light__mode__theme__background__color !important;
            }
        }

        .current__search__filter {
            background: #fcfcfd;
            border: 1px solid #ecebf5;
            border-radius: 4px;
            padding: 6px 12px;
            display: flex;
            align-items: baseline;
            gap: 6.82px;

            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
                border-color: $dark__mode__theme__border__color;
            }
            
            .current__search__filter__text {
                font-size: 12px;
                line-height: 14px;
                color: #696d8c;
                #{$dark__mode__theme__selector} {
                    color: $light__theme_color;
                }
            }

            .remove__filter {
                width: 8px;
                height: 8px;
                cursor: pointer;
            }
        }

        .tickets__search__input__field {
            width: 100%;

            .tickets__search__input {
                outline: none;
                border: none;
                width: 100%;
                background: #fafafc;
                font-size: 16px;
                line-height: 24px;
                color: #a3a3c2;

                #{$dark__mode__theme__selector} {
                    // border:1px solid #a3a3c2;
                    background-color: $dark__mode__theme__color !important;
                }

                &::placeholder {
                    font-size: 16px;
                    line-height: 24px;
                    color: #a3a3c2;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color
                    }
                }
            }
        }

        .tickets__search__actions {
            display: flex;
            align-items: center;
            gap: 14.63px;

            svg {
                cursor: pointer;
                path {
                    #{$dark__mode__theme__selector} {
                        stroke: $light__theme_color;
                    }
                }
            }
        }
    }

    .tickets__search__filters {
        position: absolute;
        z-index: 700;
        top: 65px;
        width: 100%;
        padding: 1rem;
        background: #ffffff;
        border: 1px solid #dee1e5;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border-radius: 10px;

        #{$white__mode__theme__selector} {
            background-color: $light__mode__theme__background__color !important;
        }

        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            background-color: $dark__mode__theme__color !important;
        }

        .tickets__search__filters__text {
            font-size: 16px;
            line-height: 18px;
            color: #696d8c;

            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }

        .tickets__search__filters__list {
            margin-top: 12px;
            display: flex;
            align-items: center;
            gap: 12px;

            .tickets__search__filter {
                padding: 8px 12px;
                background: #fcfcfd;
                border: 1px solid #ecebf5;
                border-radius: 6px;
                font-size: 14px;
                line-height: 16px;
                color: #696d8c;
                cursor: pointer;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                    color: $dark__mode__theme__text__color;
                    background-color: $dark__mode__theme__color;
                }
            }

            .tickets__search__filter__active {
                border: 1px solid $primary__theme_color;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__primary__theme_muted_color;
                }
            }

            .tickets__search__date__filter {
                height: fit-content !important;

                .react-select__control {
                    height: 20px !important;
                    margin-bottom: -1rem;
                    font-size: 14px !important;
                    border-radius: 6px !important;
                }
            }
        }
    }
}
