@import "assets/scss/master";

.email__preview {
    height: auto;
    width: 100%;
    background: #fff;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }

    &__nav {
        padding: 12px 24px 12px 18px;
        border-bottom: 1px solid #ecebf5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        .back {
            display: flex;
            align-items: flex-end;
            width: fit-content;
            &__arrow {
                svg {
                    cursor: pointer;
                    height: 12px;
                    width: 18px;
                }
            }

            &__text {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #060213;
                cursor: pointer;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
        }
    }

    &__content {
        display: flex;
        height: 100%;

        .email__container {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;

            .email__history__action {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // margin-top: auto;
                position: relative;
                bottom: 0;
                padding: 24px 31px 24px 33.99px;
                border-top: 1px solid #ecebf5;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                }
                &__text__container {
                    display: flex;
                    align-items: center;
                    gap: 10.44px;
                    cursor: pointer;
                }

                &__text {
                    margin: 0;
                    font-size: 1rem;
                    line-height: 24px;
                    color: #696d8c;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color;
                    }
                }

                .close__email__history {
                    svg {
                        cursor: pointer;
                    }
                }
            }
        }

        .attachment__container {
            flex: 2;
            border-left: 1px solid #ecebf5;
            padding: 24px 16px 24px 32px;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
        }
    }
}
