@import "assets/scss/master";

.date__picker {
    outline: none;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color;
        background: $dark__mode__theme__color !important;
    }
}

input[type="time"]::-webkit-calendar-picker-indicator {
    #{$dark__mode__theme__selector} {
        filter: invert(100%);
    }
}
