@import "assets/scss/_master.scss";

.notification {
    padding: 1.5rem 0;
    border-bottom: 0.063rem solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    img {
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
    }

    h3 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 19px;
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }

    p {
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    .content {
        background: $light__grey__theme_color;
        border-radius: 0.375rem;
        width: 100%;
        padding: 0.75rem 0.875rem;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color;
        }

        p {
            color: $muted__theme_color_2;
            margin-bottom: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    .reference {
        margin-top: 0.438rem;

        &--content {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .user {
                display: flex;
                align-items: center;
                gap: 0.375rem;

                &--icon {
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 100%;
                    object-fit: contain;
                }
            }

            p {
                color: $muted__theme_color_2;
                margin-bottom: 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }

            .timestamp {
                display: flex;
                align-items: baseline;
                gap: 0.5rem;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                .category {
                    text-transform: capitalize;
                }
            }
        }
    }
}
