@import "assets/scss/master";

.attached__classes__table {
    margin-bottom: 3.25rem;

    &--header {
        display: flex;
        border-bottom: 1px solid $border__theme_color;
        padding-bottom: 0.5rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
         }
        &--text {
            color: $text__default_color;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.813rem;
            margin-bottom: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
             }
        }
    }

    &--list {
        display: flex;
        padding-top: 1.063rem;

    }
}