@import "assets/scss/master";

.analyzer {
    display: flex;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
    &-heading {
        font-weight: 600;
        font-size: 2.125rem;
        line-height: 160%;
    }
    &-container {
        padding: 0 3rem;
        padding-top: 1.188rem;
        .analyzer-query {
            &__search {
                height: 25.063rem;
            }
            .search-hide-label {
                display: none;
            }
            &__redo {
                display: flex;
                float: right;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: #696d8c;
                margin-bottom: 1.5rem;
                margin-top: 0.5rem;
                cursor: pointer;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                span {
                    margin-right: 0.5rem;
                }
            }
        }
    }
    .form-group {
        textarea {
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
                border-color: $dark__mode__theme__border__color !important;
            }

            &::placeholder {
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }
    }
    &-tags {
        border-left: 1px solid #f1f0f8;
        height: fit-content;
        padding: 2.063rem 3.625rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        h4 {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.125rem;
            color: #060213;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
        p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 135%;
            color: rgba(113, 111, 121, 0.74);
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__color;
            }
        }
        &-container {
            margin-top: 1.5rem;
            height: 100%;
            &__empty {
                background: #ffffff;
                border: 0.1px solid #696d8c;
                border-radius: 0.625rem;
                height: 34.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__background__color;
                    border-color: $dark__mode__theme__border__color;
                }
                p {
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color !important;
                    }
                }
            }
            &__non-selected {
                background: #ffffff;
                border: 0.1px solid #696d8c;
                border-radius: 0.625rem;
                height: 15rem;
                display: flex;
                align-items: center;
                justify-content: center;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__background__color;
                    border-color: $dark__mode__theme__border__color;
                }
            }
            p {
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
            .analyzer-message-tags {
                margin-top: 1rem;

                .analyzer-td__elements {
                    background: #ffffff;
                    border: 1px solid #e1e1eb;
                    border-radius: 0.625rem;
                    min-height: 7.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    #{$dark__mode__theme__selector} {
                        border-color: $dark__mode__theme__border__color;
                        background: $dark__mode__theme__background__color;
                    }
                    .analyzer-td__items {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 1rem 1rem;
                    }
                }
            }
        }
    }
}
