@import "assets/scss/master";

.ticketaa-next-steps {
    width: 100%;
    min-height: 169px;
    height: fit-content;
    padding: 16px 24px;
    gap: 24px;
    border: 0px solid #ecebf5;
    background: #f8f8fa;
  

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }
    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    &__list {
        width: 100%;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ecebf5;
        padding: 14px 0px;
        cursor: pointer;
        &.disabled {
            opacity: 0.3 !important;
            cursor: not-allowed;
        }
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        &__text {
            color: #696d8c;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
        .d-flex {
            gap: 12px;
            align-items: center;
        }
        &__action {
            display: flex;
            padding: 4px 8px 5px 8px;
            justify-content: center;
            gap: 10px;
            border-radius: 8px;
            background: #2f80ed;
            color: #fff;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
        }
        svg {
            fill: #696d8c !important;
            margin-top: -8px;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__text__color !important;
            }
            path {
                fill: #696d8c !important;
                #{$dark__mode__theme__selector} {
                    fill: $dark__mode__theme__text__color !important;
                }
            }
        }

        .lds-spinner-small {
            width: fit-content !important;
            margin-left: unset !important;
            margin-right: 22px !important;
        }
        .lds-spinner-small div:after {
            background: $primary__theme_color !important;
            #{$dark__mode__theme__selector} {
                background: $dark__mode__theme__white__color !important;
            }
        }
    }
}
