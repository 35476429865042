@import "assets/scss/master";

.attached-canned-content {
    margin-bottom: 1rem;
    .canned__content__label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__text {
            font-weight: 600;
            font-size: 16px;
            line-height: 13px;
            color: #060213;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    .canned__contents__container {
        padding: 16px;
        border: 1px solid #e1e1eb;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 198px;
        overflow-y: scroll;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        .canned__content__container {
            padding: 8px 16px;
            width: 100%;
            background: #f5f5f7;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
            }
            .canned__content {
                .canned__content__subject {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #060213;
                    margin: 0;
                    #{$dark__mode__theme__selector} {
                        color: $light__theme_color
                    }
                }

                .canned__content__body {
                    font-size: 14px;
                    line-height: 22px;
                    color: #696d8c;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color;
                    }
                }
            }

            .canned__content__options {
                svg {
                    cursor: pointer;
                }
            }
        }
    }
}
