@import "assets/scss/master";

.create__comment__input__group {
    width: 80%;

    &.full--input {
        width: 100% !important;
        margin-bottom: 10px;
        & .mentions {
            max-height: unset !important;
            min-height: 100px !important;
    
            .mentions__highlighter {
                height: unset !important;
            }
        }
        textarea {
            border-radius: 10px !important;
            padding: 16px !important;
            &:focus {
                border: 1px solid $primary__theme_color !important;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__primary__theme_muted_color !important;
                }
            }
        }
    }
    label {
        margin-bottom: 10px;
    }
    .mentions {
        max-height: 45px !important;

        .mentions__highlighter {
            height: 45px;
        }

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }

        textarea {
            outline: none !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }

        & .mentions__suggestions {
            top: 12px !important;
            z-index: 6 !important;

            .mentions__suggestions__list {
                background-color: white;
                border: 1px solid rgba(0, 0, 0, 0.15);
                font-size: 10pt;
                max-height: 250px;
                overflow-y: auto;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                    background-color: $dark__mode__theme__color;
                }
            }

            .mentions__suggestions__item {
                padding: 5px 15px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }

            .mentions__suggestions__item--focused {
                background-color: #cee4e5;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__theme__color;
                }
            }
        }
    }

    .mentions__error {
        font-size: 11px;
        margin: 0;
        color: $danger__theme_color;
        z-index: 4;
        position: relative;

        & span {
            color: $capsule_highlight_color;
            cursor: pointer;
        }
    }
}
