@import "../../../assets/scss/animate/animate.scss";

.shine__loader {
    border-radius: 0px;
    width: 120px;
    height: 26px;
    overflow: hidden;
    position: relative;
    animation: shine-animation 1s infinite cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation: shine-animation 1s infinite cubic-bezier(0.4, 0, 1, 1);
}

#metaInfinite {
    font-size: 8.5vw;
    font-weight: bolder;
    text-align: center;
    margin: 0;
    background-size: contain;
    margin: auto;
    width: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: shine-animation 2s infinite ease-in-out;
    -webkit-animation: shine-animation 2s infinite ease-in-out;
}

.shiny__background {
    animation: shine-animation 2s infinite cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation: shine-animation 2s infinite cubic-bezier(0.4, 0, 1, 1);
}

.shiny__line {
    width: 120px;
    height: 1.25rem;
    border-radius: 3px;
    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear;
  }
  