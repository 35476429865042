@import "assets/scss/master";

.tag__list {
    margin-bottom: 1.25rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
    }
    button {
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
        }
    }
}

.tagging__body {
    padding-top: 1.25rem;
}

.key__Phrase__header {
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $border__theme_color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}

.canned__response__header {
    padding: 1.25rem 0;
    border-bottom: 0.063rem solid $border__theme_color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}

.suggestion__header {
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}

.tagging__suggestions {
    border-bottom: 0.063rem solid $border__theme_color;
    padding: 1.25rem 0;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
    }
}
