@import "assets/scss/master";

.classification {
    background: $light__theme_color;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 10px;
    color: $muted__theme_color_2;
    width: fit-content;
    padding: 6px 1rem;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        background: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }

    &.active__classification {
        background: $primary__theme_color;
        border: 0.063rem solid $primary__theme_color;
        color: $light__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
            border-color: $dark__mode__primary__theme_muted_color
        }
    }
}