@import "assets/scss/master";

.reminders {
    &__empty {
        margin-top: 1.5rem;
        background: #ffffff;
        border: 1px solid $border__theme_color;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20rem !important;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
            border-color: $dark__mode__theme__border__color !important;
        }
        & .empty--svg {
            margin-top: 0 !important;
        }
    }
}
