@import "assets/scss/master";

.file__email__attachment--upload__area {
    background: #fafafc;
    border: 1px dashed #e1e1eb;
    border-radius: 12px;
    padding: 35.5px 0 32px;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }
    .drag__and__drop__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .drag__and__drop__cta {
        font-size: 16px;
        line-height: 19px;
        color: #696d8c;
        margin: 19.5px 0 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
        .browse__file {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #696d8c;
            text-decoration: underline;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }

    .max__file__size {
        font-size: 15px;
        line-height: 18px;
        color: #696d8c;
        font-style: normal;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
    }
}
