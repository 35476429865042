@import "assets/scss/master";
.dropdown {
    .dropdown-menu {
        border: 1px solid #ecebf5;
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
            background-color: $dark__mode__theme__color !important;
            color: $light__theme_color !important;
            button {
                color: $light__theme_color !important;
                &.focus{
                    background: transparent !important;
                }
                &:hover {
                    background-color: $dark__mode__theme__capsule__count__color !important;
                }
            }
        }

        &.show {
            left: -3px !important;
            top: -100px !important;

            &.capsule__bottom__menu {
                left: -3px !important;
                top: 45px !important;

                &::after {
                    content: "";
                    display: none;
                }

                &::before {
                    content: "";
                    display: none;
                }
            }

            &.focus{
                background: transparent !important;
            }
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 12px 0px 12px;
            border-color: #ffffff transparent transparent transparent;
            left: 0;
            right: 0;
            margin-left: 0;
            margin-right: auto;
            margin-bottom: -8px;
            &.focus{
                background: transparent !important;
            }

            #{$dark__mode__theme__selector} {
                display: none;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0px 8px;
            border-color: #ecebf5 transparent transparent transparent;
            z-index: -100;
            left: 0;
            right: 0;
            margin-left: 4px;
            margin-bottom: -8px;
            &.focus{
                background: transparent !important;
            }

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__color transparent transparent transparent !important;
            }
        }
    }
}
