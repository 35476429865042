@import "assets/scss/master";

.tickets__filter--selector {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.15px;
    font-size: 0.875rem;
    white-space: nowrap;
    padding: 10px 16px;
    text-align: center;
    text-transform: capitalize;
    font-family: $primary_font_family;
    border-width: 0.063rem;
    border-style: solid;
    border-color: #e1e1eb;
    border-radius: 0.625rem;
    cursor: pointer;
    height: 42px;
    margin-right: 1.25rem;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border: none;
    }

    span {
        color: $muted__theme_color_2;
        line-height: 12px;
        // color: $light__theme_color !important;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }
    &.message--tag,
    &.ticket--info {
        height: 36px;
        background: #f5f5f7;
        border-color: #f5f5f7;
        padding: 2px 1.25rem;
        #{$dark__mode__theme__selector} {
            background-color: #292929;
            // border: none;
        }
        .selector_count {
            // background-color: #e1e1eb;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__capsule__count__color;
            }
        }
        &.active {
            background-color: white !important;
            #{$dark__mode__theme__selector} {
                border: 1px solid $dark__mode__theme__border__color;
                background-color: $dark__mode__theme__color !important;
            }
            span {
                color: #060213;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
            .selector_count {
                color: $light__theme_color !important;
            }
        }
    }

    &.active {
        span {
            color: #fff;
        }
        .selector_count {
            color: #fff;
        }
        &.with-classi {
            border-color: $black__theme_color !important;
            #{$dark__mode__theme__selector} {
                border: 1px solid $dark__mode__theme__border__color !important;
            }
            span {
                color: $black__theme_color;
            }
            .selector_count {
                color: $black__theme_color;
            }
        }
    }

    & .selector__value {
        display: flex;
        align-items: center;
        gap: 0.625rem;
    }

    .selector_count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: #e1e1eb;
        text-align: center;
        font-size: 12px;
        font-family: $primary_font_family--medium;
        color: #696d8c;
        position: relative;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__capsule__count__color;
        }
        .new__circle {
            width: 8px;
            height: 8px;

            background: #f25a68;
            border-radius: 6px;
            position: absolute;
            top: -2px;
            right: -2px;
        }
    }
}
