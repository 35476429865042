@import "assets/scss/master";

.table_app__users {
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.625rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .app_users_header {
        padding: 1rem 1.25rem;
        border-bottom: 0.063rem solid $border__theme_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        h6 {
            font-size: 1rem;
            font-family: $primary_font_family--medium;
            color: $text__default_color;
            margin-bottom: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
    .app_user_body {
        padding: 1rem 1.25rem;
        color: $muted__theme_color_2;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
        p {
            margin-bottom: 1rem;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-top: 0.5rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
}
