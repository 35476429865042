@import "assets/scss/master";

.option__select {
    & .css-1s2u09g-control {
        border: none;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            background-color: $dark__mode__theme__color !important;
        }
        &:hover {
            border: none;
        }
    }

    & .css-6j8wv5-Input {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    & .css-1pahdxg-control {
        border: none;
        box-shadow: none;

        &:hover {
            border: none;
        }
    }
}

.option__select__value.option__select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.option__select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
    input {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
}




.option__select__menu {
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        color: $dark__mode__theme__white__color !important;
    }
}

.option__select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
    input {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
}

.option__select__control {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
        background-color: $dark__mode__theme__background__color !important;
        border-color: $dark__mode__theme__border__color !important;
    }
    .option__select__indicator {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }
}

.option__select__option {
    cursor: pointer !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
    }
}

.option__select__option.option__select__option--is-selected {
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__capsule__count__color !important;
    }
}

.option__select__option:hover {
    cursor: pointer !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__capsule__count__color !important;
    }
}

.option__select__value.option__select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.option__select__single-value {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.option__select__placeholder {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}
