@import "assets/scss/master";
.status-map-header {
    border: 1px solid #ecebf5;
    height: 74px;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    #{$dark__mode__theme__selector} {
        border: none;
        border-bottom: 1px solid $dark__mode__theme__border__color;
        border-color: $dark__mode__theme__border__color;
    }
    &__inner {
        width: 100%;
        display: flex;
        align-items: center;
        .metric__group {
            display: flex;
            margin-left: 2rem;
        }
    }
}
