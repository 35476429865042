@import "assets/scss/master";

.ticket__schedule {
    margin-bottom: 1.875rem;

    .ticket__header {
        font-size: 1.125rem;
        color: $text__default_color;
        margin-bottom: 1rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    span{
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    };
    
    &--date {
        display: flex;
        align-items: baseline;

        &__title {
            padding: 0.813rem 1rem;
            border-radius: 0.625rem 0 0 0.625rem;
            background: #e1e1eb;
            #{$dark__mode__theme__selector} {
                background: $dark__mode__theme__color;
                color: $dark__mode__theme__white__color;
            }
        }

        &__date__picker__container {
            display: flex;
            border: 0.063rem solid #e1e1eb;
            padding: 0.5rem 1rem;
            border-radius: 0 0.625rem 0.625rem 0;
            align-items: baseline;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
        }
    }
}
