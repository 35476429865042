@import "assets/scss/master";

.view-gd-update-detail {
    // padding-top: 24px;
    h5 {
        color: #696d8c;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        margin-top: 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            border-color: $dark__mode__theme__border__color;
        }
    }

    .task__capsule {
        display: flex;
        height: fit-content;
        align-items: center;
    }
    li {
        color: var(--icons-fonts, #fff);
        font-feature-settings: "clig" off, "liga" off;
        // font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: #065fb2;
        height: 40px !important;
        // list-style: circle;
    }
}
