@import "assets/scss/master";

.ticketaa-instruction {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    svg {
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__white__color;
        }
        path {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
            }
        }
    }
    p {
        color: #696d8c;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    div {
        display: flex;
        justify-content: space-between;
    }
}
