@import "assets/scss/master";

.gd-update-item {
    color: #696d8c;
    border: 1px solid $border_highlight_color;
    cursor: pointer;
    font-weight: 700;
    list-style: none;
    border-radius: 10px;
    // padding: 20px;
    font-family: Gelion;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    // height: 32px;
    padding: 6px;
    align-items: center;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        border-color: $dark__mode__theme__border__color;
    }
    display: flex;
    gap: 4px;
    span {
        border-radius: 4px;
        display: flex;
        padding: 6px;
        align-items: center;
        gap: 8px;
        color: #696d8c;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        border-radius: 4px;
        background: #f5f5f7;
        #{$dark__mode__theme__selector} {
            background: rgba(#f5f5f7, 0.3);
            color: $dark__mode__theme__white__color;
        }
    }
    &.active {
        background: #065fb2;
        border-color: #065fb2;
        color: $light__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
        span {
            background-color: rgba(transparent, 0.4);
            color: white;
            #{$dark__mode__theme__selector} {
                // background: #065fb2;
                color: $dark__mode__theme__white__color;
            }
        }
    }
}
