@import "assets/scss/master";

.message__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: flex-end;
    cursor: pointer;

    &.message-reply {
        // @include animation--fadedown(0);
        align-items: flex-start;
    }

    &.selected {
        padding: 10px 0;
        background: #4650d729;
        .message__content {
            background-color: $primary__theme_color !important;
            color: $light__theme_color !important;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__primary__theme_muted_color !important;
            }
        }
    }
}

.message__metadata {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
