@import "assets/scss/master";

.analyticsqa-timeline-messages {
    padding: 41px;
    border-right: 1px solid #ecebf5;
    display: grid;
    flex-direction: column;
    gap: 32px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}
