@import 'assets/scss/master.scss';

.message__options{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;

    .branch__option {
        white-space: nowrap;
        border-radius: 14px;
        background-color: $light__theme_color;
        border: 1px solid $border_highlight_color;
        // cursor: pointer;
        color: $text__default_color;
        outline: none;
        font-size: 14.5px;
        letter-spacing: 0.05px;
        padding: 7px 12px !important;

        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color;
            color: $dark__mode__theme__text__color;
            border-color: $dark__mode__theme__border__color;
        }

        &:disabled {
            color: $text__default_color;
        }

        &.active {
            border-color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
            }
        }

        &.__fade_out {
          color: #696D8C;
        }
    }
}
