@import "assets/scss/master";

.convo__map {
    min-height: 400px;
    background: url(https://res.cloudinary.com/metacare/image/upload/v1654002124/Slice_1_ouxo0y.svg);
    border-radius: 0.625rem;
    border: 1px solid #e1e1eb;
    overflow: hidden;
    position: relative;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background:url(https://res.cloudinary.com/metacare/image/upload/v1688225561/background_1_vzrmu5.svg);
    }

    .meta__container {
        padding-left: 0 !important;

        &.horizontal-scrollable {
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .branch__column {
            height: 360px;
            overflow: scroll;
            padding: 0 1.25rem;
            border-right: 0.5px solid #e1e1eb;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
        }

        .convo__count {
            border-top: 1px solid #e1e1eb;
            border-right: 1px solid #e1e1eb;
            background-color: #fff;
            text-align: center;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            position: relative;
            background: #fff;
            z-index: 4;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__background__color;
                border-color: $dark__mode__theme__border__color;
            }
            .count__span {
                border: 1px solid #e1e1eb;
                border-radius: 4px;
                width: fit-content;
                color: #696d8c;
                font-size: 12px;
                padding: 4px 8px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                    border-color: $dark__mode__theme__border__color;
                }
            }
        }
    }
}
