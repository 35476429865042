@import "assets/scss/master";

.email__content__container {
    padding: 0.5rem 12px;
    background: #f9f9fa;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
    }

    &--subject {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #060213;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    &--content {
        font-size: 14px;
        line-height: 22px;
        color: #696d8c;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }
    }
}
