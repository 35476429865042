@import "assets/scss/master";

.ticket__detail {
    z-index: 200 !important;
    border-left: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}

.ticket__detailed {
    position: sticky;
    top: 65px;
    // height: 600px;
    // height: 450px;
    height: calc(100vh - 50px);
    overflow: auto scroll;
    margin-right: 0.5rem;
    mask-image: -webkit-gradient(
        linear,
        left 90%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 90%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
    .ticket__detailed--container {
        margin-bottom: 8rem;
    }
}
