@import "assets/scss/master";

.tickets__header {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1rem;
    border-bottom: 1px solid #ecebf5;
    z-index: 1;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }
    .user__names {
        display: flex;
        align-items: baseline;
        gap: 12px;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
        .user__name {
            font-size: 14px;
            line-height: 22px;
            color: #696d8c;
            padding-right: 12px;
            border-right: 1px solid #ecebf5;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }

        h6 {
            font-family: $primary_font_family--medium;
            font-size: 20px;
            margin: 0;
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            font-size: 0.875rem;
            color: #696d8c;
            margin-top: 0.875rem;
        }
        span {
            font-size: 14px;
            font-family: $primary_font_family--lighter;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
    }
}

.header__rows {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}
