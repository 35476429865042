.agentaguides {
    &__heading {
        &--filter {
            display: flex;
            width: 100% !important;
            justify-content: space-between !important;
            button {
                width: 146px !important;
            }
        }
    }
}
