@import "assets/scss/master";

.branch__content {
    border-bottom: 1px solid rgba(30, 31, 32, 0.1);
    padding-bottom: 1.25rem;
    margin-bottom: 1rem;
    h6 {
        font-family: $primary_font_family--medium;
        font-size: 1.25rem;
        color: $black__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    p {
        font-size: 16px;
        color: $muted__theme_color_2;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
