@import "assets/scss/master";

.message__tag__header--actions {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.message__tag__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}
