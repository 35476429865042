@import "assets/scss/master";
.branch__list--item {
    height: 36px;
    font-size: 0.875rem;
    // font-weight: bold;
    letter-spacing: 0.15px;
    border: 1px solid #ecebf5;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    border-radius: 0.625rem;
    text-transform: capitalize;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background: transparent;
        color: $dark__mode__theme__white__color;
    }
    cursor: pointer;

    &.semi-active {
        border-color: #6837ef;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__primary__theme_muted_color !important;
            background: transparent;
            color: $dark__mode__theme__white__color;
        }
    }
    &.active {
        background: #6837ef;
        color: #fff;
        border-color: #6837ef;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
            background: $dark__mode__primary__theme_color;
        }
    }

    // &.invalid {
    //     &:after {
    //         content: "";
    //         position: absolute;
    //         width: 0;
    //         height: 0;
    //         margin-top: -44px;
    //         border-style: solid;
    //         border-width: 0 10px 10px 10px;
    //         border-color: transparent transparent $danger__theme_color
    //             transparent;
    //         z-index: 9999;
    //     }
    // }

    span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-left: 10px;
        margin-top: 1px;
        background-color: $danger__theme_color !important;
        border: 1px solid $danger__theme_color !important;

        // #{$dark__mode__theme__selector} {
        //     border-color: $dark__mode__theme__border__color !important;
        //     background: $dark__mode__theme__white__color;
        // }
        &.semi-active {
            // background-color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                background: transparent;
            }
        }
        &.active {
            border-color: $light__theme_color;
            // #{$dark__mode__theme__selector} {
            //     background: $dark__mode__theme__white__color;
            // }
        }
    }
}
