@import "assets/scss/master";

.analytics-tsam-convo-table-dropdown {
    border: 1px solid $border__theme_color;
    border-radius: 10px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .table__container {
        min-height: fit-content !important;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            border-radius: 10px !important;
        }
        thead {
            th {
                padding: 16px 24px !important;
                float: unset !important;
                text-align: left !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
        }
        tbody {
            td {
                padding: 16px 24px !important;
                font-size: 14px !important;
                text-align: left !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color !important;
                }
            }
            td:last-child {
                text-align: center !important;
            }
        }
    }

    tbody {
        td:last-child {
            text-align: center !important;
        }
    }

    .analytics-tsam-RESOLVED {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: #25bb87;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__green__muted__color !important;
        }
    }
    .analytics-tsam-PENDING {
        font-weight: 600;
        font-size: 14px;
        color: #696d8c;   
    }
    .analytics-tsam-UN_RESOLVED {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: #f25a68;
    }
    .analytics-tsam-convo-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding: 8px 24px;
        a {
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
        }
    }

}
