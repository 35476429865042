@import "assets/scss/master";

.ticketaa-response-list {
    margin-top: 16px;
    &__select {
        border-radius: 10px;
        border: 1px solid #25bb87;
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 16px;
        &.checked {
            margin: 10px 0;
            font-size: 14px;
            .form-check input {
                background-color: #1b9646 !important;
            }
        }
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    .ticket-aa-card-heading {
        cursor: pointer;
    }
    .d-flex {
        gap: 4px;
        align-items: center !important;
    }
    &__item {
        display: flex;
        padding: 10px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        background: white;
        border: 1px solid #ecebf5;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
            border-color: $dark__mode__theme__border__color;
        }
        div {
            display: flex;
            gap: 16px;
            align-items: center;
        }
        span {
            color: #696d8c;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
            &.edit {
                font-weight: 600;
                cursor: pointer;
            }
            &.sent {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
        &.active {
            background: #e1f9f1;
        }
    }
    &__dropdown {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        margin-top: 8px;
        border: 1px solid #e1e1eb;
        background: #fff;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__color;
            border-color: $dark__mode__theme__border__color;
        }
    }
}
