@import "assets/scss/master";

.canned-response-listing {
    margin-top: 1rem;
    padding: 14px 1rem;
    border-radius: 10px;
    overflow-y: auto;
    height: 196px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid #e1e1eb;
    gap: 1rem 10px;
    flex-direction: column;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}
