@import "assets/scss/master";
.uploading__email__attachment {
    padding: 12px 10px 14px 17px;
    gap: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ecebf5;
    border-radius: 8px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &--details {
        width: 100%;

        .email__attachment__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;

            > div {
                display: flex;
                align-items: baseline;
                gap: 11px;
            }

            .document__icon {
                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            &--name {
                font-size: 12px;
                line-height: 14px;
                color: #696d8c;
                display: block;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color
                }
            }

            &--size {
                font-size: 10px;
                line-height: 12px;
                color: #a3a3c2;
                display: block;
                text-transform: uppercase;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color
                }
            }

            .remove__uploading__email__attachment {
                cursor: pointer;
            }
        }

        .email__attachment__upload__progress__container {
            display: flex;
            align-items: baseline;
            gap: 12px;

            .email__attachment__upload__progress {
                border: none;
                width: 100%;
                height: 0.25rem;
                border-radius: 6px;
                background: rgba(163, 163, 194, 0.16);
                color: #dedee9;

                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                    background-color: $dark__mode__theme__background__color;
                }

                &::-webkit-progress-value {
                    background: #25bb87;
                    border-radius: 6px;
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__theme__green__color
                    }
                }

                &::-moz-progress-bar {
                    background: #25bb87;
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__theme__green__color
                    }
                }

                &::-webkit-progress-bar {
                    background: #25bb87;
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__theme__green__color
                    }
                }
            }
        }
    }
}
