.create__comment--edit__actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .create__comment--cancel__svg {
        width: 25px;
        height: 25px;
        background: rgba(163, 163, 194, 0.8);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 4.4px 4.4px 7.6px;
    }
}


