@import "assets/scss/master";

.ticketaa-listing {
    padding: 16px 24px;

    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        // padding: 16px 24px;
        margin-bottom: 16px;
        padding-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    button {
        margin-left: auto !important;
        width: fit-content !important;
    }
    section {
        // padding: 16px 24px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 50vh;
        overflow-y: scroll;
        .ticketaa-listing__item {
            border-radius: 10px;
            background: #f8f8fa;
            display: flex;
            padding: 12px 16px;
            // height: 22px;
            padding-right: 12px;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
            }
            .lds-spinner-small {
                width: fit-content !important;
                margin-left: unset !important;
                margin-right: 22px !important;
            }
            .lds-spinner-small div:after {
                background: $primary__theme_color !important;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__theme__white__color !important;
                }
            }
            svg {
                #{$dark__mode__theme__selector} {
                    fill: $dark__mode__theme__white__color;
                }
                path {
                    #{$dark__mode__theme__selector} {
                        fill: $dark__mode__theme__white__color;
                    }
                }
            }
            span {
                color: $black__theme_color;
                font-family: Gelion;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
        }
    }
}

.ag__downtime {
    width: 31px;
    height: 23px;
    padding: 4px 8px;
    border-radius: 8px;
    opacity: 0px;

    background: #F25A6829;
    color: #B40E1D;
    font-size: 12px;
    font-family: $primary_font_family--medium;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 1.2rem;
}