@import "assets/scss/master";
@import "assets/scss/_include-media-lib/include-media";

.custom-rules {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
   
    @include media("<large-screen") {
        flex-direction: column ;
     }

    margin-bottom: 2.5rem;
    h1 {
        font-size: 1.75rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
       
    }
    p {
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    &--header {
        border-bottom: 0.063rem solid $border__theme_color;
        margin-bottom: 1.5rem;

        &--title {
            color: $text__default_color;
            margin-bottom: 0.5rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }

        &--sub__title {
            width: 50%;
            margin-bottom: 1.5rem;
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
}
