@import "assets/scss/master";

.teams {
    margin-bottom: 2.5rem;

    .header__text {
        font-size: 1.75rem;
        color: $text__default_color;
        margin-bottom: 0.5rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    .sub__text{
        font-size: 0.875rem;
        color: $muted__theme_color_2;
        margin-bottom: 1.5rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
    }

    .empty-teams {
        border-radius: 0.625rem;
        border: 0.063rem solid $border__theme_color;
        height: 19.438rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
}