@import "assets/scss/master";

.member__select__container {
    height: 10rem;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    align-content: flex-start;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    padding: 1rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .member__select__empty {
        margin-top: -3rem !important;
    }
}

.member__select__invalid {
    border-color: $danger__theme_color;
}
.member__select__error {
    color: $danger__theme_color;
    font-size: 0.875em;
}
