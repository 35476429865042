.agent-follow-up {
    &__heading {
        display: flex;
        justify-content: space-between;
    }
    &__add-button {
        margin-left: auto;
        width: fit-content;
        font-size: 1rem;
        font-weight: 400 !important;
    }
}
