@import "assets/scss/master";

.chat__settings__dropdown {
    height: 46px !important;
}

.chat__settings__toggler {
    .custom-bootstrap-dropdown {
        .btn {
            #{$dark__mode__theme__selector} {
                background-color: transparent !important;
            }
        }

        .dropdown-toggle::after {
            #{$dark__mode__theme__selector} {
                display: none;
            }
        }
    }
}
