@import "assets/scss/master";

.discovery--actions {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
}

.discovery-variant {
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.625rem;
    padding: 1.25rem 1.313rem;
    height: fit-content !important;
    min-height: unset !important;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    thead {
        border-bottom: none !important;
        tr {
            height: 3.69rem !important;
        }
    }

    .variant--action {
        float: right;
    }
}