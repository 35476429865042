table.table th {
    padding-left: 0 !important;
}

table.table tbody tr td {
    padding-left: 0 !important;
}

.table__head th {
    padding-left: 0 !important;
}
