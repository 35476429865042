@import "assets/scss/master";

.capsule__content--grp {
    margin-bottom: 1.5rem;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__background__color !important;
    }
    .capsules__content--picker {
        .classes-container-box {
            height: 150px;
        }
    }

    &.has-error {
        .classes-container-box {
            border-color: #ff3364;
        }

        .classes__container {
            border-color: #ff3364;
        }
    }
}

.capsule__loading__container {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}
