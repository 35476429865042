@import "assets/scss/master";

.message__content__error {
    display: flex;
    gap: 4px;
    font-size: 12px;
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 4px;
    margin-top: -4px;
    cursor: pointer;
    align-items: center;
    span {
        color: $muted__theme_color_2;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    #{$dark__mode__theme__selector} {
        background-color: transparent;
        color: $light__theme_color;
    }
}

.message__content__sending {
    margin-top: -20px;
    svg {
        width: 2rem;
    }
}

.message__content {
    width: fit-content;
    padding: 0.7rem 1rem;
    max-width: 50%;
    font-size: 16px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;

    &.received {
        color: $light__theme_color;
        background: $primary__theme_color;
        border-radius: 1rem 1rem 1rem 0;
        cursor: pointer;
        &.other {
            background: $blue__theme__color;
        }
    }
    &.sent {
        color: $muted__theme_color_2;
        background: $light__grey__theme_color;
        border-radius: 1rem 1rem 0 1rem;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            color: $light__theme_color;
        }
    }
    &.sending {
        color: #d3d3d3;
        border-radius: 1rem 1rem 0 1rem;
    }
}
