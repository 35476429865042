@import "assets/scss/master";

.analytics-qa-ticket-capsule {
    border-top: 1px solid #ecebf5;
    border-bottom: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &.active {
        .analytics-qa-dropHeader {
            padding-bottom: 0;
        }
    }
    .ticket-info__details {
        margin-bottom: 0 !important;
        margin-top: 0rem !important;
    }
}
