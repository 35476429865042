.create__forms {
    padding: 0 0 2rem 2.5rem;

    &--container {
        display: flex;
    }

    &--drop__section {
        padding: 2.688rem 1.563rem 0 0;
    }
}