@import "assets/scss/master";

.builder__badge {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 0.313rem;
    background-color: $default__capsule__theme_color;
    color: $muted__theme_color_2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25rem;
    margin-left: 0.5rem;
    #{$dark__mode__theme__selector} {
       background-color: $dark__mode__theme__capsule__count__color;
       color: $dark__mode__theme__text__color
    }
    &--svg {
        display: inline-flex;
    }
}