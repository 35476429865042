
@import "assets/scss/master";

.file__upload__title {
    font-size: 1.125rem;
    line-height: 1rem;
    color: $text__default_color;
    margin-bottom: 0.75rem;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}

.file__group {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 1.688rem;

    .file__label__container {
        border-radius: 0.313rem;
        border: 0.063rem solid $light__border__theme__color;
        position: relative;
        width: 100%;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            border-color: $dark__mode__theme__border__color;
        }
        .file__name {
            width: 100%;
            height: 3.438rem;
            background: $light__theme_color;
            padding: 1.063rem 3rem 1.063rem 1.25rem;
            border-radius: 0.625rem;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
            }
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 1rem;
                margin: 0;
                width: 60%;
                color: $muted__theme_color_2;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color
                }
            }
        }

        .file__label__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
                color: $dark__mode__theme__white__color !important;
                border: 0.635em solid $dark__mode__theme__border__color !important
            }
        } 

        .file__label {
            top: 0.063rem;
            right: 0.063rem;
            position: absolute;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
            }
        }
    }
}


.uploaded__file {
	height: 20rem;
	border-radius: 0.625rem;
    width: 100%;
    margin-bottom: 1.688rem;

	&--video {
		height: unset;
	}
}

.extra__input__mssg {
    color: $muted__theme_color_2;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color
    }
}