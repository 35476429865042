.analytics-qa-progress-chart {
    margin-top: 20px;
    &__legends {
        display: flex;
        gap: 22px;
        justify-content: right;
        div {
            display: flex;
            gap: 4px;
            align-items: center;
        }
        span {
            color: #696d8c;
            text-align: right;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
        .tickets-color {
            width: 16px;
            height: 8px;
            background: #6837ef;
        }
        .accuracy-color {
            width: 16px;
            height: 8px;
            background: #a5a6f6;
        }
    }
}
