@import "assets/scss/master";

.analytics-qa-td-header {
    display: flex;
    align-items: flex-end;
    height: 62px;
    border-bottom: 1px solid #ecebf5;
    border-right: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 !important;
        padding: 16px 24px 16px 40px;
        padding-bottom: 0px;
        li {
            color: #696d8c;
            font-family: Gelion;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            list-style: none;
            padding-bottom: 16px;
            margin: 0 !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
            &.active {
                color: #6837ef !important;
                border-bottom: 2px solid #6837ef;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__primary__theme_muted_color !important;
                    border-color: $dark__mode__primary__theme_muted_color !important;
                }
            }
        }
    }
}
