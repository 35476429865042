@import "assets/scss/master";

.basic__template {
    padding-top: 1.25rem;

    h1 {
        font-size: 1rem;
        color: $text__default_color;
        font-weight: 600;
        font-size: 1rem;
        line-height: 0.813rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    .check__box {
        font-size: 0.875rem;
        line-height: 1.375rem;
        text-align: center;
        color: $muted__theme_color_2;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    &--inner__box {
        min-height: 3.438rem;
        width: 100%;
        background: $light__theme_color;
        margin-top: 1rem;
        border: 0.063rem solid $border_highlight_color;
        box-sizing: border-box;
        border-radius: 0.625rem;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
            border-color: $dark__mode__theme__border__color;
        }
        h1 {
            margin: 1.313rem 1.268rem; 
            text-transform: none !important;
            font-size: 1rem;
            line-height: 0.813rem;
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }

        p {
            color: $text__default_color;
            margin: 1rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
}