@import "assets/scss/master";
.custom-control-right {
    display: flex;
    flex-direction: row-reverse;
    gap: 60px;
    align-items: center;
    padding: 0;
    .form-check-label {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }
}
