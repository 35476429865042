@import "assets/scss/master";

.capsule__content__list {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.view__more__capsules {
    font-size: 0.875rem;
    line-height: 1.063rem;
    text-decoration: underline;
    color: $text__default_color;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}
