@import "../../../assets/scss/mixins/mixins.scss";
@import "../../../assets/scss/master";

.loading__section {
	color: #8487a0 !important;
    min-height: 50vh;

	h1 {
		font-size: 1.25rem;
        color: #8487a0 !important;
	}
	p {
		font-size: 0.875rem;
    	color: #8487a0 !important;
	}
}

.dots-container {
	color: #8487a0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 1rem 0;
	margin: 0 -5%;
	overflow: hidden;
	min-width: 200px;
	max-width: 300px;
	.loading-dots {
		position: relative;
		width: 0.625rem;
		height: 0.625rem;
		border-radius: 5px;
		background-color: $primary__theme_color;
		color: $primary__theme_color;
		animation: loadingDots 1s infinite linear alternate;
		animation-delay: 0.5s;
		#{$dark__mode__theme__selector} {
			color: $dark__mode__primary__theme_muted_color;
		}
		&::before,
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
		}
		&::before {
			left: -15px;
			width: 0.625rem;
			height: 0.625rem;
			border-radius: 5px;
			background-color: $primary__theme_color;
			color: $primary__theme_color;
			#{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
			animation: loadingDots 1s infinite alternate;
			animation-delay: 0s;
		}
		&::after {
			left: 15px;
			width: 0.625rem;
			height: 0.625rem;
			border-radius: 5px;
			background-color: $primary__theme_color;
			color: $primary__theme_color;
			#{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
			animation: loadingDots 1s infinite alternate;
			animation-delay: 1s;
		}
	}
}

@keyframes loadingDots {
	0% {
		background-color: $primary__theme_color;
	}
	50%,
	100% {
		background-color: $muted__theme_color;
	}
}
