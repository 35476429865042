@import "assets/scss/master";

.analytics-qava-convos {
    position: absolute;
    right: 0;
    left: 0;
    &__header {
        width: 100%;
        height: 70px;
        padding: 16px 24px;
        border-bottom: 0.1px solid #ecebf5;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
    display: flex;
    padding-left: 0px !important;
    padding-right: 0 !important;
    padding-top: 62px !important;
    &__mentions{
        height: calc(100vh - 315px);
        overflow-y: scroll;  
    }
    &__body {
        .abs__container {
            height: calc(100vh - 315px);
        }
        .chat__box {
            mask-image: -webkit-gradient(
                linear,
                left 90%,
                left bottom,
                from(rgba(0, 0, 0, 1)),
                to(rgba(0, 0, 0, 0))
            );
            -webkit-mask-image: -webkit-gradient(
                linear,
                left 90%,
                left bottom,
                from(rgba(0, 0, 0, 1)),
                to(rgba(0, 0, 0, 0))
            );
        }
        .scrollable-y {
            overflow-y: scroll;
            height: calc(100% - 60px);
        }
    }
    section {
        border-right: 1px solid #ecebf5;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
}
