@import "assets/scss/master";

.capsule__class--header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &--text {
        color: $text__default_color;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        font-weight: 600;
        font-size: 1.75rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    &--badge {
        border-radius: 100%;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        width: 1.75rem;
        height: 1.75rem;
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        background-color: $badge__theme__color;
        color: $black__theme_color;
    }
}