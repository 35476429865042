.analytics-tsam-convo-table {
    .table__container {
        min-height: fit-content !important;
        thead {
            th:first-child {
                text-align: left !important;
            }
            th {
                text-align: center !important;
                float: none;
            }
        }
        tbody {
            td {
                text-align: center !important;
            }
            td:last-child {
                text-align: center !important;
            }
        }
    }
}
