@import "assets/scss/master";

.view-gd-steps {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &__buttons {
        display: flex;
        gap: 12px;
        button {
            height: 40px !important;
            width: fit-content !important;
            font-size: 14px !important;
            // line-height: 22px;
            align-items: center !important;
        }
        .plain-button {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
}
