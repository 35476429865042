@import "assets/scss/master";

.follow-up-task-view {
    &__empty {
        border: 0.1px solid $border_highlight_color;
        border-radius: 10px;
        height: 298px !important;
        margin-top: 65px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }

    &__table {
        margin-top: 65px;
    }
}
