@import "assets/scss/master";

.branch__option {
    &--heading {
        margin-bottom: 1rem;

        h6 {
            font-family: $primary_font_family--medium;
            font-size: 1.25rem;
            color: $black__theme_color;
            margin-bottom: 0;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }

        .option__header__container {
            display: flex;
            align-items: baseline;
            gap: 15px;

            .option__header {
                font-family: $primary_font_family--medium;
                font-size: 1.25rem;
                color: $black__theme_color;
                margin-bottom: 0;
            }

            .update__order {
                display: flex;
                gap: 10px;

                &--send__button {
                    height: 18px !important;
                    width: 18px !important;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    padding: 4.4px 4.4px 7.6px !important;
                    border-radius: 3px !important;
                }

                &--cancel__svg {
                    width: 18px;
                    height: 18px;
                    background: rgba(163, 163, 194, 0.8);
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    padding: 4.4px 4.4px 7.6px;
                }

                &--send__svg {
                    & svg {
                        width: 8.28px;
                        height: 8.28px;
                    }
                }
            }
        }
        .next-step-btn {
            cursor: pointer;
            border-radius: 10px;
            border: 1px solid #e1e1eb;
            background: white;
            color: #696d8c;
            text-align: center;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                background-color: $dark__mode__theme__background__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
}
