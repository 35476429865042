@import "assets/scss/master";

.create__persona__heading {
    top: 0;
    position: sticky;
    padding: 0;
    background: $light__theme_color;
    padding-bottom: 0.313rem;
    z-index: 1000;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
    .pre__nav {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.063rem solid $border_highlight_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        font-size: 1rem;
        a {
            color: $muted__theme_color_2 !important;
            text-decoration: none !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
        svg{
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
            path{
                #{$dark__mode__theme__selector} {
                    stroke: $dark__mode__theme__white__color !important;
                }
            }
        }
        .anchor-right {
            margin: 0 1.25rem;
        }
        p {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color
            }
        }
        svg {
            #{$dark__mode__theme__selector} {
                stroke: $light__theme_color !important;
                #{$dark__mode__theme__selector} {
                    stroke: $dark__mode__theme__white__color !important
                }
            }
        }
    }
}
