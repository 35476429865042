@import "assets/scss/master";

.ticketaa-heading {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;

    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 0;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    svg {
        transform: rotate(180deg);
        cursor: pointer;

        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__white__color;
        }

        path {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
            }
        }
    }

    &__steps {
        display: flex;
        gap: 6px;
        align-items: center;

        span {
            width: 16px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            &:not(.active){
                background-color: #e6e6ed;
            }
            &.downtime {
                background-color: #ff24245b;

                svg {
                    margin-bottom: 5px;

                    path {
                        fill: #ff2424;
                    }
                }
            }

            &.uptime {
                background-color: #25bb865b;

                svg {
                    margin-bottom: 5px;
                    path {
                        fill: #1d9169;
                    }
                }
            }
        }

        .active {
            display: flex;
            height: 22px;
            padding: 5px 10px;
            width: fit-content;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: $primary__theme_color;
            color: #fff;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            text-wrap: nowrap;
        }
    }
}

.ticketaa-heading_downtime {
    padding: 16px 24px;
    margin-top: -16px;

    .uptime_heading {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        color: #1d9169;
        text-align: center;
        font-family: $primary_font_family--medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 10px;
        background: #effaf6;

        #{$dark__mode__theme__selector} {
            border: 1px solid #1d9169;
            background: rgba(#1d9169, 0.1);
        }
    }

    .downtime_heading {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        background: #ffebeb;
        font-family: $primary_font_family--medium;
        color: #ff2424;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        #{$dark__mode__theme__selector} {
            border: 1px solid #ff2424;
            background: rgba(#ff2424, 0.1);
        }
    }

    .regular_heading {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        background: #fff5dc;
        color: #7a5f0c;
        text-align: center;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        #{$dark__mode__theme__selector} {
            border: 1px solid #7a5f0c;
            background: rgba(#fff5dc, 0.1);
        }
    }
}