@import "assets/scss/master";

.team__members {
    display: flex;
    gap: 1rem;

    &--member {
        color: $primary__theme_color;
        background: $gray__bg__color;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            color: $dark__mode__primary__theme_muted_color
        }
    }

    &--others {
        color: $text__default_color;
        background: $light__theme_color;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        border: 0.063rem solid $border_highlight_color;
    }

    &--text {
        text-transform: capitalize;
        font-size: 0.875rem;
        line-height: 1.375rem;
    }
}
