@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/master";

.custom-bootstrap-dropdown {
    svg {
        #{$dark__mode__theme__selector} {
            stroke: $light__theme_color !important;
        }
        path {
            #{$dark__mode__theme__selector} {
                stroke: $light__theme_color !important;
            }
        }
    }
    button {
        background-color: white !important;
        border: none !important;
        color: black;
        float: right !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
            background-color: $dark__mode__theme__background__color !important;
        }
    }
    button:focus,
    button:hover {
        border: none !important;
        color: none !important;
    }
    button:focus:not(:focus-visible) {
        border: none !important;
        color: none !important;
    }
    .btn-check:focus + .btn,
    .btn:focus {
        border: none !important;
        box-shadow: none !important;
        color: none !important;
    }
    .dropdown-menu {
        line-height: 1.5rem;
        margin-top: 0.2rem;
        width: fit-content !important;
        transform: translate3d(0px, 40px, 0px) !important;
        background: #fcfcfc;
        border: 1px solid rgba(45, 45, 45, 0.1);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        margin-left: auto !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
            color: $light__theme_color !important;
        }
    }
    .dropdown-menu.show {
        top: 0px !important;
        height: fit-content !important;
        transform: translate3d(0px, 40px, 0px) !important;
    }
    .dropdown-menu::before {
        content: unset !important;
    }
    .dropdown-menu::after {
        content: unset !important;
    }
    a {
        color: none !important;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
        p {
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }
        &:hover {
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__capsule__count__color !important;
            }
        }
    }
    .dropdown-item {
        background-color: transparent !important;
    }
    .dropdown-toggle::after {
        display: none !important;
        #{$dark__mode__theme__selector} {
            display: none !important;
        }
    }
}
