.no-permission {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
        margin-top: 16px;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #060213;
    }
    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #696d8c;
    }
    &__button {
        width: 121px;
        border: 1px solid #e1e1eb;
        border-radius: 5px;
        background-color: transparent !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 32px;
        color: #060213 !important;
    }
}
