@import "assets/scss/master";
.mini__capsule--view {
    margin: 24px;

    &--related__classes {
        margin-bottom: 5rem;
    }

    #{$dark__mode__theme__selector} {
        color: $light__theme_color !important;
    }

    .product__classes__header {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    .capsule {
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
            color: $light__theme_color;
            background-color: $dark__mode__theme__color !important;
        }

        &.active {
            #{$dark__mode__theme__selector} {
                border-color: unset !important;
            }
        }
    }

    .classes__container {
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
        }

        .capsule__content--tag {
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color !important;
                background-color: $dark__mode__theme__color !important;
            }
            p {
                #{$dark__mode__theme__selector} {
                    color: $light__theme_color !important;
                }
            }
        }
    }

    .empty__content--block {
        margin: 2rem 0;
        h2 {
            font-size: 14px;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }
    }
}
