@import "assets/scss/master.scss";

.analytics-filter {
    // gap: 20px !important;
    align-items: center;
    margin: 15px 0px 21px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__actions {
        display: flex;
        // gap: 20px;
    }

    .col-3 {
        padding: 0 !important;
    }
    .col-9 {
        padding: 0 !important;
    }

    .col-2,
    .col-5 {
        padding: 0 !important;
    }

    .col-5 {
        padding: 0 !important;
        margin: 0 !important;
        background-color: yellow;
    }

    &.row {
        margin-left: 0;
        margin-right: 0;
    }

    .row {
        margin: 0 !important;
    }

    &__button {
        // width: 138px !important;
        width: 100% !important;
        height: 48px !important;
        margin-left: 20px;

        // padding: 18px 39px !important;
    }

    .form-control {
        min-height: unset !important;
    }

    &__duration-select {
        display: flex !important;
        align-items: center;
        margin-right: 20px !important;
        padding: 0 !important;
        padding-right: 20px !important;
        justify-content: space-between !important;
        margin: 0 !important;
        .col-6 {
            padding: 0 !important;
            margin: 0 !important;
        }
        &.row {
            margin: 0 !important;
            padding: 0 !important;
        }
        &.col-2 {
            padding: 0 !important;
            margin: 0 !important;
        }
        // &.col-4{
        //     margin: 0 !important;
        //     padding: 0 !important;
        // }
    }

    &__group {
        display: flex;
        border-radius: 16px;
        border: 1px solid $border__theme_color;
        padding: 8px 0px;
        align-items: center;
        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__theme__border__color;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__select-duration {
        display: flex;
        height: 50px !important;
        // width: 200px;
        width: 100%;
        margin-right: 20px !important;
        // max-width: 200px;
        border-radius: 16px;
        border: 1px solid $border__theme_color;
        padding: 5px 5px;
        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__theme__border__color;
        }
    }

    &__select {
        &-sortby {
            // margin-left: 12px !important;
            height: 35px !important;
            margin-right: 12px !important;
            width: 220px !important;
            .css-1iqk98z-control {
                height: 40px !important;
            }
            .react-select__value-container {
                padding-bottom: 0 !important;
                padding-top: 0 !important;
            }
            .react-select__multi-value {
                width: fit-content !important;
                flex: 0 0 auto;
            }
            .react-select__indicators {
                margin-left: auto !important;
                display: block !important;
                height: fit-content !important;
                margin-top: 3px !important;
                margin-right: 12px !important;
                svg {
                    padding: 0 !important;
                    margin-left: -50px;
                    cursor: pointer;
                }
            }
        }

        &-values {
            height: 35px !important;
            display: flex !important;
            width: 100% !important;
            .react-select__value-container {
                width: 100% !important;
                overflow: scroll !important;
            }
            &.has-custom-date {
                max-width: 35vw !important;
                @media screen and (max-width: 1280px) {
                    max-width: 20vw !important;
                }
            }
            // display: flex !important;
            // align-items: center !important;
            // margin-left: 24px !important;
            @media screen and (max-width: 1280px) {
                max-width: 40vw !important;
            }
            padding: 0 !important;
            margin-left: 12px !important;
            // overflow-y: scroll;

            .form-group {
                width: 100% !important;
            }

            .css-1iqk98z-control {
                height: 40px !important;
                padding: 0 !important;
            }
            .react-select__control {
                padding: 0 !important;
            }
            .react-select__value-container {
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                // margin-bottom: -20px !important;
            }
            .react-select__multi-value {
                width: fit-content !important;
                flex: 0 0 auto;
            }
            .react-select__value-container {
                padding: 0 !important;
                margin-right: auto;
            }
            .react-select__indicators {
                margin-left: auto !important;
                display: block !important;
                height: fit-content !important;
                margin-top: 3px !important;
                margin-right: 12px !important;
                svg {
                    padding: 0 !important;
                    margin-top: -10px !important;
                    margin-bottom: -10px !important;
                    margin-left: -10px;
                    margin-right: -10px;
                    cursor: pointer;
                }
            }
            .react-select__indicator-separator {
                // background-color: yellow !important;
                display: none !important;
            }
        }
    }
}

.analytics-filter__date-range {
    min-width: 96px;
    width: 190px !important;
    height: 50px !important;
    padding: 8px !important;
}

.react-datepicker-wrapper {
    height: 40px !important;
    margin-top: -10px;
}

.analytics-filter__switch {
    .form-switch {
        label {
            color: $muted__theme_color_2;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 22px !important;
        }
    }
}
