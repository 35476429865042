@import "assets/scss/master";

.selectui-checkbox-option {
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: #fcfcfd !important;
    cursor: pointer;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
    }
    &__container {
        display: flex !important;
        background-color: #fcfcfd !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
        }
    }
    input {
        width: 16px;
        height: 16px;
        background: #ffffff !important;
        border: 1px solid #e1e1eb;
        border-radius: 4px;
        cursor: pointer;
        line-height: 24px;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
    }
    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 8px;
        color: #696d8c;
        margin-top: -0.3rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    &:hover {
        background-color: #fcfcfd !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
        }
    }
}
