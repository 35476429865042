.toasts-container {
    .toast {
        border-radius: 5px;
        box-shadow: none;
        height: 4.625rem;
    }
    .toast-success {
        background: #25bb87 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 28.188rem !important;
    }
    .toast-error {
        background: #f25a68 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 28.188rem !important;
    }
    .toast-content {
        display: flex;
        color: #ffffff;
        align-items: center;
        width: 25rem !important;
        &-message {
            border-left: 1px solid #ffffff;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.125rem;
            margin-left: 1.375rem;
            margin-right: 1.375rem;
            text-align: center;
            width: 100%;
        }
    }
}
