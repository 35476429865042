@import "assets/scss/master";

.error-view {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    p {
        color: $danger__theme_color;
    }
    button {
        border: none;
        margin-top: -0.9rem;
        margin-left: -0.6rem;
        height: fit-content !important;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }
}
