.livechat__status {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
    padding: 13px 20px;
    border-radius: 100px;
    gap: 8px;
    margin: 0 10px;
    height: 42px;
    .circular__indicator {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #DBDBF4;
        display: block;
        &.SUCCESS {
            background: #45B26B;;
        }
        &.LOADING {
            background: #FFBEC4;
        }
        &.ERROR {
            background: #DBDBF4;
        }
    }
    span {
        color: #696D8C;
        font-size: 16px;
    }
}