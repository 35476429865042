@import "assets/scss/master";

.analyticsqa-tm-header {
    display: flex;
    height: 62px;
    padding: 16px 24px 16px 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ecebf5;
    border-right: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    svg {
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__white__color !important;
        }
        path {
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
        }
    }
    &__left {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 12px;
        span {
            color: #696d8c;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
        b {
            color: var(--Text-black, #060213);
            font-family: Gelion;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }
}
