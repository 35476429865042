@import "assets/scss/master";

.analytics-qa-card {
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid $border__theme_color;
    background: #fcfcfd;
    width: 100%;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background-color: $dark__mode__theme__color;
    }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h5 {
        color: $muted__theme_color_2;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    span {
        color: $primary__theme_color;
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        gap: 1px;
        border-radius: 32px;
        background: $default__capsule__theme_color;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__capsule__count__color;
            color: $dark__mode__primary__theme_muted_color;
        }
    }
    b {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
}
