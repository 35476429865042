@import "../../../../../../../../../../assets/scss/master";

.classification__priority {
    list-style: none;
    display: flex;
    align-items: center;
    width: fit-content;
    overflow: hidden;
    background: $default__capsule__theme_color;
    padding: 0.25rem;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    #{$dark__mode__theme__selector} {
      border-color: $dark__mode__theme__border__color;
      background: $dark__mode__theme__color;
    }
    &--item {
        padding: 0.5rem 1.25rem;
        color: $muted__theme_color_2;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color
        }

        &.selected {
            background: $light__theme_color;
            color: $black__theme_color;
            border: 0.063rem solid $primary__theme_color;
            border-radius: 0.5rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
                background: $dark__mode__theme__background__color;
                border-color: $dark__mode__primary__theme_muted_color;
            }
        } 
    }
}