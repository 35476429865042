@import "assets/scss/master";

.ticket__postform--group {
    padding: 1rem;
    h6 {
        font-size: 16px;
        color: #060213;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    &__empty {
        display: flex;
        margin-top: 1rem;
        padding: 14px 1rem;
        gap: 1rem 10px;
        border: 1px solid #e1e1eb;
        border-radius: 10px;
        height: 400px;
        overflow-y: auto;
        height: 196px;
        width: 100%;
        align-items: center !important;
        justify-content: center !important;
        text-align: center;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
    input {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
            background-color: $dark__mode__theme__color !important;
            border-color: none !important;
            border: none !important;
        }
    }

    .search-input {
        border-color: $dark__mode__theme__border__color !important;
        .input-group-text {
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
                background-color: $dark__mode__theme__background__color !important;
                border-color: $dark__mode__theme__border__color !important;
                svg {
                    path {
                        stroke: $light__theme_color !important;
                    }
                }
            }

            &:focus {
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
}
