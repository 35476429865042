#comment__dropdown {
    .comment__dropdown__icon {
        svg {
            cursor: pointer;
        }
    }

    .comment__dropdown__menu {
        left: -145px !important;
        top: -105px !important;
     
        &::after {
            content: "";
            display: none;
        }

         &::before {
            content: "";
            display: none;
        }
    }
}