@import "assets/scss/master";

.team__members {
    div[class^="status_"] {
        border-radius: 4px;
        padding: 5px 0.625rem;
        display: inline;

        &[class^="status_active"] {
            background: $success__background__theme_color;
            color: $success__theme_color;
        }
        &[class^="status_verification__pending"] {
            background: $pending__background__theme_color;
            color: $pending__theme_color;
        }
        &[class^="status_deactivated"] {
            background: $danger__background__theme_color;
            color: $danger__theme_color;
        }
    }
}

.table__dropdown {
    &.custom-dropdown {
        .selected--sm {
            width: auto !important;
            height: auto !important;
        }
        .options--table__dropdown {
            width: auto !important;
            right: -10px !important;
            top: 1.25rem !important;
        }
    }
}