@import "assets/scss/master";
.controller {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
        svg {
            stroke: $light__theme_color;
        }
    }
    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;
    }

    svg {
        margin-bottom: 5px;
    }
    &.right {
        svg {
            transform: rotate(180deg);
        }
    }
}
