@import "assets/scss/master";

.email__subject {
    display: flex;
    padding: 20px 24px 20px 24px;
    gap: 5px;
    border-bottom: 0.063rem solid #e1e1eb;
    // align-items: baseline;
    align-items: center;
    height: 60px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    & h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 2rem;
        color: #060213;
        margin: 0;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    .email__subject__input__group {
        width: 100%;

        .email__subject__input {
            height: fit-content !important;
            border: 0 !important;
            border-radius: 0 !important;
            padding: 0 !important;
            resize: none;
            font-weight: 600;
            font-size: 20px;
            line-height: 2rem;
            color: #060213;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
                background-color: transparent;
            }
        }
    }
}
