@import "assets/scss/master";

.add__task__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    width: 100%;
    border: 1px solid #ecebf5;
    border-radius: 10px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &:hover {
        border: 1px solid #ecebf5;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }
}

.save__task__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    height: 36px;
    border-radius: 8px;
}
