@import "assets/scss/master";

.ticketaa-mentions {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &__sent {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        margin-top: 12px;
        background: rgba(248, 248, 250, 0.7);
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }
        &__title {
            display: flex;
            align-items: center;
            gap: 6px;
            span {
                color: #25bb87;
            }
            h5 {
                color: #696d8c;
                font-family: Gelion;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                margin-top: 0;
                margin-bottom: 0;
                p {
                    color: #a3a3c2;
                    font-family: Gelion;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color;
                    }
                }
            }
        }
        p {
            color: #a3a3c2;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
}
