@import "assets/scss/master";
.convo__breaker__checkbox {
    pointer-events: none;
    margin-top: 1.125rem;
}

.convo__breaker__checkbox__input {
    cursor: pointer;
}

.convo__breaker__checkbox__rule {
    font-size: 1rem;
    line-height: 24px;
    color: #696d8c;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}
