@import "assets/scss/master";

.builder__element__list {
    background: $builder__gray__theme__color;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color;
        border-left: 1px solid $dark__mode__theme__border__color;
    }
    .builder__col {
        padding: 2.5rem;

        &--title {
            font-size: 1.5rem;
            line-height: 2rem;
            color: $text__default_color;
            font-weight: 600;
            margin-bottom: 1.5rem;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }

        &--search {
            margin-bottom: 1rem;
            .search__input {
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color !important;
                }
            }
        }
    }
}
