@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/_master.scss";

.items-list {
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 0.75rem;
    }
    &__button {
        margin-top: 1rem;
        font-family: $primary_font_family--medium;
        font-weight: 500;
        font-size: 1rem;
        color: $primary__theme_color;
        margin-bottom: 1rem;
        float: right;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }
}
