@import "assets/scss/master";

.updates-header {
    &__top {
        display: flex;
        align-items: top;
        justify-content: space-between;
    }
    .heading {
        h1 {
            margin-top: 0;
        }
        p {
            margin-bottom: 0;
        }
    }
    button {
        display: flex !important;
        align-items: center !important;
        width: fit-content !important;
        gap: 8px !important;
        svg {
            margin-top: -4px !important;
            path {
                stroke: white !important;
                fill: white !important;
            }
        }
    }
    &__search {
        width: 441px;
        margin-top: 32px;
    }
}

.update__email-box {
    color: #fc5a5a;
    text-align: center;
    font-family: Gelion;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 10px;
    border: 1px solid #fc5a5a;
    background: rgba(252, 90, 90, 0.1);
    padding: 6px 8px;
}

.update__response-box {
    padding: 6px 8px;
    border-radius: 10px;
    border: 1px solid #0066f5;
    background: rgba(0, 102, 245, 0.1);
    color: #0066f5;
    text-align: center;
    font-family: Gelion;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.update__mention-box {
    padding: 6px 8px;
    color: var(--main-sucess, #25bb87);
    text-align: center;
    font-family: Gelion;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 10px;
    border: 1px solid var(--main-sucess, #25bb87);
    background: rgba(37, 187, 135, 0.1);
}
