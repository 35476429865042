@import "assets/scss/master";

.header__info {
    border: 1px solid #ecebf5;
    border-radius: 8px;
    padding: 0 0.625rem;
    font-size: 15px;
    height: 32px;
    color: #696d8c;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    #{$dark__mode__theme__selector} {
        border: none !important;
        color: $light__theme_color;
        background-color: $dark__mode__theme__color;
    }

    .icon__caret {
        margin-bottom: 4px;
        cursor: pointer;
        svg {
            path {
                #{$dark__mode__theme__selector} {
                    fill: #B5B5BE;
                }
            }
        }
    }
}
