@import "assets/scss/master";

.ticket__mentions {
    padding: 25px 24px;
    margin-bottom: 5rem;

    .comment__input__group {
        padding-bottom: 17px;
        border-bottom: 0.063rem solid rgba(30, 31, 32, 0.05);
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
        }
    }

    .empty__ticket__activity {
        display: flex;
        align-items: baseline;
        gap: 7.5px;
        flex-direction: row;

        .empty--svg {
            margin-top: 0;
        }

        .empty--text {
            font-size: 18px;
            line-height: 24px;
            color: #a3a3c2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }
}
