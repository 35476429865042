@import "assets/scss/master";

.branch__group {
    &--item {
        border: 1px solid #e1e1eb;
        color: #060213;
        border-radius: 0.625rem;
        height: 42px;
        width: fit-content;
        padding: 8px 1.25rem;
        display: flex;
        align-items: center;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
            border-color: $dark__mode__theme__border__color;
        }
        span {
            white-space: nowrap;
            line-height: 0.625rem;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 0.15px;
            &.branchName {
                text-transform: capitalize;
            }
            // letter-spacing: 0.13px;
        }
        &:not(:last-child) {
            margin-right: 2rem;
        }
        &.active {
            background-color: #377dff;
            border-color: #377dff;
            color: #fff !important;
            .interval {
                background: #065fb2;
            }
        }
        .interval {
            height: 26px;
            border-radius: 6px;
            width: fit-content;
            background: #f5f5f7;
            margin-left: 1rem;
            padding: 2px 8px;
            display: flex;
            align-items: center;
            text-transform: lowercase !important;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__background__color;
            }
        }
    }
}
