@import "assets/scss/master";

.canned {
    font-size: 14px;
    line-height: 22px;
    color: #696d8c;
    padding: 0.5rem 1rem;
    background: rgba(104, 55, 239, 0.05);
    border-radius: 10px;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color
    }
}

.postform {
    background: #060213;
    padding: 8px 16px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    width: fit-content;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color;
        background: $dark__mode__theme__color;
    }
}
