@import "assets/scss/master";
@import "assets/scss/_index.scss";

.dropdown-workspaces {
    max-height: 10rem !important;

    height: fit-content !important;

    overflow-y: scroll;

    overflow-x: hidden !important;

    .active {
        color: $primary__theme_color !important;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
        }
    }
}
