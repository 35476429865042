@import "assets/scss/_master.scss";

.notification-section-header {
    height: 2.5rem;
    background: $light__grey__theme_color;
    width: 100%;
    padding: 0.875rem 0;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__color;
    }
    p {
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.75rem;
        color: #a3a3c2;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
