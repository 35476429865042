@import "assets/scss/master";

.tickets__form--list {
    margin: 1rem;
    height: 300px;
    border: 1px solid #e1e1eb;
    border-radius: 10px;
    overflow: scroll;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .filled__record {
        padding: 1rem;
        &:not(:last-child) {
            border-bottom: 1px solid #ecebf5;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
        }
        h6 {
            font-size: 16px;
            font-family: $primary_font_family--medium;
            margin-bottom: 1.25rem;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
        .filled__value {
            width: fit-content;
            padding: 6px 16px;
            background: #f5f5f7;
            border-radius: 10px;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
            }
            &__text {
                color: #696d8c;
                font-size: 14px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }

            &__url {
                color: $blue__theme__color;
                font-size: 14px;
                cursor: pointer;
            }

            &__image {
                width: 100%;
                height: 100%;
            }
        }
        margin-bottom: 1rem;
    }
}
