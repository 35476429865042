@import "assets/scss/master";

.select__label {
    margin-bottom: 0.938rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1rem;

    &.dark__label {
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }

    &.primary__label {
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
        }
    }
}

.select__ui--error-msg {
    color: $danger__theme_color !important;

    & span {
        color: $capsule_highlight_color;
        cursor: pointer;
    }
}

.react-select__menu {
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        color: $dark__mode__theme__white__color !important;
    }
}

.react-select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
    input {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
}

.react-select__multi-value__label{
    color: $text__default_color !important;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}

.react-select__control {
    // width: 20rem !important;
   
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
        background-color: $dark__mode__theme__background__color !important;
        border-color: $dark__mode__theme__border__color !important;
    }
    .react-select__indicator {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }
}

.react-select__value-container{
    // width: 100% !important;
    // overflow: scroll !important;
}

.react-select__option {
    cursor: pointer !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
    }
}

.react-select__option.react-select__option--is-selected {
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__capsule__count__color !important;
    }
}

.react-select__option:hover {
    cursor: pointer !important;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__capsule__count__color !important;
    }
}

.react-select__value.react-select__input {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.react-select__single-value {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.react-select__placeholder {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}
