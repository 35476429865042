@import "assets/scss/master";

.pagination-search {
    width: 17.5rem !important;
    #{$dark__mode__theme__selector} {
      color: $light__theme_color !important;
  }
    input {
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }
}
