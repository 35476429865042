@import "assets/scss/master";

.external__emails__checkbox {
    border: 1px solid #e1e1eb;

    &:checked {
        border: 1px solid #e1e1eb !important;
        background: #ffffff
            url("https://res.cloudinary.com/metacare/image/upload/v1666966153/icons/Ellipse_541_w3fpac.svg")
            no-repeat center !important;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
            background: $dark__mode__primary__theme_color !important;
        }
    }

    &.selected {
        pointer-events: none;
    }
}
