@import "assets/scss/master";

.drag__section{
    min-height: 25rem;
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .no-form-content {
        height: 21.875rem;

        h1 {
            font-size: 1.25rem;
        }
    }
}
