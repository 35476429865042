@import "assets/scss/master";

.appearance {
    &__wrapper {
        max-width: 550px;
        p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1rem;
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
        .uploading-email-logo {
            .lds-spinner-small {
                display: block;
                margin: 1rem !important;
            }
            .lds-spinner-small div:after {
                background: $primary__theme_color !important;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__primary__theme_color !important;
                }
            }
        }
        .company-logo {
            div {
                display: flex;
                align-items: center;
                .logo {
                    margin-right: 1rem;
                    img {
                        width: 73px;
                        height: 73px;
                    }
                }
            }
        }
        .company-email-logo {
            margin-top: 30px;
            div {
                display: flex;
                align-items: center;
                .logo {
                    margin-right: 1rem;
                    img {
                        max-width: 322px;
                        height: 98px;
                    }
                }
            }
        }
        .company-chat-url {
            p {
                cursor: pointer;
            }
        }
    }

    .company-background {
        // border-bottom: 1px solid $border__theme_color;
        img {
            width: 62.4%;
            height: 98px;
            border-radius: 10px;
        }
    }
}
