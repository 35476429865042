.delete-branch-modal {
    &__content {
        margin-bottom: 55px;
        margin-top: 55px;
        &--icon {
            text-align: center;
        }
        h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #060213;
            font-family: "Gelion";
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #696d8c;
        }
    }
}
