@import "assets/scss/master";

.tagging__suggestions {
    border-bottom: 0.063rem solid $border__theme_color;
    padding: 1.25rem 0;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
    }
}