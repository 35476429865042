@import "assets/scss/master";

.classifications {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.625rem;
 
        &--item {
            font-size: 0.875rem;
            padding: 0.125rem 0.5rem;
            background-color: $item__list__bg__color;
            border-radius: 0.25rem;
            color: $deep_orange__theme_color;
            #{$dark__mode__theme__selector} {
              background-color: $dark__mode__theme__color;
            }
        }

        &--more {
            font-size: 0.875rem;
            padding: 0.125rem 0.5rem;
            background-color: $light__theme_color;
            border-radius: 0.25rem;
            border: 0.063rem solid $border__theme_color;
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
                border-color: $dark__mode__theme__border__color;
                background-color: $dark__mode__theme__color;
            }
        }
    
}