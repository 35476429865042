@import "assets/scss/master";

.forgotpassword {
    h1 {
        font-size: 2.125rem;
        font-weight: 600;
        line-height: 2.5rem;
        letter-spacing: -0.5px;
        text-transform: none !important;
        text-align: center;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        color: $muted__theme_color_2;
        text-align: center;
        margin-bottom: 2rem;
    }
}
