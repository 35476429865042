@import "assets/scss/master";
.reminder__duration {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 8px;

    .duration {
        color: #060213;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
        &__suffix {
            color: #696d8c;
            margin-left: 3px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
}
