@import "assets/scss/master";
.attached__file {
    width: 100%;
    background: #FFFFFF;
    border-top: 1px solid #ECEBF5;
    padding: 16px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    #{$dark__mode__theme__selector} {
        padding: 16px 8px;
        background: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }
    &__meta__data {
        display: flex;
        align-items: center;
        gap: 11px;

        .document {
            svg {
                width: 16px;
                height: 16px;
            }
        }

        .attached__file__name {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #060213;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color
            }
        }
    }

    .remove__attached__file {
        svg {
            cursor: pointer;
            width: 11.25px;
            height: 11.25px;
        }
    }
    
}  