@import "assets/scss/master";

.analytics-qava-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 34px;
    #{$dark__mode__theme__selector} {
        svg {
            fill: $dark__mode__theme__white__color !important;
            path {
                fill: $dark__mode__theme__white__color !important;
            }
        }
    }
    span {
        color: $muted__theme_color_2;
        font-family: Gelion;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
        &.active {
            border-bottom: 1px solid $primary__theme_color;
            padding-bottom: 26px;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
            }
        }
    }
}
