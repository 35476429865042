@import "assets/scss/master";

.roles__permissions {
    padding: 1.25rem 0px;

    .role-module-tag {
        border-radius: 4px;
        padding: 5px 0.625rem;
        display: inline;
        background: $pimary__background__theme_color;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            background: $dark__mode__primary__theme_color;
        }
    }

    &-modal {
        padding: 0rem 4.75rem;
        margin-bottom: 2rem;
    }
}
