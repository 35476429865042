@import "assets/scss/master";

.reminders-header {
    &__empty {
        display: flex;
        justify-content: space-between;
    }
    &__withdata {
        display: flex;
        justify-content: space-between;
    }
    &__underule {
        border-bottom: 0.063rem solid $border__theme_color !important;
    }
}
