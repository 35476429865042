@import "assets/scss/master";

.attached__branch__image__title {
    color: #060213;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }
}

.attached__branch__image {
    border: 1px solid $light__border__theme__color;
    border-radius: 0.625rem;
    width: 100%;
    height: auto;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
    }
}

.empty__attached__branch__image {
    width: 100%;
    height: 224px;
    background: #fafafc;
    border: 1px solid #ecebf5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__background__color;
    }

    &--text__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--text {
        font-size: 16px;
        line-height: 26px;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
