.typing-indicator {
    $ti-color-bg: #e6e7ed;
    animation: 1s bulge infinite ease-out;

    span {
        height: 5px;
        width: 5px;
        float: left;
        margin: 0 5px;
        background-color: #9e9ea1;
        display: block;
        border-radius: 50%;
        opacity: 0.4;
        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                animation: 1s blink infinite ($i * 0.3333s);
            }
        }
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}
