@import "assets/scss/master";

.content__block {
    width: 100%;
    height: auto;
    background: $light__theme_color;
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.625rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background-color: $dark__mode__theme__background__color;
    }
    .content__body {
        padding:1.5rem;

        &--text {
            font-size:1.125rem;
            line-height: 1.625rem;
            color: $secondary__theme_color;
            white-space: pre-wrap;
            margin: 0;
            padding: 0;
        }

        &--url {
            font-size:1.125rem;
            line-height: 1.625rem;
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
        }

        &--responsive__media {
            width: 100%;
            height: auto;
        }
    }
}