@import "assets/scss/master";

.email_attached_files {
    width: 100%;
    padding: 10px;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    // margin-bottom: 60px;
}
