@import "assets/scss/master";

.analytics-qa-mt-block {
    border-left: 2px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    padding-top: 0px;
    padding-bottom: 24px;
    &:last-child {
        padding-bottom: 0px;
    }
    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .d-flex {
            align-items: center;
            gap: 10px;
            span {
                background: #6837ef;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__primary__theme_muted_color;
                }
            }
            margin-left: -14px;
            // hr{
            //     width: 40px;
            //     border: 2px solid red;
            //     transform: rotate(90deg)
            // }
            h5 {
                color: #696d8c;
                font-family: Gelion;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-top: 0;
                margin-bottom: 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }

        span {
            color: #696d8c;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    &__brands {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 28px;
        .d-flex {
            align-items: center !important;
            gap: 10px;
            span {
                width: 20.18px;
                height: 16px;
                background-color: $black__theme_color;
                border-radius: 2px;
            }
        }
        svg {
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
                path {
                    fill: $dark__mode__theme__white__color;
                }
            }
        }
    }

    &__body {
        max-height: 200px;
        height: fit-content;
        overflow-y: scroll;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0px 28px;
        section {
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            border-radius: 8px;
            background: #f8f8fa;
            height: fit-content;
            #{$dark__mode__theme__selector} {
                background: $dark__theme_color;
            }
            p {
                overflow: hidden;
                color: #696d8c;
                text-overflow: ellipsis;
                font-family: Gelion;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
            h5 {
                color: #060213;
                font-family: Gelion;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
            .d-flex {
                align-items: center !important;
                gap: 6px;
                margin-bottom: 0 !important;
            }
            .brand__logo {
                width: 20.18px;
                height: 16px;
                background-color: $black__theme_color;
                border-radius: 2px;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__primary__theme_color;
                }
            }
            span {
                color: #696d8c;
                font-family: Gelion;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }
    }
}
