@import "assets/scss/master";

.create__comment__group {
    border: 0.063rem solid #e1e1eb;
    border-radius: 0.5rem;
    padding: 0.875rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .comment__textarea__container {
        display: flex;
        align-items: baseline;
        gap: 12px;

        & img {
            object-fit: cover;
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            border: 3px solid #c1c7d0;
            margin-top: -5px;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
        }

        & textarea {
            border: 0 !important;
            border-radius: 0 !important;
            padding: 0 !important;
            outline: none !important;
        }
    }

    .create__comment {
        display: flex;
        margin-top: 5px;
        position: relative;
        z-index: 4;
        margin-left: auto;
        width: fit-content;

        &--send__button {
            height: 25px !important;
            width: 25px !important;
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 4.4px 4.4px 7.6px !important;
            border-radius: 3px !important;
        }

        &--send__svg {
            & svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}
