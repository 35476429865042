@import "assets/scss/master";

.branch-canned-messages {
    h6 {
        margin-bottom: 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
    &__list {
        display: flex;
        gap: 20px;
    }
}
