@import "assets/scss/master";

.tag__classes__container {
    min-height: 10rem;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    align-content: flex-start;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;
    padding: 1rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .tag_classes {
        background:  #F5F5F7;
        border: 0.063rem solid $border_highlight_color;
        border-radius: 10px;
        color: $muted__theme_color_2;
        width: fit-content;
        padding: 6px 1rem;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background: $dark__mode__theme__color;
            border-color: $dark__mode__theme__border__color;
        }
    
        &.active__tag_classes {
            background: $light__theme_color;
            border: 0.063rem solid #F49E4C;
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
               background: $dark__mode__theme__color;
               color: $dark__mode__theme__white__color
            }
        }
    }

    .tag__select__empty {
        margin-top: -3rem !important;
    }
}

.tag__select__invalid {
    border-color: $danger__theme_color;
}
.tag__select__error {
    color: $danger__theme_color;
    font-size: 0.875em;
}
