@import "assets/scss/master";

.view-gd-updates {
    margin-top: 24px;
    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        margin-bottom: 24px;
        margin-top: 0px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    .edit_step_button {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            border-color: $dark__mode__theme__border__color !important;
        }
    }
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button {
            width: fit-content !important;
        }
    }

    &__details {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-top: 0px;
        align-items: flex-start;
        border-top: 1px solid var(--border-stroke, #ecebf5);
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
        }
        padding-top: 24px;
        .update__actions {
            display: flex;
            align-items: center !important;
        }
    }
    &__empty {
        padding: 60px 40px;
        border-radius: 10px;
        margin-top: 12px;
        border: 1px solid var(--border-stroke, #ecebf5);
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color !important;
            background-color: $dark__mode__theme__background__color;
        }
    }

    &__list {
        display: flex;
        list-style: none;
        padding: 0;
        align-items: center;
        // width: fit-content;
        gap: 12px;
        &__actions {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }
}

.new-automation-button {
    margin-left: auto;
    width: fit-content !important;
}
