@import "assets/scss/master";

.content__block__header__title {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $light__theme_color;
    border-bottom: 1px solid $border__theme_color;  
    padding: 1.25rem 1.5rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }
    &--text {
        font-size: 1rem;
        line-height:1.5rem;
        display: flex;
        align-items: center;
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color
        }
    }

    &--actions {
        display: flex;
        align-items: center;
        gap: 1.167rem;
        cursor: pointer;
    }
}