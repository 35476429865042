@import "assets/scss/master";

.product__dashboard__header {
    padding-top: 3.313rem;
    position: sticky;
    top: 0;
    z-index: 10;
    background: $light__theme_color;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
    &--header__items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}