#cc__block__dropdown {
    .cc__block__menu {
        left: -144px !important;
        top: 37px !important;

        &::after {
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #ffffff transparent;
            top: -10px;
        }

        &::before {
            border-color: transparent transparent #ffffff transparent;
            top: -10px;
        }
    }
}