@import "assets/scss/master";

.product-setup {
    min-height: 100%;
    margin: 3.75rem 0 1.5rem;

    &--container {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }

    &--text {
        text-align: center;
        color: $text__default_color;
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.438rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }

    &--sub__text {
        text-align: center;
        color: $muted__theme_color_2;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
