@import "assets/scss/master";

.classes-container-box {
    height: 219px;
    border: 1px solid rgba(30, 31, 32, 0.1);
    border-radius: 0.625rem;
    padding: 36px 0.625rem;
    padding-top: 26px;
    overflow-y: auto;
    overflow-x: scroll;
    margin-bottom: 2rem;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    --gap: 0.625rem;
    display: inline-flex;
    flex-wrap: wrap;

    > * {
        margin: var(--gap) 0 0 var(--gap);
    }
}

.classes-container {
    margin-top: 0px;
    h3 {
        font-size: 1.5rem;
    }
}

.collection__capsules {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 40rem;
    padding: 0.625rem 0;

    span {
        white-space: nowrap;
    }
}

.capsule,
.branch__collection--capsule {
    color: $muted__theme_color_2;
    background: #f5f5f7;
    transition: all 0.2s;
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.15px;
    font-size: 0.875rem;
    padding: 6px 16px;
    height: 36px;
    text-align: center;
    text-transform: capitalize;
    font-family: $primary_font_family;
    border: 1px solid !important;
    border-radius: 0.625rem;
    cursor: pointer;
    border-color: #f5f5f7 !important;
    max-height: 52px;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color !important;
    }
    &.active {
        border-color: currentColor !important;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
            span {
                color: $dark__mode__theme__white__color !important;
            }
        }
    }
    .capsule_count {
        margin-left: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: #e1e1eb;
        text-align: center;
        font-size: 12px;
        font-family: $primary_font_family--medium;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background-color: $dark__mode__theme__capsule__count__color;
        }
    }
    .cross-add {
        margin-right: 0.8rem;
        margin-bottom: -0.1rem;
    }
    .cross-del {
        margin-left: 1rem;
    }
    &.addition-mode {
        border: 1px solid $capsule_highlight_color !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
}

.capsule_tags {
    height: 100px;
    overflow-y: scroll;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #e1e1eb;
    border-radius: 0.625rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background: $dark__mode__theme__color;
    }
    .capsule {
        color: #060213;
        background: #f5f5f7;
        border-color: #f5f5f7;
        border-radius: 0.625rem;
        padding: 6px 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background: $dark__mode__theme__background__color;
            border: $dark__mode__theme__border__color;
        }
    }
}

// .capsule--content {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     width: 100%;
//     max-height: 100%;
//     min-height: 55px;
//     p {
//         margin-bottom: 0;
//         font-size: 15px;
//         letter-spacing: 0.15px;
//     }
// }

.secondary__capsules {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.capsule__content {
    // min-height: 100%;
    height: auto;
    width: fit-content;
    p {
        margin-bottom: 0;
        text-transform: capitalize;
        font-size: 14.5px;
        letter-spacing: 0.15px;
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
        &.main__content {
            margin-left: 0.5rem;
        }
    }
    &--tag,
    .selectable__capsulecontent {
        margin: 0px 10px 10px 0px;
        color: #696d8c;
        font-size: 0.875rem;
        white-space: nowrap;
        text-transform: capitalize;
        background: #f5f5f7;
        border: 1px solid #f5f5f7;
        border-radius: 0.625rem;
        padding: 7px 1.25rem;
        letter-spacing: 0.15px;
        font-weight: lighter;
        text-align: center;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background-color: $dark__mode__theme__color !important;
            border-color: $dark__mode__theme__border__color;
        }
        &.active{
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &--heading {
        top: 0;
        position: sticky;
        padding: 0;
        background: #ffffff;
        padding-bottom: 5px;
        z-index: 1000;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }
        .pre__nav {
            padding-top: 16px;
            padding-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid $border_highlight_color;
            font-size: 16px;
            a {
                color: $muted__theme_color_2 !important;
                text-decoration: none !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }
            .anchor-right {
                margin: 0 1.25rem;
            }
            svg{
                path{
                    #{$dark__mode__theme__selector} {
                        stroke: $dark__mode__theme__white__color !important;
                    }
                }
            }
            p {
                text-transform: capitalize;
                margin-bottom: 0;
                color: $primary__theme_color;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__primary__theme_muted_color;
                }
            }
        }
    }
}
