@import "assets/scss/_include-media-lib/include-media";

.upload {
    &--icons {
        display: flex;
        align-items: center;
        gap: 0.92rem;
    
        svg {
            cursor: pointer;
    
            @include media("<=small-screen") {
                height: 0.917rem;
                width: 0.917rem;
            }
        }
    }
}

.upload__disabled {
    pointer-events: none;
}
