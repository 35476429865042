@import "../../../../../../../../assets/scss/master";

.files__container {
    display: flex;
    align-items: center;
    gap: 0.938rem;
    flex-wrap: wrap;
    margin: 1rem 0;

    .file {
        display: flex;
        align-items: center;
        gap: 0.313rem;

        .file__name {
            color: $muted__theme_color_2;
            font-size: 0.875rem;
            line-height: 1.375rem;
            padding: 0.563rem 0.938rem;
            background: $light__grey__theme_color;
            border-radius: 0.375rem;
            display: flex;
            align-items: center;
            gap: 0.125rem;
        }
    }
}