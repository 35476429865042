@import "assets/scss/master";

.ticketaa-guide-capsule {
    padding: 16px 24px;
    border-top: 1px solid $border__theme_color;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    .mini__capsule--view {
        margin: 0px !important;
        margin-top: 16px !important;
    }
}
