.img--container {
    height: 12.5rem;
}

.select__box {
    height: 3.438rem;
}

.full__screenloader {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #000000cc;
    z-index: 100;
}

.drag__section {
    min-height: 25rem;
    border: 0.063rem solid #ecebf5;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
}

.empty__container {
    height: 12.5rem !important;
    border: 0.063rem solid rgba(30, 31, 32, 0.1);
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
}

// #react-select-3-listbox {
//     height: 4.375rem !important;
//     overflow: scroll !important;
// }
.text-right {
    text-align: right;
}

.soln__content {
    // align-contents:;
    .content-tag {
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}

.element__content {
    // margin: 0;
    color: $black__theme_color;
    font-size: 0.875rem;
    #{$dark__mode__theme__selector} {
        color: $light__theme_color
    }
}

.no-space {
    white-space: nowrap;
}

.logo-icon {
    height: 4.563rem;
    width: 4.563rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
    }
}

.empty__chat {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    &.with-margin {
        padding-top: 6rem;
    }
    p {
        font-size: 1rem;
        color: $muted__theme_color_2;
        margin-top: 1rem;
        margin-bottom: 0;
        white-space: pre-wrap;
    }
}

.tickets__filter_group {
    position: sticky;
    top: 0;
    z-index: 99; 
    background: #fff;
    padding: 1rem 0;
    .host__filters {
        .phase--class {
            display: flex;
            align-items: flex-end;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
                width: 0;
            }
        }
    }
    .directional {
        display: none;
        margin-bottom: 0;
        margin-right: 6rem;
    }
    &.bordered {
        .message__tag--hr {
            display: none;
        }
        .meta__container {
            height: 66px;
            display: flex;
            .phase--class {
                margin: 0;
            }
        }
        .host__filters {
            display: flex;
            align-items: baseline;
        }
        .mt__filter--container {
            border: 0;
            height: auto;
            padding: 0;
        }
        h6 {
            display: none;
        }
        .directional {
            display: block;
        }
        .tickets__filter--selector {
            // opacity: 0;
            // cursor: default;
            display: none;

            &.active {
                // opacity: 1;
                height: 42px;
                display: flex !important;
            }
        }
    }
    h6 {
        font-size: 1rem;
        color: $black__theme_color;
        &:not(.directional) {
            margin-top: 1rem;
        }
    }
}

.custom__image {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
}

.status__bar {
    position: sticky;
    top: 1.2rem;
    text-align: center;
    z-index: 999;
    white-space: nowrap;
    font-size: 12px;
    .error {
        padding: 8px 16px;
        padding-right: 20px;
        background-color: #fff5f5;
        border: 1px solid #ffccc7 !important;
        border-radius: 8px;
        border-radius: 8px;
        color: #ff000096;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
        }
    }
    .reconnected {
        padding-right: 20px;
        width: fit-content;
        padding: 0.25rem 0.625rem;
        border: 0.063rem solid $success__theme_color;
        font-size: 0.813rem;
        border-radius: 0.5rem;
        background: $light__theme_color;
        color: $success__theme_color;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
        }
    
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}

#ticketsList {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ticket__status {
    width: fit-content;
    white-space: nowrap;
    // height: 26px;
    display: flex;
    align-items: center;

    .status__circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #25bb87;
        margin-right: 10px;
        margin-bottom: -2px;
    }
}

#dummy {
    // margin-top: 5rem;
    // height: 100px;
    display: block;
    width: 100%;
}

.countable__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 22px;
    padding: 0 8px;
    border: 1px solid #0066f5;
    border-radius: 8px;
    margin: auto;
    margin-top: 5px;

    font-size: 10px;
    color: #060213;
}

.analytics-team-filter__select {
    .react-select__value-container {
        padding-bottom: 0 !important;
        padding-top: 8px !important;
    }
    .react-select__multi-value {
        width: fit-content !important;
        flex: 0 0 auto;
    }
}

.react-multi-email {
    &.focused {
        border-color: $primary__theme_color !important;
    }
}

.llm__answer {
    font-size: 14px;
    opacity: 0.6;
}

.ql-formats, 
.ql-formats span {
    background: transparent !important;
}