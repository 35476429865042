@import "assets/scss/master";

.deletable__input {
    align-items: center;
    
    .form-control {
        &:not(.full-height) {
            height: 1.5rem;
            border-radius: 6px;
            font-size: 0.875rem;
        }
    }
}

.formbuilder__input {
    background: $light__theme_color;
    margin-bottom: 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid $border__theme_color;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }
    &--body {
        padding: 1.5rem;
        .input__label {
            margin-bottom: 1rem;
        }
    }

    &--heading {
        padding: 0.5rem 0.5rem;
		border-bottom: 1px solid $border__theme_color;
		background: url(https://res.cloudinary.com/metacare/image/upload/v1653586729/Vector_kknzkn.svg) no-repeat left 30px center;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        .text--holder {
            padding-right: 0.625rem;
            border-right: 1px solid #E1E1EB;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
            h3{
                font-size: 16px;
                color: #060213;
                margin-bottom: 0;
                #{$dark__mode__theme__selector} {
                    color: $light__theme_color
                }
            }
        }
        .form-check {
            margin: 0;
            .form-check-label {
                color: #696D8C;
                font-family: $primary_font_family;
                font-size: 0.875rem;
                #{$dark__mode__theme__selector} {
                  color: $dark__mode__theme__text__color
                }
            }
        }
    }
}
 