@import "assets/scss/master";

.analytics-qa-agents-cards {
    display: flex;
    gap: 20px;
    section {
        display: flex;
        padding: 4px 16px 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        // min-width: 169px;
        width: 200px;
        height: 65.39px;
        border-radius: 12px;
        border: 1px solid $border__theme_color;
        background: $off__white__theme__color;
        #{$dark__mode__theme__selector} {
            background: transparent;
            border-color: $dark__mode__theme__border__color;
        }
        h5 {
            color: $text__muted__theme__color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
        p {
            color: $text__default_color;
            font-family: Gelion;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }
}
