@import "assets/scss/master";

.customer__tickets--container {
    padding: 0;
    padding-right: 1rem;
    position: sticky;
    top: 0;
    right: 0;
    height: 600px;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    mask-image: -webkit-gradient(
        linear,
        left 90%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 90%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
    .tickets__container {
        // margin: 3rem 0;
        margin-bottom: 12rem;
    }

    .empty__chat {
        p {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }

    // .custom-scroll-bar{
    //     &::-webkit-scrollbar {
    //         display: inline;
    //         width: 5px !important;
    //     }
    //     &::-webkit-scrollbar-track {
    //         background: #fafafc !important;
    //         border-radius: 3px !important;
    //         #{$dark__mode__theme__selector} {
    //            background-color: yellow !important;
    //         }
    //     }
    //     &::-webkit-scrollbar-thumb {
    //         background: #e6e6ed;
    //         border-radius: 3px;
    //         #{$dark__mode__theme__selector} {
    //             background-color: red !important;
    //          }
    //     }
    //     &::-webkit-scrollbar-thumb:hover {
    //         background: #4d4b4b;
    //         #{$dark__mode__theme__selector} {
    //             background-color: $muted__theme_color !important;
    //          }
    //     }
    // }
}

.active--filters {
    .customer__tickets--container {
        height: 480px;
        // .tickets__container {
        //     // margin: 6rem 0;
        //     // margin-bottom: 5rem;
        // }
    }
}
