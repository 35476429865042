@import "assets/scss/master";

.image__crop__scale__container {
    border-top: 0.063rem solid #ecebf5;
    border-bottom: 0.063rem solid #ecebf5;
    padding: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .image__crop__scales {
        display: flex;
        align-items: center;
        margin: 0;

        .image__crop__scale {
            list-style: none;
            padding: 7px 18.5px;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            &--standard {
                width: 2rem;
                height: 2rem;
                border: 1px solid #ecebf5;
                display: inline-block;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                }
            }

            &--wide {
                width: 60px;
                height: 32px;
                border: 1px solid #ecebf5;
                display: inline-block;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                }
            }

            &--text {
                font-size: 14px;
                line-height: 22px;
                color: #060213;
                text-transform: capitalize;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                }
            }

            &.active__image__crop__scale {
                border-radius: 8px;
                border: 1px solid #6837ef;
                box-shadow: 8px 4px 16px rgba(0, 0, 0, 0.04);
                pointer-events: none;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__primary__theme_muted_color;
                }
            }
        }
    }
}
