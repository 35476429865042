@import "assets/scss/master";

.chart-tooltip {
    margin-bottom: -1rem;
    margin-left: -0.5rem;
    &:before {
        content: " ";
        height: 0;
        position: absolute !important;
        width: 0;
        left: -24px;
        top: 20px;
        border: 8px solid transparent;
        border-right-color: white;
        #{$dark__mode__theme__selector} {
            border-right-color: $dark__mode__theme__color;
        }
    }

    &.swipe-layout {
        margin-bottom: 0rem;
        margin-top: -36px;
        &:before {
            top: -14px;
        }
    }

    border-radius: 12px;
    background: $light__theme_color;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color !important;
        border-color: $dark__mode__theme__border__color !important;
    }
    box-shadow: 8px 12px 32px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    min-width: 132px;
    padding: 8px 12px 12px 12px;
    align-items: center;
    gap: 12px;
    min-height: 56px;

    div {
        height: fit-content;
    }
    h4 {
        margin-bottom: 0;
        margin-top: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    p {
        color: $muted__theme_color_2;
        font-family: Gelion;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 0;
        margin-top: 0;
        display: flex;
        gap: 4px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
        span {
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        strong {
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }
    h5 {
        color: $muted__theme_color_2;
        font-family: Gelion;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 0;
        margin-top: 16px;
        display: flex;
        gap: 4px;
        margin-bottom: -4px;
        text-transform: capitalize;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    b {
        color: $text__default_color;
        font-family: Gelion;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    span {
        color: $muted__theme_color_2;
        font-family: Gelion;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
