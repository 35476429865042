@import "assets/scss/master";

.plotted__bar__chart__container {
    .y-axis {
        margin-bottom: 0.75rem;
    }

    .graph__label {
        color: $muted__theme_color_2;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.375rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color !important;
        }
    }

    .plotted__bar__chart {
        height: 464px;
    }

    .x-axis {
        display: flex;
        justify-content: flex-end;
    }

    tspan {
        fill: $muted__theme_color_2 !important;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__text__color !important;
        }
    }
}
// .recharts-cartesian-grid {
//     padding: 20px !important;
// }
// .recharts-wrapper {
//     .recharts-cartesian-grid {
//         padding: 20px !important;
//     }
// }

// .recharts-xAxis{
//     padding: 20px !important;
// }

.recharts-layer {
    margin-bottom: 2rem !important;
    padding: 40px !important;
}

.recharts-cartesian-axis-tick-line {
    display: none !important;
}

.recharts-bar-rectangle{
    width: 5rem !important;
}

.recharts-rectangle{
    width: 10rem !important;
 
}



// .recharts-legend-item-text {
//     font-family: Raleway;
//     color: red !important;
//     font-size: 10px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//   }
