@import "assets/scss/master";

.pending__task {
    width: 100%;
    border-bottom: 1px solid #ecebf5;
    padding: 12px 0 16px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &__new--indicator {
        color: $primary__theme_color;
        text-align: center;
        padding: 4px 8px 5px;
        width: fit-content;
        height: fit-content;
        background: rgba(104, 55, 239, 0.1);
        border-radius: 8px;
        margin-left: 26px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 6px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }

    .pending__task__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        cursor: pointer;

        &--checkbox__group {
            margin-left: 0 !important;
        }

        &--checkbox {
            font-size: 1rem !important;
            line-height: 20px;
            font-weight: 500;
            color: #060213;
            white-space: pre-wrap !important;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }

            &.checked {
                color: #696d8c;
            }
        }

        &--timestamp {
            font-size: 14px;
            line-height: 1rem;
            color: #696d8c;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }

        .toggle__task {
            transform: rotateZ(90deg);

            svg {
                cursor: pointer;
                width: 6px;
                height: 15px;
            }

            &.rotate {
                transform: rotateZ(270deg);
            }
        }
    }

    .task__metadata__container {
        padding-top: 1rem;

        .email__container {
            margin-bottom: 12px;
            border: 1px solid #ecebf5;
            border-radius: 10px;

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }

            .email__actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #ecebf5;
                padding: 11px 12px;

                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                }
                & p {
                    font-size: 14px;
                    line-height: 1rem;
                    color: #a3a3c2;
                    margin: 0;
                    font-weight: 600;
                    color: #060213;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color
                    }
                }
            }

            .canned__email__list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 16px;
                max-height: 14rem;
                overflow-y: scroll;
            }
        }

        .response__container {
            margin-top: 12px;
            border: 1px solid #ecebf5;
            border-radius: 10px;

            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }

            .response__header {
                font-weight: 700;
                color: #060213;
                font-size: 14px;
                line-height: 16px;
                border-bottom: 1px solid #ecebf5;
                padding: 11px 12px;
                margin: 0;

                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                    color: $light__theme_color;
                }

                & span {
                    font-weight: 400;
                    color: #696d8c;

                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color
                    }
                }
            }

            .canned__responses__list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 16px;
                max-height: 14rem;
                overflow-y: scroll;
            }

            .post__form__collections__list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                padding: 16px;
            }
        }
    }
}
