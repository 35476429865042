@import "assets/scss/master";
@import "assets/scss/main.scss";
@import "assets/scss/_index.scss";

.nav-search-input {
    width: 28.125rem !important;
    border-radius: 0.625rem;
    #{$dark__mode__theme__selector} {
        border: 1px solid $dark__mode__theme__border__color;
    }
    .input-group-btn.btn {
        border-radius: 0.625rem;
        background-color: #fafafc;
        border-left: none !important;
        border: none;
        height: 3.375rem;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }
        svg {
            path {
                #{$dark__mode__theme__selector} {
                    stroke: $light__theme_color !important;
                }
            }
        }
        &.focus {
            border-color: $primary__theme_color !important;
            outline: none;
            background-color: #fafafc;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
                border-color: $dark__mode__primary__theme_muted_color !important;
            }
        }
        &:focus {
            border: none !important;
            outline: none;
            background-color: #fafafc;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color;
            }
        }
    }
    input.form-control {
        border: none !important;
        border-right: none !important;
        background-color: #fafafc;
        border-radius: 0.625rem;
        height: 3.375rem;
        padding: 1rem;
        color: #060213;
        font-size: 0.875rem;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
        &:focus {
            border-color: $primary__theme_color !important;
            color: #000;
            outline: none;
            box-shadow: none;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
                border-color: $dark__mode__primary__theme_muted_color !important;
            }
        }
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a3a3c2;
            font-size: 1rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
    @include responsiveMobile($xl) {
        width: 25rem;
    }
}
