@import "assets/scss/master";

.each__builder {
    background: url(https://res.cloudinary.com/metacare/image/upload/v1653586729/Vector_kknzkn.svg) no-repeat left 0.938rem center;
    border: 0.063rem solid $border__theme_color;
    border-radius: 0.375rem;
    background-color: $light__theme_color;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0.875rem;
    padding-left: 1.688rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background-color: $dark__mode__theme__color;
    }
    .builder__text {
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 0.875rem;
            margin-bottom: 0;
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color
            }
        }

        &--sub__title {
            font-size: 0.75rem;
            margin-bottom: 0;
            color: $text__default_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color
            }
        }
    }
   
}