@import "assets/scss/master";

.ticketaa-info-card {
    // display: flex;
    padding: 12px 16px;
    // align-items: flex-end;
    gap: 16px;
    // align-self: stretch;
    border-radius: 10px;
    border: 1px solid $primary__theme_color;
    margin-top: 12px;

    display: flex;
    align-items: flex-end;
    gap: 12px;

    .mentions {
        max-height: 120px !important;

        .mentions__highlighter {
            height: auto;
            max-height: 120px;
        }
    }

    .form-group {
        width: 100% !important;
        textarea {
            width: 100% !important;
            border: 0 !important;
            border-radius: 0 !important;
            padding: 0 !important;
            outline: none !important;
            height: 100% !important;
        }
    }

    h6 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    p {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    &-send-btn {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background-color: $primary__theme_color;
        border-radius: 50%;
        cursor: pointer;
        margin-left: auto;

        .ticketaa-info-card-send-icon {
            position: relative;
            height: 0.875rem;
            background: $primary__background__theme_color;
            svg {
                position: absolute;
                left: -0.5rem;
                width: 0.875rem;
                height: 0.875rem;
            }
        }

        .lds-spinner-small {
            width: fit-content !important;
            width: 5px !important;
            height: 5px !important;
            margin: unset !important;
            margin-left: -15px !important;
            margin-top: -15px !important;
        }
        #{$dark__mode__theme__selector} {
            background-color: $primary__theme_color;
        }

        &.disabled {
            background-color: rgba($gray__bg__color, 0.5) !important;
            opacity: 0.5;
        }
    }
}
