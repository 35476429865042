@import "assets/scss/master";

.classification__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &--text {
        color: $text__default_color;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.375rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color
        }
    }

    &--count {
        color: $text__default_color;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .custom-badge{
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            color: $dark__mode__theme__text__color
        }
    }
}