@import "assets/scss/master";

.follow-up-table-dropdown {
    gap: 10px;
    background: #ffffff;
    border: 1px solid #ecebf5;
    border-radius: 10px;
    height: fit-content;
    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }
    &__heading {
        height: 55px;
        border-bottom: 1px solid #ecebf5;
        padding: 16px 20px;
        color: $black__theme_color;
        font-weight: 600;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
            border-color: $dark__mode__theme__border__color;
        }
    }
    &__body {
        padding: 31px 20px;
        .follow-up-task__item-list {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            .follow-up-task__item {
                width: fit-content;
                background: $default__capsule__theme_color;
                border-radius: 10px;
                padding: 4px 16px;
                font-size: 14px;
                line-height: 32px;
                color: $muted__theme_color_2;
                white-space: nowrap;
            }
            .follow-up-task__item__black {
                background: $black__theme_color !important;
                color: $light__theme_color;
            }
        }
    }
}
