@import "assets/scss/master";

.external__email {
    padding: 20px;
    border: 0;
    background: #F8F8FA;
    border-radius: 8px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
   
    &:nth-child(3n-1) {
        background: #ECEDFD;
    }
  
    &__new--indicator {
        color: $primary__theme_color;
        text-align: center;
        padding: 4px 8px 5px;
        width: fit-content;
        height: fit-content;
        background: rgba(104, 55, 239, 0.1);
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 6px;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            color: $dark__mode__primary__theme_muted_color;
        }
    }

    &--content__container {
        cursor: pointer;

        .external__email--subject {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #060213;
            margin-bottom: 6px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }

        .external__email--content {
            font-size: 13px;
            line-height: 18px;
            color: #696d8c;
            margin-bottom: 10px;
            white-space: pre-wrap;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    .external__email__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

       
        .email__reply__actions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
        }
    }
    .external__email__metadata {
        display: flex;
        gap: 5.67px;
        align-items: center;
        margin-bottom: 10px;
        & p {
            font-size: 10px;
            line-height: 16px;
            color: #a3a3c2;
            margin: 0;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }

            & .sender {
                font-weight: 600;
                letter-spacing: 0.4px;
                color: #060213;
            }
        }
    }


    &.active {
        background-color: $primary__theme_color;
        color: white !important;
        .external__email--subject,
        .external__email--content{
            color: white;
        }
        .external__email__metadata {
            & p {
            
                & .sender {
                    color: white;
                }
            }
        }
        button {
            color: white !important;
        }
        svg {
            fill: white !important;
            path {
                fill: white !important;
            }
        }
        .email__reply__action {
            span {
                color: white !important;
            }
        }
    }
}
