@import "assets/scss/master";

.round__status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f25a68;
    margin-right: 12px;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__red__color;
    }
}

.customer__ticket {
    padding: 16px 0.875rem;
    border: 1px solid #e1e1eb;
    border-radius: 0.625rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.5s;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &.active {
        border-color: $primary__theme_color;
        background-color: $primary__theme_color;

        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__primary__theme_muted_color;
            border-color: $dark__mode__primary__theme_muted_color;
        }

        .customer__email {
            color: $dark__mode__theme__white__color !important;
        }

        .since__when {
            color: #e0e0e0 !important;
        }
    }

    .email__time {
        margin-bottom: 6px;

        .customer__email {
            font-size: 0.875rem;
            color: #060213;
            margin-bottom: 0;
            position: relative;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }

            .round__circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #f25a68;
                position: absolute;
                z-index: 1;
                top: 6px;
                margin-left: 3px;
            }
        }

        .since__when {
            color: #696d8c;
            font-size: 12px;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    .chat__status {
        background: #f5f5f7;
        border-radius: 6px;
        padding: 2px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        #{$dark__mode__theme__selector} {
            background-color: transparent !important;
        }

        &.active {
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
            }
        }

        span {
            font-size: 0.875rem;
            line-height: 1.375rem;
            color: #696d8c;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }

    .mentioned-users {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: -8px;
        &-images {
            display: flex;
            position: relative;
            gap: -1px;
            max-width: 150px;
            width: fit-content;
            overflow-x: scroll;
        }

        span {
            color: #696d8c;
            text-align: right;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
            margin-top: -14px;
        }
        img {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 2.08331rem;
            object-fit: cover;

            &:not(:first-child) {
                margin-left: -0.2rem;
            }

            &:last-child {
                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                }
            }
        }

        &.active {
            span {
                color: #e0e0e0 !important;
            }
        }
    }
}
