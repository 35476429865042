@import "assets/scss/master";

.tickets__form--empty {
    display: flex;
    margin: 16px;
    flex-wrap: wrap;
    gap: 10px;
    border: 1px solid #E1E1EB;
    border-radius: 10px;
    padding: 16px;
}
