@import "assets/scss/master";

.pre-heading {
    top: 0;
    position: sticky;
    padding: 0;
    background: #ffffff;
    padding-bottom: 5px;
    z-index: 1000;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }

    &:hover {
        .pre__error {
            display: block !important;
        }
    }
    svg {
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__white__color !important;
        }
    }
    &__select-ui {
        margin-left: auto;
        border: none !important;
        .selected {
            border: none !important;
        }
    }
    .pre__error {
        color: $danger__theme_color;
        width: fit-content;
        padding: 8px;
        font-size: 12px;
        border-radius: 10px 10px 10px 10px;
        margin-top: -6px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__green__color !important;
            color: $dark__mode__theme__white__color !important;
        }

        svg {
            width: 1rem !important;
            height: 1rem !important;
        }

    }
    .pre__nav {
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid $border_highlight_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        font-size: 16px;
        a {
            color: $muted__theme_color_2 !important;
            text-decoration: none !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
        .anchor-right {
            margin: 0 1.25rem;
        }
        svg {
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
            path {
                #{$dark__mode__theme__selector} {
                    stroke: $dark__mode__theme__white__color !important;
                }
            }
        }
        p {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
        }
    }
}
