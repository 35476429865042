@import "assets/scss/master";

.ticket-info__details {
    margin-bottom: 15rem;
}

.ticketinfo-elements {
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 24px;
        padding-bottom: 0;
        span {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        svg {
            cursor: pointer;
        }
    }
}
