@import "assets/scss/master";

.team__classification__container {
    margin-bottom: 2.5rem;

    h1 {
        font-size: 1.5rem;
    }
}

.unassigned-capsule-header {
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color;
    }

    .custom-badge {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__color;
            background-color: $dark__mode__theme__color;
        }
    }
}
