@import "assets/scss/master";

.conversation__breakers {
    &--header {
        text-transform: capitalize;
        font-family: $primary_font_family--medium;
        color: $black__theme_color;
        letter-spacing: 0.0.938rem;
        margin-bottom: 1.2rem;
        font-size: 1.375rem;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color
        }
    }
}

.empty__conversation__breaker__container {
    border: 0.063rem solid rgba(30, 31, 32, 0.1);
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    & .empty--svg{
        margin-top: 0 !important;
    }
}