@import "assets/scss/master";

.analytics-qa-report-table {
    margin-top: 20px;
    .awaiting {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        border: 0.56px solid $danger__theme_color;
        background: rgba(234, 85, 67, 0.1);
        color: $danger__theme_color;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        width: fit-content;
    }
    .in-progress {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        border: 0.56px solid #b88f14;
        background: rgba(184, 143, 20, 0.1);
        color: #b88f14;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        width: fit-content;
    }
    .complete {
        display: flex;
        width: 65px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        border: 0.56px solid $success__green_color;
        background: rgba(37, 187, 135, 0.1);
        color: $success__green_color;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        width: fit-content;
    }

    &__filter {
        display: flex;
        gap: 20px;
    }
    .uptime-trend {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(37, 187, 135, 0.1);
        color: $success__green_color;
        width: 40px;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }
    .d-flex {
        gap: 10px;
    }
    .downtime-trend {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(37, 187, 135, 0.1);
        color: $danger__theme_color;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }
}
