@import "assets/scss/master";

.tickets__list {
    &--container {
        // position: relative;
        // border-bottom: 1px solid #ecebf5;
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        overflow-y: auto;

        // #mCSB2_container {
        //     display: flex;
        //     flex-direction: column;
        // }
    }
}

.loading-state {
    // background: red;
    padding: 2rem 0;
}


.loader-container {
    position: relative;
    min-height: 50px;
    padding: 10px 0;
    
    &--top {
      // Instead of absolute positioning, use margin
      margin-top: -40px;
      width: 100%;
      visibility: hidden; // Use visibility instead of opacity
      
      &.is-loading {
        margin-top: 0; // Remove negative margin when loading
        visibility: visible;
      }
    }
    
    &--bottom {
      // Instead of absolute positioning, use margin
      margin-bottom: -40px;
      width: 100%;
      visibility: hidden;
      
      &.is-loading {
        margin-bottom: 0;
        visibility: visible;
      }
    }
  }

  .loader-container.is-loading {
    transition: all 0.2s ease-in-out;
  }