@import "assets/scss/master";

.capsule__class__details__header {
    margin: 1rem 0 1.5rem;

    &--title {
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.5rem;
        color: $text__default_color;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
         }
    }

    &--sub__title {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $text__default_color;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
         }
    }
}