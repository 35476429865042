@import "assets/scss/master";

.add-canned-message-option {
    h6 {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    &__list {
        display: flex;
        gap: 12px;
        span {
            cursor: pointer;
            border-radius: 10px;
            border: 1px solid #e1e1eb;
            background: #f5f5f7;
            display: flex;
            padding: 6px 16px;
            align-items: flex-start;
            gap: 10px;
            color: #696d8c;
            text-align: center;

            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
                background-color: $dark__mode__theme__color;
                border-color: $dark__mode__theme__border__color;
            }
        }
    }
}
