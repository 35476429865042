@import "assets/scss/master";

.send__email__actions__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 32px;
    background: #f8f8fa;
    border-top: 1px solid #ecebf5;
    position: sticky;
    left: 0;
    bottom: 20px;
    width: 100%;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
        border-color: $dark__mode__theme__border__color;
    }

    .send__email__actions {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;

        .send__email__actions--attachment {
            svg {
                height: 1.472rem;
                width: 1.284rem;
            }
        }

        &.is-disabled {
            cursor: default;
        }
    }

    .send__email__button {
        height: 46px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 12px !important;
        border-radius: 0.5rem !important;

        .email__send__svg {
            svg {
                width: 0.984rem;
            }
        }
    }
}
