@import "assets/scss/master";

.externalemail-clipboard {
    padding: 16px 24px;
    gap: 8px;
    border-bottom: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-bottom: 1px solid $dark__mode__theme__border__color;
    }
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        span {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        svg {
            cursor: pointer;
        }
        .is-closed {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__file {
        display: flex;
        padding: 10px 14px;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: #f5f5f7;
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
            background-color: $dark__mode__theme__color;
        }
        svg {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
                stroke: $dark__mode__theme__white__color;
            }
            path {
                #{$dark__mode__theme__selector} {
                    fill: $dark__mode__theme__white__color;
                    stroke: $dark__mode__theme__white__color;
                }
            }
        }
    }
    &__attachments {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
    }
    &__body {
        h5 {
            color: $black__theme_color;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-top: 12px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        p {
            color: #696d8c;
            font-family: Gelion;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }
}

.external-email-ticket-list {
    display: flex;
    width: 396px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #ecebf5;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    &__item {
        display: flex;
        gap: 8x;
        span, label {
            color: #696d8c;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;;
            }
        }
    }
}

.external-email-ticket-list__heading {
    color: #696d8c;
    font-family: Gelion;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}
