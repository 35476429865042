@import "assets/scss/master.scss";

#ticket__info__dropdown {
    .ticket__info__dropdown__menu {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        border-radius: 10px !important;
        width: fit-content !important;
        border: 1px solid $border__theme_color !important;
        background-color: $light__theme_color !important;
        #{$dark__mode__theme__selector} {
            border: 1px solid $dark__mode__theme__border__color !important;
            background-color: $dark__mode__theme__color !important;
        }
    }
    .info__bottom__menu {
        left: -3px !important;
        // top: 45px !important;
        top: 35px !important;
        // z-index: -1 !important;
        background-color: transparent !important;
        border: none !important;
        &::after {
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #ffffff transparent;
            top: -10px;
            top: 0px;
        }

        &::before {
            border-color: transparent transparent #ffffff transparent;
            top: -10px;
            top: 0px;
        }
    }

    .info__top__menu {
        left: -3px !important;
        // top: -58px !important
    }
}
