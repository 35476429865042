@import "assets/scss/master";

.badge__label {
    background: #f5f5f7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 3px 16px;
    color: #060213;
    font-size: 0.875rem;
    letter-spacing: 0.15px;
    height: 36px;
    padding: 0 16px;
    white-space: nowrap;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        background: $dark__mode__theme__color;
    }
    &--icon {
        margin-left: 0.625rem;
    }
    svg path {
        fill: #696d8c;
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__text__color;
        }
    }
}
