@import "assets/scss/master";

.analytics-qa-tmessage {
    display: flex;
    width: 55%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: var(--Main-Purple, #6837ef);

    .white {
        color: white !important;
    }

    .black {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    &.received {
        background: #6837ef !important;
        p {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
        svg {
            fill: #fff !important;
            path {
                fill: #fff !important;
            }
        }
    }

    p {
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    &.sent {
        margin-left: auto;
        background: #f8f8fa !important;
        p {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
        svg {
            fill: #fff !important;
            path {
                fill: #fff !important;
            }
        }
    }

    &.agent {
        background: #f8f8fa !important;
    }

    &.assistant {
        background: rgba(0, 102, 245, 0.1) !important;
    }
    &.downtime {
        background-color: #ea5543 !important;
        p {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
        svg {
            fill: #fff !important;
            path {
                fill: #fff !important;
            }
        }
    }

    &.response_agent {
        background: #f8f8fa !important;
        p {
            color: #696d8c !important;
        }
        span {
            color: #060213 !important;
        }
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c;
            }
            span {
                color: #060213 !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2;
            }
            .message__type {
                color: #0066f5 !important;
            }
        }
        .dot {
            background-color: $black__theme_color !important;
        }
    }

    &.scenario {
        background: #f8f8fa !important;
        p {
            color: #696d8c !important;
        }
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c !important;
            }
            span {
                color: #060213 !important;
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2 !important;
            }
            .message__type {
                color: #f19165 !important;
            }
        }
        .dot {
            background-color: $black__theme_color !important;
        }
    }

    &.mention_agent {
        background: #f8f8fa !important;
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c;
            }
            span {
                color: #060213 !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2;
            }
            .message__type {
                color: #25bb87 !important;
            }
        }
        .dot {
            background-color: $black__theme_color !important;
        }
    }
    &.email {
        background: #f8f8fa !important;
        p {
            color: #696d8c !important;
        }
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c;
            }
            span {
                color: #060213 !important;
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2 !important;
            }
            .message__type {
                color: #060213 !important;
            }
        }
        .dot {
            background-color: $black__theme_color !important;
        }
    }

    &.email_update {
        background: #696d8c !important;
        p {
            color: white !important;
        }
        span {
            color: white !important;
        }
    }

    &.email_third-user {
        background: #060213 !important;
        p {
            color: white !important;
        }
        span {
            color: white !important;
        }
    }
    .dot {
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: white;
    }
    &.response {
        background: rgba(0, 102, 245, 0.1) !important;
        p {
            color: #696d8c !important;
        }
        span {
            color: #060213 !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c;
            }
            span {
                color: #060213 !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2;
            }
            .message__type {
                color: #0066f5 !important;
            }
        }
    }
    &.mention {
        background: rgba(37, 187, 135, 0.1) !important;
        p {
            color: #696d8c !important;
        }
        span {
            color: #a3a3c2 !important;
        }
        .analytics-qa-tmessage__sender {
            p {
                color: #696d8c;
            }
            span {
                color: #060213 !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color !important;
                }
            }
        }
        .analytics-qa-tmessage__bottom {
            span {
                color: #a3a3c2;
            }
            .message__type {
                color: #25bb87 !important;
            }
        }
    }
    &.uptime {
        background-color: #25bb87 !important;
        p {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
        svg {
            fill: #fff !important;
            path {
                fill: #fff !important;
            }
        }
    }

    &.uptime {
        background-color: #25bb87 !important;
        p {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
        svg {
            fill: #fff !important;
            path {
                fill: #fff !important;
            }
        }
    }

    box-shadow: 8px 4px 16px 0px rgba(0, 0, 0, 0.04);
    &__sender {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p {
            color: #696d8c;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin: 0;
        }
        svg {
            width: fit-content !important;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
            color: var(--Text-mute-text, #a3a3c2);
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }
    }
}
