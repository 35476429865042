@import "assets/scss/master";

.follow__up {
    .empty__follow__up__container {
        padding: 0 1.5rem;
        margin-top: 0rem;
        margin-bottom: 7rem;
        .empty__follow__up__tasks {
            width: 100%;
            height: 300px !important;
            border: 0.063rem solid $border__theme_color;
            border-radius: 10px !important;
            margin: 10px auto !important;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
            }
            .empty--svg {
                margin-top: 0 !important;
            }

            .empty--text {
                text-align: center !important;
                width: 85% !important;
                font-size: 1rem;
                line-height: 20px !important;
                color: #696d8c !important;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color !important

                }
            }
        }
    }
}
