@import "assets/scss/master";

.schedule__checkbox {
    margin-bottom: 0.75rem !important;
}

.relate__capsule__group {
    margin: 1rem 0;

    & label {
        color: $primary__theme_color !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
        }
    }
}