@import "assets/scss/master";

.status-map-chart {
    padding: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    .title {
        font-weight: 400;
        font-size: 16px;
        // line-height: 1.5rem;
        color: #060213;
        margin-bottom: -40px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    .legend {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #696d8c;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    .legend-name {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #060213;
        margin-top: -40px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    .bar-container::-webkit-scrollbar {
        width: 2px;
        height: 0.625rem;
    }
    .bar-container::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        width: 2px;
        height: 0.625rem;
    }
    .bar-container::-webkit-scrollbar-thumb {
        width: 2px;
        height: 0.625rem;
    }
    .bar-container::-webkit-scrollbar-thumb:hover {
        background: rgba(163, 163, 194, 0.2);
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color;
        }
    }
    .bar-container {
        max-width: 900px;
        height: 100%;
        height: fit-content;
        @media screen and (max-width: 900px) {
            max-width: 400px !important;
        }

        @media screen and (max-width: 540px) {
            max-width: 200px !important;
        }
        .bar-container-inner {
            width: 100%;
            height: 192px;
            margin-left: 80px;
            display: flex;
            align-items: center;
            .barBody {
                height: 40px;
            }
            .bar {
                background: #44daa5;
                box-shadow: 0px 1px 0px rgba(242, 242, 242, 0.1);
                width: 30px;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 0.8);
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                    background: $dark__mode__theme__green__color;
                }
            }
            .indicator {
                font-weight: 400;
                line-height: 1.375rem;
                color: #696d8c;
                margin-top: 17px;
                font-size: 0.625rem;
                text-align: center;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                // transform: rotate(60deg);
            }

            .popup-container {
                .popup {
                    position: relative;
                    display: block;
                    cursor: pointer;
                    margin-top: -1.5rem;
                }

                .popup .popuptext {
                    // visibility: hidden;
                    width: 169px;
                    height: 70px;
                    background: #1e1e2f;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    padding: 14px 0;
                    position: absolute;
                    z-index: 1;
                    bottom: 125%;
                    left: 50%;
                    #{$dark__mode__theme__selector} {
                        background-color: $dark__mode__theme__background__color;
                    }
                    margin-left: -80px;
                    h6 {
                        opacity: 0.85;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 1.25rem;
                        text-align: center;
                        color: #ffffff;
                        margin-bottom: 0.063rem;
                    }
                    p {
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.375rem;
                        color: #f25a68;
                        #{$dark__mode__theme__selector} {
                            color: $dark__mode__theme__red__color
                        }
                    }
                    .text-white {
                        color: white;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.375rem;
                        #{$dark__mode__theme__selector} {
                            color: $dark__mode__theme__white__color
                        }
                    }
                }

                .popup .popuptext::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: #555 transparent transparent transparent;
                }

                .popup .show {
                    // visibility: visible;
                    -webkit-animation: fadeIn 1s;
                    animation: fadeIn 1s;
                }

                @-webkit-keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
