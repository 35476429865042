@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/master";

.custom-dropdown {
    width: 100%;
    color: #060213;
    #{$dark__mode__theme__selector} {
        color: $light__theme_color;
    }
    .selected {
        border: 0.063rem solid rgba(30, 31, 32, 0.08);
        box-sizing: border-box;
        border-radius: 0.625rem;
        font-family: $primary_font_family--medium;
        padding-left: 1.25rem;
        padding-right: 1.125rem;
        font-size: 1.25rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        &--lg {
            height: 4.5rem;
        }
        &--md {
            height: 3.25rem;
        }
        &--sm {
            height: 2.813rem;
        }
        &--normal-weight {
            font-weight: 400 !important;
            font-family: $primary_font_family;
            color: $muted__theme_color_2 !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
        &--features {
            background-color: $primary__theme_color;
            color: #fff !important;
            font-size: 1rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
        &--borderless {
            color: $primary__theme_color !important;
            font-size: 1rem;
            border: 0;
            padding: 0;
            justify-content: flex-end !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color !important;
            }
        }
        .selected__label {
            margin-right: 0.625rem;
        }
        .anchor {
            margin-bottom: -0.313rem;
        }
    }
    .options {
        background: #fcfcfd;
        border: 0.063rem solid #dee1e5;
        box-shadow: 0px 4rem 4rem -3rem rgba(31, 47, 70, 0.12);
        border-radius: 0.625rem;
        // padding: 0.5rem 0.938rem 0.5rem 1.125rem;
        // padding-top: 0.5rem;
        // padding-bottom: 0.5rem;
        margin-top: 0.75rem;
        z-index: 1000;
        text-transform: capitalize;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            background: $dark__mode__theme__color;
        }

        &--downtime-options {
            width: 15.875rem !important;
            top: 1.875rem;
            right: 0;
        }
        .opt {
            padding: 0.625rem 0.625rem;
            // padding: 0.5rem 0.938rem 0.5rem 1.125rem;
            &:hover {
                background: #f4f5f6;
                #{$dark__mode__theme__selector} {
                    width: 100%;
                    border-radius: 0.625rem;
                    background: $dark__mode__theme__capsule__count__color;
                }
            }
            &--selected {
                background: $light__theme_color;
                border-radius: 0.5rem;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__theme__color;
                }
            }
        }
    }
}
