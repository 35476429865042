.capsule__status__listing__heading {
    display: flex;
    align-items: baseline;

    .select-input {
        margin-right: 1rem;
        width: 180px;
    }
    
    .search-input {
        margin-top: 0.2rem;
    }
}
