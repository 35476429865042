@import "assets/scss/master";

.classes-container-box {
    height: 150px;
    overflow: scroll;
    border: 1px solid rgba(30, 31, 32, 0.1);
    border-radius: 0.625rem;
    margin-bottom: 58px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 5px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
     }
    .shiny__line {
        min-width: 150px;
        min-height: 30px;
        border-radius: 0.625rem;
        margin-bottom: 0.625rem;
        margin-right: 0.625rem;
    }
}

.capsule__classes {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    .capsule {
        margin-right: 0.625rem;
    }
}

.capsule-settings {
    h1 {
        font-size: 34px;
    }
    h3 {
        font-size: 28px;
    }
    h4 {
        font-size: 18px;
    }
    p {
        font-size: 0.875rem;
    }

    .attached-classes-table {
        margin-bottom: 52px;
        &__header {
            border-bottom: 1px solid $border__theme_color;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
             }
        }
    }
}
