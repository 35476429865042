// prefix mixins
$prefixes: webkit, khtml, moz, ms, o;
@mixin prefix($option, $value...) {
  @each $p in $prefixes {
    // add the prefixed property
    -#{$p}-#{$option}: $value;
  }
  
  // add the regular property
  #{$option}: $value;
}

// prevent user selection
@mixin noselect() {
  @include prefix(user-select, none);
  -webkit-touch-callout: none;
}

// keyframes mixin
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {@content;}
   @-khtml-keyframes #{$animation-name} {@content;}
     @-moz-keyframes #{$animation-name} {@content;}
      @-ms-keyframes #{$animation-name} {@content;}
       @-o-keyframes #{$animation-name} {@content;}
          @keyframes #{$animation-name} {@content;}
}

// modify this variable if you have a different amount of div.ball elements.
$balls: 3;



// uncomment the rotateY parts, it didn't rotate the nth(1+) elements in IE :()
@include keyframes(bounce) {
  0% {
    @include prefix(transform, translateY(-6px) /*rotateY(180deg)*/);
  }
  
  50% {
    // background: #0a6;
            opacity: 0.4;


  }
  
//   95% {
//     border-radius: 50%;
//   }
  
  100% {
    @include prefix(transform, translateY(6px) /*rotateY(-180deg)*/);
    // border-radius: 50% 50% 10% 10%;
  }
}

.spring__container {
    width: fit-content;
    // height: 33px;
    // background: #fff;
    margin: auto;
    // margin-top: 10px;
    // border-radius: 18px;
    // padding: 10px 15px;
    // background: #333;
    // border: 1px solid #ecebf5;
    // width: 500px;

    $delay: 0s; // initial animation delay, 0s.
    @for $i from 1 through $balls {
        div.ball:nth-of-type(#{$i}) {
            // set the animation for the current ball
            @include prefix(animation, bounce 0.5s ease-in infinite alternate);
            @include prefix(animation-delay, $delay);

            // increase delay for the next ball
            $delay: ($delay + 0.2s);
        }
    }

    div.ball {
        @include prefix(border-radius, 50%);

        @include prefix(transform, translateY(-6px));
        @include prefix(transform-style, preserve-3d);

        background: #9e9ea1;

        display: inline-block;

        width: 8px;
        height: 8px;

        margin-right: 8px;
        &:last-child {
            margin-right: 0px;
        }
    }
}
