.delete-brances-list {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 24px;
    &__item {
        width: 100%;
        padding: 15px 8px;
        gap: 12px;
        display: flex;
        text-align: center;
        background: #ffffff;
        border: 1px solid #ecebf5;
        border-radius: 12px;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;
        &--icon {
            margin-top: -2px;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #060213;
        }
    }
}
