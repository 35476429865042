@import "assets/scss/master";

.ticket__distribution{
 
        margin-bottom: 1.875rem;

        .ticket__header {
            font-size: 1.125rem;
            color: $text__default_color;
            margin-bottom: 1rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }

            label {
                font-size: 0.875rem;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color !important;
                }
            }
}
