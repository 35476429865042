@import "assets/scss/master";

.ticketaa-run-card {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #25bb87;
    margin-top: 8px;
    .task_counter {
        color: #fff;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        display: flex;
        width: 32px;
        height: 32px;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 1000px;
        background: #1d9169;
    }
    .d-flex {
        gap: 6px;
        align-items: center;
    }
    .task_timer {
        width: 20px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    svg {
        fill: $dark__mode__theme__white__color;
        path {
            fill: $dark__mode__theme__white__color;
        }
    }
    .task_timer_paused {
        svg {
            fill: #ff3364 !important;
            path {
                fill: #ff3364 !important;
            }
        }
    }
    .task_status {
        font-family: Gelion;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $dark__mode__theme__white__color;
    }
}
