@import "../../../../assets/scss/master";

.rc-pagination {
    list-style: none;
    margin-left: auto;
    display: flex;
    float: right;
    margin: 1rem 0;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e1e1eb;
        border-radius: 4px;
        text-align: center;
        padding: 5px 4px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        color: #696d8c;
        font-size: 0.875rem;
        font-family: $primary_font_family--medium;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
            color: $dark__mode__theme__text__color;
        }
        &:not(:last-child) {
            margin-right: 8px;
        }
        .rc-pagination-item {
            &:hover {
                background: $primary__theme_color;
                color: $light__theme_color;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__white__color;
                    background: $dark__mode__primary__theme_color;
                }
            }
        }

        &.rc-pagination-disabled {
            cursor: not-allowed;
            background: #e6e6ed;
            border-color: #e6e6ed;
            opacity: 0.5;
            #{$dark__mode__theme__selector} {
                background: $dark__mode__theme__color;
                border-color: $dark__mode__theme__border__color;
            }
        }
        &.rc-pagination-item-active {
            color: $primary__theme_color;
            border-color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
                color: $dark__mode__primary__theme_muted_color;
            }
        }

        &.rc-pagination-prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
