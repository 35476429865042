@import "assets/scss/master";

.copy__recipient {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #696d8c;
    cursor: pointer;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color
    }
    &:hover {
        text-decoration: underline;
    }
}
