@import "assets/scss/master";

.schedule__input__label {
    color: $text__default_color;

    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}

.sub-label {
    color: $muted__theme_color_2;
    font-size: 1rem;

    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}

.schedule__error {
    border: 0.063rem solid $danger__theme_color !important;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
    }
}

.schedule__input {
    border: 1px solid $border_highlight_color;
    border-radius: 0.625rem;
    height: 100%;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__background__color !important;
    }

    .option__select .css-1s2u09g-control {
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
            color: $light__theme_color !important;
        }

        .css-qc6sy-singleValue {
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }
    }

    .option__select__control {
        border: 0 !important;

        &:focus {
            border: 0 !important;
        }

        &:hover {
            border: 0 !important;
        }
    }

    .option__select {
        cursor: pointer !important;

        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color !important;
        }
    }

    input {
        border: 0 !important;
        font-family: $primary_font_family;
        color: $muted__theme_color_2;
        background: transparent;
        padding: 0;
        // text-align: center;
        outline: none;
        width: 50px;

        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }

    span {
        color: $muted__theme_color_2;
        font-size: 0.875rem;

        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
        }
    }
}

.switch6 {
    max-width: 17em;
    margin: 0 auto;
}

.switch6-light>span,
.switch-toggle>span {
    color: $dark__theme_color;

    #{$dark__mode__theme__selector} {
        color: $light__theme_color !important;
    }
}

.switch6-light {

    span span,
    label {
        color: #2b2b2b;

        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}

.switch-toggle {

    span span,
    label {
        color: #2b2b2b;
    }

    a {
        display: none;
    }
}

.switch6-light {
    background: #f5f5f7;
    /* Border/stroke-field */
    height: 45px;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 0.625rem;

    display: block;
    position: relative;
    overflow: visible;
    padding: 6px;
    margin-left: 0px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__color;
    }

    span span {
        display: none;
    }

    * {
        box-sizing: border-box;
    }

    a {
        display: block;
        transition: all 0.2s;
    }

    label {
        line-height: 30px;
        vertical-align: middle;
        font-weight: 700;
        // margin-bottom: px;
        max-width: 100%;
    }

    >span {
        line-height: 30px;
        vertical-align: middle;
    }

    input {
        // &:focus {
        //     ~ a,
        //     + label {
        //         // outline: 1px dotted rgb(136, 136, 136);
        //     }
        // }
        position: absolute;
        opacity: 0;
        z-index: 5;

        &:checked~a {
            right: 0%;
        }
    }

    >span {
        position: absolute;
        left: -100px;
        width: 100%;
        margin: 0px;
        padding-right: 100px;
        text-align: left;

        span {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 5;
            display: block;
            width: 50%;
            margin-left: 100px;
            text-align: center;

            &:last-child {
                left: 50%;
            }
        }
    }

    a {
        position: absolute;
        right: 50%;
        top: 0px;
        z-index: 4;
        display: block;
        width: 50%;
        height: 100%;
        padding: 0px;
    }
}

.btn-active-schedule {
    background: #ffffff !important;
    /* Main/Purple */
    // font-size: 0.875rem;
    color: #060213;
    border: 1px solid #6837ef !important;
    font-family: $primary_font_family--medium;
    border-radius: 8px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color !important;
        background-color: $dark__mode__theme__background__color !important;
        color: $light__theme_color !important;
    }
}