@import "assets/scss/master";

.branch__image__attached {
    display: flex;
    margin-top: 1.25rem;
    gap:  11.33px;
    cursor: pointer;
    width: fit-content;

    &--icon {
        svg {
            path {
                fill: $primary__theme_color;
                #{$dark__mode__theme__selector} {
                    fill: $dark__mode__primary__theme_muted_color
                }
            }
        }
    }

    &--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 27px;
        display: inline-block;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color
        }
    }
}
