@import "assets/scss/master";

.downtime__brancheslist {
    height: 42px;
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    overflow: hidden;
    &--item {
        color: #696d8c;
        height: 100%;
        padding: 10px 0.875rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border: 1px solid $border_highlight_color;
        cursor: pointer;
        font-weight: 700;
        letter-spacing: 0.15px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            border-color: $dark__mode__theme__border__color;
        }
        &:not(:first-child) {
            border-left: 0;
        }
        &:first-child {
            border-radius: 10px 0px 0px 0.625rem;
        }
        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }
        &.selected {
            background: $primary__theme_color;
            border-color: $primary__theme_color;
            color: $light__theme_color;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__primary__theme_muted_color;
                color: $dark__mode__theme__white__color;
            }
        }
    }
}
