@import "assets/scss/master";
.capsule_group {
    height: 100px;
    overflow-y: scroll;
    background: #d2d2d91c;
    padding: 1.25rem;
    margin-bottom: 2rem;
    border-radius: 0.625rem;
    border: 1px solid #f5f5f7;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 1rem;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    i {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}
