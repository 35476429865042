@import "assets/scss/master";

.branch__collection__capsule {
    transition: all 0.2s;
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.15px;
    font-size: 0.875rem;
    padding: 6px 16px;
    height: 36px;
    text-align: center;
    text-transform: capitalize;
    font-family: $primary_font_family;
    border: 1px solid !important;
    border-radius: 0.625rem;
    cursor: pointer;
    border-color: #f5f5f7 !important;
    max-height: 52px;
    background: #060213;
    color: #fff;
    #{$dark__mode__theme__selector} {
        color: $light__theme_color;
        background-color: $dark__mode__theme__color;
        border: none !important;
    }
}
