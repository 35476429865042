@import "assets/scss/master";

.product__classes__header {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: $text__default_color;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 0px;
    #{$dark__mode__theme__selector} {
        color: $light__theme_color;
    }
    &--badge {
        border-radius: 100%;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        width: 1.75rem;
        height: 1.75rem;
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        background-color: $badge__theme__color;
        color: $black__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
            background-color: $dark__mode__theme__color;
        }
    }
}