@import "assets/scss/master";

.conversations__header {
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $border__theme_color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    &--text {
        font-size: 1rem;
        color: $text__default_color;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
}