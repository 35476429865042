@import "assets/scss/master";

.empty__canned__content__text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #a3a3c2;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
    }
}
