@import "assets/scss/master";

* {
    .status__card {
        width: 280px;
        height: 132px;
        padding: 1.5rem;
        border: 1px solid $border__theme_color;
        background-color: #fafafa;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
            border-color: $dark__mode__theme__border__color;
        }
        &__heading {
            display: flex;
            align-items: center;
            p {
                font-size: 0.875rem;
            }
            span {
                font-size: 12px;
            }
        }

        .status__card__amount {
            font-size: 1.5rem;
            color: #060213;
            font-weight: 600;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
        .text-grey,
        .link {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }
}
