@import "assets/scss/master";

.convo__map{
    &--controller {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
       
        .page__list {
            margin: 0 1rem;
            display: flex;
            align-items: center;
        .map__circle {
            background:#E6E6ED;
            #{$dark__mode__theme__selector} {
               background: $dark__mode__theme__text__color;
            }
            width: 12px;
            height: 12px;
            border-radius: 50%;
            &:not(:last-child){
                margin-right: 4px;
            }
            &:hover {
                &:not(.active){
                    opacity: 0.8;
                }
            }
            &.active {
                background: $primary__theme_color;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__primary__theme_muted_color;
                 }
            }
        }
    }
    }
}