@import "assets/scss/master";

.chat__input--group {
    background: #fff;
    // position:  ;
    width: 95%;
    // position: sticky;
    // left: 2.5%;
    // bottom: 15px;
    margin: 0 auto;

    // overflow: hidden;
    // padding: 12px 24px;
    padding: 5px 24px;
    // margin: auto;
    border: 1px solid #ecebf5;
    border-radius: 10px;

    height: 4rem;

    // &.size__up__input__group {
    //     height: 10rem;
    // }

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
        background-color: $dark__mode__theme__color !important;
    }

    input {
        height: fit-content !important;
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0 !important;
        resize: none;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            background-color: $dark__mode__theme__color !important;
        }
    }

    textarea {
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0 !important;
        resize: none;
        padding: 1rem 0 !important;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            background-color: $dark__mode__theme__color !important;
        }

        &::placeholder {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
    }
    .action__group {
        // padding: 0px 6px;
        align-items: center;

        &.adjust__action__group {
            align-items: flex-end;
        }

        #chat-input {
            height: 20px;
        }

        &--actions {
            display: flex;
            align-items: center;
            gap: 0.894rem;
            justify-content: flex-end;

            &.adjust__actions {
                margin-bottom: 10px;
            }

            .emoji__picker__toggle {
                svg {
                    width: 16px;
                    height: 24px;
                }
            }
        }

        .send__button {
            height: 32px !important;
            width: 32px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
    }
}
