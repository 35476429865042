@import "assets/scss/master";

.edit__template__btn {
    color: $primary__theme_color;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.813rem;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__primary__theme_muted_color
    }
}

.create__template__btn {
    height: 1rem;
}