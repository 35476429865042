@import "assets/scss/master";

.empty__notification__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
    gap: 0.5rem;

    p {
        font-size: 0.75rem;
        line-height: 1.25rem;
        margin-bottom: 0;
        color: $text__muted__theme__color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }

    .empty {
        height: fit-content !important;
    }
}
