@import "assets/scss/master";

.message__tag {
    background: $light__theme_color;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 10px;
    color: $text__default_color;
    width: fit-content;
    padding: 6px 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.375rem;

    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
        color: $dark__mode__theme__text__color;
    }
    &.active__message__tag {
        background: $tag__theme__color;
        border: 0.063rem solid $tag__theme__color;
        color: $light__theme_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__red__color;
            background: $dark__mode__theme__color;
        }
    }
}
