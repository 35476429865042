@import "assets/scss/master";

.startup-profile {
    &-details {
        display: flex;
        justify-content: space-between;
        .startup-profile__button {
            padding: 0.75rem 1.188rem;
            width: fit-content;
            background: $primary__theme_color;
            border-radius: 0.625rem;
            color: white;
            height: 3rem;
            font-size: 0.875rem;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                background: $dark__mode__primary__theme_color;
            }
        }
        div {
            display: flex;
            .startup-logo {
                width: 108px;
                height: 104px;
                border-radius: 100%;
            }
            .startup-detail {
                margin-left: 8px;
                display: block;
                h4 {
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.375rem;
                    letter-spacing: 0.116667px;
                    color: $grey__theme_color;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__white__color !important;
                    }
                }
                small {
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.313rem;
                    letter-spacing: 0.1px;
                    color: $muted__theme_color_2;
                    display: block;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color !important;
                    }
                }
            }
        }
    }
    &-description {
        margin-top: 2.188rem;
        h4 {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.813rem;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: $grey__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
        p {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.688rem;
            letter-spacing: 0.1px;
            color: $muted__theme_color_2;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
        button {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.063rem;
            letter-spacing: 0.2px;
            text-decoration-line: underline;
            color: $primary__theme_color;
            border: none;
            background-color: transparent;
            margin-left: -4px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color !important;
            }
        }
    }
    &-modal {
        padding: 0rem 4.75rem;
        margin-bottom: 2rem;
    }
}
