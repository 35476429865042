@import "assets/scss/master";

.comment__group {
    padding: 1.156rem 0 1.203rem;
    border-bottom: 0.063rem solid rgba(30, 31, 32, 0.05);
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }

    .comment__metadata__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comment__created__by {
            display: flex;
            gap: 0.75rem;
            align-items: center;

            & img {
                object-fit: cover;
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                border: 3px solid #c1c7d0;
                #{$dark__mode__theme__selector} {
                    border-color: $dark__mode__theme__border__color;
                }
            }

            & p {
                display: inline-block;
                font-size: 16px;
                line-height: 19px;
                color: #7c7f9b;
                margin: 0;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
                & span {
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    color: #696d8c;
                    display: inline;
                    #{$dark__mode__theme__selector} {
                        color: $dark__mode__theme__text__color;
                    }
                }
            }
        }

        .comment__timestamp {
            display: flex;
            align-items: center;
            gap: 0.538rem;

            & span {
                font-size: 13px;
                line-height: 16px;
                color: #a3a3c2;
                display: inline;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__theme__text__color;
                }
            }
        }
    }

    .comment__container {
        padding: 12px 1rem;
        background: #fafafc;
        border-radius: 8px;
        margin: 12px 0 15.25px;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__color;
        }
        .comment {
            font-size: 14px;
            line-height: 18px;
            color: #696d8c;
            margin: 0;
            white-space: pre-wrap;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
            & a {
                pointer-events: none;
            }
        }
    }

    .comment__reactions__container {
        display: flex;
        justify-content: flex-end;

        .comment__reactions {
            display: flex;
            align-items: center;
            gap: 19.6px;
        }
    }
}
