@import "assets/scss/master";
.custom__rule__element {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    color: #060213;
    border: 1px solid #e1e1eb;
    border-radius: 6px;
    padding: 6px 16px;
    height: 36px;
    white-space: nowrap;
    margin-right: 0.625rem;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
        color: $dark__mode__theme__text__color;
    }
    &.active {
        border-color: #6837ef;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__primary__theme_muted_color;
        }
    }

    svg {
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__text__color;
        }
        path {
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__text__color;
            }
        }
    }
    &.error__border {
        border: 0.063rem solid red !important;
    }
}
