@import "assets/scss/master";
.form__setup__header {
    margin-bottom: 1.313rem;

    &--actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &--error {
        display: block;
    }

    &--cancel{
        #{$dark__mode__theme__selector} {
            color: $light__theme_color !important;
            border-color: $dark__mode__theme__border__color;
        }
    }
}