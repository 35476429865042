@import "assets/scss/master";

.convo__branch {
    margin: 18px auto;
    padding: 15px;
    border: 1px solid #e1e1eb;
    border-radius: 10px !important;
    background-color: #fff;
    #{$dark__mode__theme__selector} {
        border: 1px solid $dark__mode__theme__border__color;
        background-color: $dark__mode__theme__color;
    }
    // width: 172px;
    .convo__branch__name {
        text-transform: capitalize;
        font-size: 0.875rem;
        color: #696d8c;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: #f5f5f7;
        }
    }

    &.is-bypassed {
        .convo__branch__name {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
        }
    }

    &__active {
        border: 1px solid #25bb87;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
        border-radius: 5px;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__green__muted__color;
        }
        .convo__branch__name {
            color: #060213;
            font-family: $primary_font_family--medium;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }
    }

    .branch__main__sentence {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #060213;
        padding-left: 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }

    .branch__options {
        &__active {
            color: $primary__theme_color;
            font-family: $primary_font_family--medium;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
        }
        &__details {
            border: 1px solid #e1e1eb;
            border-radius: 5px;
            margin-top: 0.625rem;
            padding: 0 8px;
            cursor: pointer;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color;
                color: $dark__mode__theme__text__color;
            }
            .branch__column__option {
                font-size: 0.875rem;
                padding: 6px 0;
                white-space: break-spaces;
                &:not(:last-child) {
                    border-bottom: 1px solid #e1e1eb;
                    #{$dark__mode__theme__selector} {
                        border-color: $dark__mode__theme__border__color;
                    }
                }
            }
        }
        &--toggler {
            margin-top: 0.625rem;
            cursor: pointer;
            color: #696d8c;
            font-size: 0.875rem;
            display: flex;
            align-items: center;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
            }
            svg {
                margin-right: 0.625rem;
                transition: all 0.5s;
            }
            &.show {
                color: $primary__theme_color;
                #{$dark__mode__theme__selector} {
                    color: $dark__mode__primary__theme_muted_color;
                }
                svg {
                    transform: rotate(90deg);
                    path {
                        stroke: $primary__theme_color;
                        #{$dark__mode__theme__selector} {
                            stroke: $dark__mode__primary__theme_muted_color;
                        }
                    }
                }
            }
        }
    }
}
