@import "assets/scss/master";
.email__success {
    // border: 0.063rem solid #ECEBF5;
    // border-radius: 1rem;
    padding: 60px 23px 100px;
    margin-top: 4.969rem;

    .close__email__success {
        display: flex;
        justify-content: flex-end;
    }

    &--message__container  {
        margin-top: 40px;
        // width: 357px;
        text-align: center;
        padding: 0 30px;

        & .email__success__message--title {
            font-weight: 500;
            font-size: 2rem;
            line-height: 56px;
            color: #25BB87;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__green__muted__color !important;
            }
        }

        & .email__success__message--body {
            font-size: 22px;
            line-height: 30px;
            color: #696D8C;
            margin: 0;
        }
    }
}