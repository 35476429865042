@import "assets/scss/master";

.capsule__setting--header {
    position: sticky;
    background: $light__theme_color;
    padding: 2rem 0;
    top: 0;
    z-index: 2;

    #{$dark__mode__theme__selector} {
       background-color: $dark__mode__theme__background__color;
     }

    &--contents {
        display: flex;
        justify-content: space-between;
        
        &--title {
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 2.5rem;
            color: $text__default_color;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
             }
        }

        &--sub__title {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $text__default_color;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color;
             }
        }
    }

}
