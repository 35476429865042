@import "assets/scss/master";

.edit__capsule__format__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.438rem;

    &--title {
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.5rem;
        color: $text__default_color;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
         }
    }

    &--sub__title {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $text__default_color;
        margin: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
         }
    }
}