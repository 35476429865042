@import "assets/scss/master";
.signup{
    h1 {
        font-size: 2.125rem;
        font-weight: 600;
        line-height: 2.5rem;
        letter-spacing: -0.5px;
        margin-bottom: 1.875rem;
        text-transform: none !important;
    }
}