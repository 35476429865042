@import "assets/scss/master";

.ticket-aa-card-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h5 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
        display: flex;
        align-items: center;
        gap: 4px;
        .form-check {
            input {
                background-color: $success__theme_color !important;
                outline: none !important;
                border: none !important;
                margin-top: 7px;
                #{$dark__mode__theme__selector} {
                    background-color: $dark__mode__theme__green__muted__color !important;
                }
            }
        }
    }

    &__dot {
        width: 8px;
        height: 8px;
        background-color: $notification__theme__color;
        display: block;
        border-radius: 50%;
    }

    svg {
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__white__color;
        }
        path {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color;
            }
        }
    }
}
