//customization for bootstrap
$theme-colors: (
	"primary": #6837ef,
	"danger": #ff3364,
	"success": #45b26b,
	"warning": #f49e4c,
	"info": #557aff,
	"dark": #060213,
	"muted": #b0b7c3,
	"light": #f9f9f9,
	"faded": #e0e0e0,
	"link": #0878e9,
	"secondary": #e1e1eb,
	"grey": #696d8c,
);

$position-values: (
	n15: -15%,
	n10: -10%,
	n5: -5%,
	0: 0,
	5: 5%,
	10: 10%,
	15: 15%,
	18: 18%,
	20: 20%,
	25: 25%,
	30: 30%,
	40: 40%,
	50: 50%,
	60: 60%,
	70: 70%,
	80: 80%,
	90: 90%,
	100: 100%,
	110: 110%,
	120: 120%,
	130: 130%,
	140: 140%,
	150: 150%,
	160: 160%,
	170: 170%,
	180: 180%,
);

$spacer: 1rem;
$spacers: (
	n5: -$spacer * 3,
	n4: -$spacer * 1.5,
	n3: -$spacer,
	n2: -$spacer * 0.5,
	n1: -$spacer * 0.25,
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	6: $spacer * 4.5,
	7: $spacer * 6,
	8: $spacer * 7.5,
	9: $spacer * 9,
);

$form-validation-states: (
	"valid": (
		"color": #45b26b,
		"icon":
			url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z' fill='#45B26B'/><path fill-rule='evenodd' clip-rule='evenodd' d='M17.3107 8.04297C17.7012 8.43349 17.7012 9.06666 17.3107 9.45718L11.1036 15.9572C10.713 16.3477 10.0799 16.3477 9.68935 15.9572L6.68935 12.9572C6.29882 12.5667 6.29882 11.9335 6.68935 11.543C7.07987 11.1524 7.71304 11.1524 8.10356 11.543L10.3965 13.8359L15.8965 8.04297C16.287 7.65244 16.9201 7.65244 17.3107 8.04297Z' fill='white'/></svg>"),
	),
	"invalid": (
		"color": #ff3364,
		"icon":
			url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0Z' fill='#F44336'/><path d='M16.4191 15.0051C16.81 15.3962 16.81 16.0281 16.4191 16.4192C16.224 16.6142 15.9681 16.7121 15.7119 16.7121C15.4559 16.7121 15.1999 16.6142 15.0049 16.4192L12 13.414L8.99504 16.4192C8.80003 16.6142 8.54405 16.7121 8.28807 16.7121C8.03191 16.7121 7.77592 16.6142 7.58092 16.4192C7.18999 16.0281 7.18999 15.3962 7.58092 15.0051L10.586 12.0001L7.58092 8.99516C7.18999 8.60405 7.18999 7.97215 7.58092 7.58104C7.97203 7.19011 8.60393 7.19011 8.99504 7.58104L12 10.5862L15.0049 7.58104C15.396 7.19011 16.0279 7.19011 16.4191 7.58104C16.81 7.97215 16.81 8.60405 16.4191 8.99516L13.4139 12.0001L16.4191 15.0051Z' fill='#fff'/></svg>"),
	),
	"loading": (
		"color": #6837ef,
		"icon":
			url("data:image/svg+xml,<svg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24' height='24' viewBox='0 0 40 40' enableBackground='new 0 0 40 40'><path opacity='0.2' fill='#6837ef' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'/><path fill='#6837ef' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z'><animateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'/></path></svg>"),
	),
);

@import "~bootstrap/scss/bootstrap";
