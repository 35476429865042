@import "assets/scss/master";

.analyticsqa-ticket-summary-item {
    padding: 10px 24px;
    h6 {
        color: $black__theme_color;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color;
        }
    }
    p {
        color: #696d8c;
        font-family: Gelion;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    .d-flex {
        align-items: center !important;
        justify-content: space-between !important;
        gap: 10px;
        div {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .duration {
        color: #696d8c;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    .upwards {
        display: flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(37, 187, 135, 0.1);
        color: #25bb87;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    b {
        color: #696d8c;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__text__color;
        }
    }
    .downwards {
        display: flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: #ea5543;
        color: #fff;
        font-family: Gelion;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        svg {
            fill: $dark__mode__theme__white__color !important;
            stroke: $dark__mode__theme__white__color !important;
            path {
                fill: $dark__mode__theme__white__color;
                stroke: $dark__mode__theme__white__color !important;
            }
        }
    }
}
