@import "assets/scss/master";

.tickets__detail--heading {
    .new__circle {
        width: 8px;
        height: 8px;
        background: #f25a68;
        border-radius: 6px;
        position: absolute;
        top: 3px;
        right: 4px;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__red__color;
        }
    }
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 1.375rem;
    color: #696d8c;
    padding: 26px 16px;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
    }

    &::before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 0%;
        height: 0.063rem;
        background: $primary__theme_color;
        transition: all 1s;
        border:0.1s solid red !important;

        #{$dark__mode__theme__selector} {
            border: 0.1px solid $dark__mode__primary__theme_muted_color;
        }
    }
    &.active {
        color: $primary__theme_color !important;
        // font-family: $primary_font_family--medium;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color !important;
            border-color: $dark__mode__primary__theme_muted_color;
        }
        &::before {
            width: 100%;
        }
    }
}
