@import "assets/scss/master";

.personas__header {
    &--text {
        font-size: 1.5rem;
        color: $text__default_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }

    &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--search {
            display: flex;
            align-items: center;
            gap: 1.125rem;
        }

        &--select {
            width: 135px;
            height: 3.33rem !important;
        }
    }
}
