@import "assets/scss/master";

.table__container {
    min-height: 50vh;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__background__color !important;
    }

    table.table {
        font-size: 0.875rem;
        text-transform: capitalize;
        font-weight: 100;
        letter-spacing: 0.0.125rem;
        color: #060213;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
        thead {
            border-bottom: 0.063rem solid $border__theme_color !important;
            #{$dark__mode__theme__selector} {
                border-color: $dark__mode__theme__border__color !important;
            }
            tr {
                height: 4.563rem;
                vertical-align: middle;
                th {
                    border-bottom: none;
                    padding: 1.25rem 1.25rem;
                    font-family: $primary_font_family--semi-bold;
                    font-weight: 100;
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__theme__background__color !important;
                    }
                }
            }
        }
        tbody {
            border-top: none !important;
            tr {
                font-size: 1rem;
                // border-top: 0.063rem solid $border__theme_color;
                min-height: 4.125rem;
                vertical-align: middle;
                td {
                    #{$dark__mode__theme__selector} {
                        background: $dark__mode__theme__background__color !important;
                    }
                    padding: 1.25rem 1.25rem;
                    &.inner-table-td {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.table {
    position: relative;
    margin-bottom: 150px !important;
    table-layout: fixed;
}

.table__head th {
    font-weight: 600;
    padding: 10px 15px !important;
    color: $black__theme_color !important;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color !important;
    }
}

.table__head th:last-child {
    text-align: right;
}

.table__head th,
.table__body td {
    font-weight: normal;
    font-size: 16px;
    vertical-align: middle !important;
    // white-space: nowrap;

    border-bottom: 1px solid $muted__border__theme_color !important;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}

.table__body td {
    padding: 15px !important;
    border-top: 0 !important;
    color: $muted__theme_color_2;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color;
    }
}

.td-href {
    color: $muted__theme_color_2;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__white__color;
    }
}

.table__body .dropdown__element td {
    width: 100% !important;
    column-span: all;
}

.table__body .dropdown__element td:last-child {
    float: unset !important;
}

.table__body .accordion__text {
    margin-left: 8px;
}

.table__body .accordion__icon {
    cursor: pointer;
    svg {
        #{$dark__mode__theme__selector} {
            fill: $dark__mode__theme__white__color !important;
        }
        path {
            #{$dark__mode__theme__selector} {
                fill: $dark__mode__theme__white__color !important;
            }
        }
    }
}

.table__body .table__action {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
}
