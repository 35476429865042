@import "assets/scss/master";

.profile-file-input {
    padding: 0.75rem 1.188rem;
    width: fit-content;
    height: 3rem;
    background: $primary__theme_color;
    border-radius: 0.625rem;
    color: white;
    margin-left: 2.5rem;
    width: 7.813rem !important;
    #{$dark__mode__theme__selector} {
       background: $dark__mode__primary__theme_color;
       color: $dark__mode__theme__white__color
    }
}

.profile-file-input-button {
    padding: 0.75rem 1.188rem;
    border: 2px solid $primary__theme_color;
    border-radius: 0.625rem;
    height: 3rem !important;
    margin-left: 1.5rem;
    width: 9.531rem !important;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__primary__theme_muted_color;
        color: $dark__mode__primary__theme_muted_color !important
    }
}
