@import "assets/scss/master";

.reminders-table-name {
    display: flex;
    gap: 15px;
    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $black__theme_color;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }
    &__default {
        padding: 4px 8px;
        gap: 10px;
        width: 61px;
        height: 30px;
        background: $pimary__background__theme_color;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: $primary__theme_color;
        #{$dark__mode__theme__selector} {
            background: $dark__mode__theme__background__color;
        }
    }
}
