@import "assets/scss/master";

.input-search-group {
    flex-wrap: nowrap !important;
    svg {
        #{$dark__mode__theme__selector} {
            stroke: $dark__mode__theme__white__color !important;
        }
        path {
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
        }
    }
    .form-control {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
    input {
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
            padding: 0px 16px !important;
        }
        &::placeholder {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
            }
        }
    }
    .clear__search__value {
        cursor: pointer;
    }
}