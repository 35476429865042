:root {
    --default-primary-color: #6837ef;
}
// Fonts
$primary_font_family: "Gelion";
$primary_font_family--lighter: "Gelion Light";
$primary_font_family--medium: "Gelion-Medium";
$primary_font_family--semi-bold: "Gelion Semi";
$primary_font_family--bold: "Gelion Bold";

/* make the customizations */
$primary__theme_color: var(--default-primary-color);
$secondary__theme_color: #1d1d1d;
$danger__theme_color: #ff3364;
$info__theme_color: #557aff;
$dark__theme_color: #000000;
$black__theme_color: #060213;
$muted__theme_color: #b0b7c3;
$light__theme_color: #ffffff;
$success__theme_color: #1b9646;
$success__green_color: #25bb87;
$gray__theme_color: #2e2e2e;
$grey__theme_color: #171725;
$border__theme_color: #ecebf5;
$muted__theme_color_2: #696d8c;
$capsule_highlight_color: #0878e9;
$light__grey__theme_color: #fafafc;

$muted__border__theme_color: #ecebf566;
$pending__theme_color: #ff991f;
$pending__background__theme_color: #ff991f33;
$success__background__theme_color: #00875a33;
$danger__background__theme_color: #ff33641a;
$primary__background__theme_color: #6837ef1a;
$border_highlight_color: #e1e1eb;
$text__default_color: #060213;
$default__capsule__theme_color: #f5f5f7;
$deep_orange__theme_color: #f56e00;
$item__list__bg__color: #fff4eb;
$gray__bg__color: #f1ecfd;
$muted__gray__theme__color: #f4f5f6;
$off__white__theme__color: #fcfcfd;
$notification__theme__color: #f25a68;
$text__muted__theme__color: #a3a3c2;
$builder__gray__theme__color: #fbfbfb;
$badge__theme__color: #f3f4f4;
$pimary__background__theme_color: #6837ef33;
$light__border__theme__color: #dee1e5;
$blue__theme__color: #3776ef;
$tag__theme__color: #f28482;
$dark__mode__theme__color: #292929;
$dark__mode__theme__text__color: #8f8f8f;
$dark__mode__theme__background__color: #191919;
$light__mode__theme__background__color: #ffffff;
$dark__mode__theme__border__color: #474747;
$dark__mode__theme__capsule__count__color: #3d3d3d;
$dark__mode__theme__selector: ".dark-mode-default &";
$white__mode__theme__selector: ".white-mode-default &";
$dark__mode__primary__theme_color: #6837ef;
$dark__mode__primary__theme_muted_color: #a5a6f6;
$dark__mode__theme__white__color: #f5f5f7;
$dark__mode__theme__green__color: #0c842a;
$dark__mode__theme__green__muted__color: #25bb87;
$dark__mode__theme__blue__color: #0878e9;
$dark__mode__theme__red__color: #ff3364;
$--background-background-dark: #E6E6ED;
// $dark__mode__theme__red__muted__color:
