@import "assets/scss/master";

.pf__branches--branch {
    width: fit-content;
    max-width: 100%;
    background: #060213;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // height: fit-content;
    height: 36px;
    padding: 6px 16px 6px 16px;

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__color;
    }

    #{$dark__mode__theme__selector} {
        background: $dark__mode__theme__color;
    }

    span {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        #{$dark__mode__theme__selector} {
            color: $light__theme_color;
        }
    }
}
