@import "assets/scss/master";
.ticket__info__list {
    display: flex;
    margin: 24px;
    flex-wrap: wrap;
    gap: 10px;
    border: 1px solid #e1e1eb;
    border-radius: 10px;
    padding: 16px;

    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
    .new__ticket__info__btn {
        height: auto;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
        }
    }
}
