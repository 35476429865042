@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/master";

.horizontal__scroll {
    margin-bottom: 0.938rem;
    display: flex;
    flex-wrap: nowrap;
    width: 95%;
    overflow: scroll;
    // .scrollarea-content {

    // overflow-x: auto;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none;  /* Internet Explorer 10+ */
    > * {
        flex: 0 0 auto;
        margin-right: 0.625rem;
    }
    // }

    &--control {
        width: 2rem !important;
        height: 2rem !important;
        border-radius: 1rem;
        background: #fff;
        z-index: 1;
        border: 0.063rem solid #ecebf5;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        &--left {
            left: 0;
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
