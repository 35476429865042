@import "assets/scss/master";

.tag__list__header {
    font-size: 18px;
    line-height: 26px;
    color: #060213;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color
    }
}

.tags {
    background: $light__theme_color;
    border: 0.063rem solid $border_highlight_color;
    border-radius: 10px;
    color: $muted__theme_color_2;
    width: fit-content;
    padding: 6px 1rem;
    cursor: pointer;

    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color;
        background: $dark__mode__theme__color;
        border-color: $dark__mode__theme__border__color;
    }
    &.active__tags {
        background: #F49E4C;
        border: 0.063rem solid #F49E4C;
        color: $light__theme_color;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__theme__white__color !important;
        }
    }
}