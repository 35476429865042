@import "assets/scss/mixins/mixins.scss";
@import "assets/scss/_master.scss";

.attached-follow-up {
    &__text {
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 29px;
        color: $primary__theme_color;
        cursor: pointer;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
        font-family: $primary_font_family--medium;
    }

    &__loader {
        width: 50%;
    }
}
