@import "assets/scss/master";

.schedule-day-select {
    width: 160px;
    &__error {
        border: 1px solid red !important;
    }
    &__label {
        padding: 4px 8px;
        gap: 10px;
        width: 160px;
        background: #ffffff;
        border: 1px solid #6837ef;
        // position: absolute;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            line-height: 22px;
            color: #060213;
            width: 100%;
            text-align: center;
        }
    }

    &__default-label {
        border: 1px solid #e1e1eb;
    }

    &__dropdown {
        padding: 8px;
        gap: 8px;
        // position: absolute;
        width: 160px;
        height: 98px;
        // background: red;
        top: 0;
        // display: none;
        margin-top: 8px;
        border: 1px solid #ecebf5;
        border-radius: 4px;
        p {
            font-weight: 500;
            font-size: 8px;
            line-height: 16px;
            color: #696d8c;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        button {
            padding: 4px 12px !important;
            width: 144px;
            background: #6837ef !important;
            border-radius: 4px !important;
            font-weight: 600;
            font-size: 10px;
            line-height: 18px !important;
            text-align: center;
            color: #ffffff !important;
            border: none !important;
            height: 26px !important;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .schedule-day__select {
        width: 144px;
        height: 28px;
        border: 1px solid #ecebf5;
        border-radius: 4px;
        margin-bottom: 8px;

        display: flex;
        justify-content: space-between;
        &--value {
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            color: #696d8c;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            input {
                border: 0 !important;
                font-family: $primary_font_family;
                color: $muted__theme_color_2;
                background: transparent;
                padding: 0;
                text-align: center;
                outline: none;
                width: 1.8rem;
            }
        }
        &--nav {
            width: 50px;
            height: 26px;
            background: #f5f5f7;
            border-left: 1px solid #ecebf5;
            border-radius: 0px 3px 3px 0px;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #060213;
            justify-content: center;
            cursor: pointer;
            span {
                margin-left: 2.5px;
                margin-right: 2.5px;
            }
            .schedule-day__icon {
                margin-top: -2px;
                cursor: pointer;
            }
        }
    }
}
