@import "assets/scss/master";

.email__input__container {
    display: flex;
    gap: 3px;
    width: 100%;
    overflow-x: auto;
    align-items: center;
    & .list__of__emails {
        display: flex;
        gap: 3px;
        overflow-x: auto;

        &.emails--full__width {
            max-width: 100%;
        }

        &.emails__wide {
            flex: 2;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    & form {
        width: 100%;
        overflow-x: auto;

        &.shrink__width {
            flex: 1;
        }

        & .email__input__group {
            width: 100%;
        }

        & .email__input {
            border: 0 !important;
            border-radius: 0 !important;
            padding: 3px 4px !important;
            resize: none;
            height: fit-content !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
                background-color: $dark__mode__theme__color;
            }
            &:disabled {
                border: 0 !important;
            }
        }
    }
}
