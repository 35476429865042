@import "../../../../../../assets/scss/master";

.branch__view {
    &--heading {
        h1 {
            font-family: $primary_font_family--medium;
            font-size: 32px;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color;
            }
        }

        &__actions {
            display: "flex";
            align-items: center;
        }

        &__button {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important;
                border-color: $dark__mode__theme__border__color !important;
            }
        }
    }
}
