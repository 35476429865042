@import "assets/scss/master";

.metadata__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;


    &--tag {
		border-radius: 0.625rem;
		display: inline;
		background: $default__capsule__theme_color;

        span {
            text-transform: capitalize;
            font-size: 0.875rem;
            line-height: 2rem;
        }
    }
}

.view__more {
    text-transform: none !important;
    background: $light__theme_color !important;
    border: 0.063rem solid $border_highlight_color !important;
    color: $black__theme_color !important;
    border-radius: 0.625rem !important;
    #{$dark__mode__theme__selector} {
        color: $dark__mode__theme__text__color !important;
        background: $dark__mode__theme__background__color !important;
        border-color: $dark__mode__theme__border__color !important;
    }
}