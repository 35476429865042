@import "assets/scss/master";
.auth--footer {
    display: flex;
    justify-content: center;
    p {
        font-weight: 400;
        margin-right: 2px;
    }
    &-link {
        color: $primary__theme_color;
        justify-content: right;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
    }
}
