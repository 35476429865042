@import "assets/scss/master";
@import "assets/scss/animate/animate.scss";

.modal-content {
    border: 0px;
}

.modal-darkmode {
    .modal-content {
        background-color: $dark__mode__theme__background__color !important;
        h1 {
            color: $dark__mode__theme__white__color !important;
        }
        p {
            font-size: 1rem;
            color: $dark__mode__theme__text__color !important;
        }

        .continue-button {
            background-color: $dark__mode__theme__red__color !important;
        }

        .close-button {
            background: $dark__mode__theme__color !important;
            border: 0.1px solid $dark__mode__theme__border__color !important;
            color: $dark__mode__theme__white__color !important;
        }
    }
}

.side-modal {
    @include transition();
    visibility: hidden;
    z-index: 5000;
    position: fixed;
    left: 100%;
    top: 0;
    width: 0;

    &.active {
        visibility: visible;
        width: 100%;
        height: 100vh;
        left: 0;
    }

    .close-icon {
        margin: 3.75rem 1.875rem;
        width: 3.125rem;
        height: 3.125rem;
    }
    .side-overlay {
        animation: fade-side-overlay 0.3s normal forwards ease;
        backdrop-filter: blur(0.25rem);
        .close-modal-btn {
            transition: all 2s;
            background: $light__theme_color;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.125rem;
            height: 3.125rem;
            border-radius: 50%;
            margin-top: 2rem;
            margin-right: 2rem;
            cursor: pointer;
            opacity: 0;
            animation: fade-in 4s normal forwards ease;
            animation-delay: 2s;
            #{$dark__mode__theme__selector} {
                background-color: $dark__mode__theme__color !important;
            }
            svg {
                #{$dark__mode__theme__selector} {
                    stroke: $light__theme_color !important;
                }
                path {
                    #{$dark__mode__theme__selector} {
                        stroke: $light__theme_color !important;
                    }
                }
            }
            &:hover {
                svg {
                    transform: rotate(60deg) !important;
                }
            }
        }
    }
    &--container {
        height: 100vh;
        background: #fff;
        overflow: auto;
        padding: 4rem 0rem;
        padding-bottom: 0;
        transform: translateX(33.438rem);
        animation: slide-side-menu 0.3s normal forwards ease;
        #{$dark__mode__theme__selector} {
            background-color: $dark__mode__theme__background__color !important;
        }
    }
    &.custom__modal--padding {
        .side-modal--container {
            padding: 2rem 0;
        }
    }
}

.custom-modal {
    @include transition();
    z-index: 5000;
    &.top-margin {
        .custom-modal--dialog {
            margin-top: 3.813rem;
        }
    }
    .custom-modal--dialog {
        background-color: #fff;
        border-radius: 1.125rem;
        padding: 2.813rem;
        height: fit-content;
        @include transition(0.9s);
        &.custom-modal--xs {
            max-width: 18.75rem;
        }
        &.custom-modal--sm {
            max-width: 25rem;
        }
        &.custom-modal--md {
            max-width: 31.25rem;
        }
        &.custom-modal--lg {
            max-width: 50rem;
        }
        &.custom-modal--xl {
            max-width: 71.25rem;
        }
    }
}

.modal-visible {
    visibility: visible;
}

.modal-hidden {
    visibility: hidden;
}
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    margin: auto;
}
.modal.fade .modal-dialog {
    -moz-transition: none !important;
    -o-transition: none !important;
    -webkit-transition: none !important;
    transition: none !important;

    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
}

.btn-close {
    --bs-btn-close-bg: url("https://res.cloudinary.com/metacare/image/upload/v1642338302/icons/icon_x3uj2c.svg") !important;
}
