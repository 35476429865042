@import "assets/scss/master";

.create__persona {
    .display__picture {
        border-bottom: 0.063rem solid $border__theme_color;
        margin-bottom: 2rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        h2 {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2.375rem;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color !important;
            }
        }

        p {
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__text__color !important;
            }
        }
        &--picture__upload {
            display: flex;
            align-items: center;
            margin-bottom: 1.813rem;
        }

        .logo__icon {
            margin-right: 2rem;

            img {
                width: 9.375rem;
                height: 9.375rem;
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }
}
