@import "assets/scss/master";

.ticket-filter-group {
    &__actions {
        display: flex;
        width: fit-content;
        margin-left: auto;
        justify-content: center;
        font-family: $primary_font_family--medium;
        font-size: 1rem;
        color: $primary__theme_color;
        cursor: pointer;
        gap: 10px;
        #{$dark__mode__theme__selector} {
            color: $dark__mode__primary__theme_muted_color;
        }
        svg {
            path {
                #{$dark__mode__theme__selector} {
                    fill: $light__theme_color !important;
                }
            }
        }

        .toggle__ticket__filters {
            transform: rotateZ(90deg);
            margin-top: 4px;

            svg {
                cursor: pointer;
                width: 12px;
                height: 12px;
            }

            &.rotate {
                transform: rotateZ(270deg);
                margin-top: 1.5px;
            }
        }
    }
}
