@import "assets/scss/master";

.branch__view__group {
    &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__header {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 2rem;
            color: $text__default_color;
            margin: 0;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
    }
}
