/* START OF HOME VIEWS TRANSITION */

.home-views-appear {
    opacity: 0;
  
    &.home-views-appear-active {
      opacity: 1;
      transition: opacity 2s ease-in;
    }
  }
  
  .home-views-enter {
    opacity: 0;
  
    &.home-views-enter-active {
      opacity: 1;
      transition: opacity 1s ease-in;
    }
  }
  
  .home-views-leave {
    opacity: 1;
  
    &.home-views-leave-active {
      opacity: 0;
      transition: 1s ease-in;
    }
  }
  
  /* END OF HOME VIEWS TRANSITION */