@import "assets/scss/master";

.canned__content__form {
    border: 1px solid #e1e1eb;
    border-radius: 10px;
    padding: 20px;
    #{$dark__mode__theme__selector} {
        border-color: $dark__mode__theme__border__color;
    }
}
