.crop__image__container {
    position: relative;
    width: 100%;
    border:  2px dashed #786e6b;
    border-radius: 5px;
    height: 300px;
    
    .cropper__container {
        width: 100%;
        height: auto;
    }
}
