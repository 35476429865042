.builder__rules {
    &--listing {
        display: flex;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0; 
            padding: 0;
        }
    }
}
