@import "assets/scss/master";
@import "../../../../../assets/scss/mixins/mixins.scss";

.product__dashboard {
	padding-top: 2rem;

	--gap: 1.313rem;

	.flows-container {
		margin-top: 34px;
		h3 {
			font-size: 28px;
		}
		p {
			font-size: 0.875rem;
		}
		.flows-container-box {
			height: 241px;
			border: 1px solid rgba(30, 31, 32, 0.1);
			border-radius: 0.625rem;
			padding: 32px 23px;
			gap: var(--gap) 0.625rem;
		}
		.flow-item {
			border-top: 0.063rem solid $border__theme_color;
			.roadblocks-list {
				gap: var(--gap) 0.625rem;
				.add-roadblock {
					font-size: 0.875rem;
					svg {
						width: 0.875rem;
						height: 0.875rem;
					}
				}
			}
		}
	}
}