@import "assets/scss/master";

.capsule__content__view__header {
    top: 0;
    position: sticky;
    padding: 0;
    background: #ffffff;
    padding-bottom: 5px;
    z-index: 10;
    #{$dark__mode__theme__selector} {
        background-color: $dark__mode__theme__background__color;
    }
    .pre__nav {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.063rem solid $border_highlight_color;
        font-size: 1rem;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
        a {
            color: $muted__theme_color_2 !important;
            text-decoration: none !important;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__theme__white__color !important
            }
        }

        svg{
            #{$dark__mode__theme__selector} {
                stroke: $dark__mode__theme__white__color !important;
            }
            path{
                #{$dark__mode__theme__selector} {
                    stroke: $dark__mode__theme__white__color !important;
                }
            }
        }
        .anchor-right {
            margin: 0 1.25rem;
        }

        p {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $primary__theme_color;
            #{$dark__mode__theme__selector} {
                color: $dark__mode__primary__theme_muted_color;
            }
        }
    }

    .content__title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 3rem 3rem 0 3rem;

        &--text {
            color: $text__default_color;
            font-weight: 600;
            font-size: 2.125rem;
            line-height: 2.438rem;
            #{$dark__mode__theme__selector} {
                color: $light__theme_color;
            }
        }
    }
}