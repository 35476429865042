@import "assets/scss/master";

.analytics-qat-variations {
    // padding: 16px 24px;
    &__item {
        border-bottom: 1px solid $border__theme_color;
        #{$dark__mode__theme__selector} {
            border-color: $dark__mode__theme__border__color;
        }
    }

    &__variations {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0px 24px;
        padding-bottom: 16px;
        span {
            display: flex;
            height: 22px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border-radius: 10px;
            background: #a5a6f6;
            color: #fff;
            font-family: Gelion;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            &.active {
                background: #6837ef;
                #{$dark__mode__theme__selector} {
                    background: $dark__mode__primary__theme_color;
                }
            }
        }
    }
}
