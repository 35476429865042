.delete-branch-actions {
    &__delete {
        padding: 8px 16px;
        width: 100%;
        height: 38px;
        background: #f25a68 !important;
        border: none !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff !important;
        margin-top: 8px;
        &:hover {
            opacity: 0.8 !important;
        }
    }

    &__continue {
        padding: 8px 16px;
        width: 100%;
        height: 38px;
        background: #ffffff !important;
        border: 1px solid #e1e1eb !important;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #060213 !important;
        &:hover {
            opacity: 0.8 !important;
        }
    }
}
